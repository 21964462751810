app.controller('Inbox_CreateOrEditPortletController',
[
    '$scope', '$uibModalInstance', 'createOrEditModalService', 'inboxPluginConfigurationService', 'data', 'inboxService',
    function ($scope, $uibModalInstance, createOrEditModalService, inboxPluginConfigurationService, data, inboxService) {


        var init = function () {

            inboxService.getInboxes()
                .then(function(result) {
                    $scope.inboxes = {
                        dataTextField: "Title",
                        dataValueField: "Id",
                        dataSource: result.data
                    };
                }).catch(angular.noop);
        }();

      
        $scope.model = createOrEditModalService.getModel("Inbox", data);
        $scope.configurationModel = inboxPluginConfigurationService.getConfigurationModel($scope.model.Properties);

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.ok = function() {
            inboxPluginConfigurationService.saveProperties($scope.model.Properties,
                $scope.configurationModel,
                data.operation);
            $uibModalInstance.close($scope.model);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');        
        };
    }
]);