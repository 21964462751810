app.controller('ObsoleteController', ['$scope', 'sharedSessionService','portletErrorService','translationsService','$controller', 
    function ($scope, sharedSessionService, portletErrorService, translationsService, $controller) {

    $controller('BasePluginController', { $scope: $scope });
        
    $scope.initialize = function () {

    };

    $scope.initialize();

}
])

