app.controller('FlowMenuController',
[
    '$scope', '$rootScope', '$uibModal', 'flowMenuConfigurationService', 'flowService', 'translationsService',
    'flowMenuService', '$controller',
    function ($scope,
        $rootScope,
        $uibModal,
        flowMenuConfigurationService) {
        
        $scope.portlet.ParsedTitle = $scope.portlet.Title;
        $scope.configurationModel =
            flowMenuConfigurationService.getConfigurationModel($scope.portlet.Properties);

        $scope.controllingModel = flowMenuConfigurationService.getControllingModel();

    }
]);