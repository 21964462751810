app.service('resourceBucketsService', ['basePluginService', '$filter',
    function (basePluginService, $filter) {
        angular.extend(this, basePluginService);

        var service = {};
		
        service.getResources=function(paginationModel, portletId)
        {
            return basePluginService.backend.post("resourceBuckets", paginationModel, portletId);
        }
        return service;
    }]);