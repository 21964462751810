app.service('HtmlService', ['basePluginService',
    function (basePluginService) {
        angular.extend(this, basePluginService);

        var service = {};
             
        service.getHtmlData = function (paginationModel, portletId) {
            return basePluginService.backend.post("htmlData", paginationModel, portletId);
        };  

        return service;

    }]);