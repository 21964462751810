app.controller('NewsViewerController', [
    '$scope', 'sharedSessionService', 'newsViewerConfigurationService', 'portletErrorService',
    'translationFlowClientService', 'appConfig', 'NewsViewerService', '$controller', '$rootScope',

function ($scope, sharedSessionService,newsViewerConfigurationService, portletErrorService, translationFlowClientService, appConfig, NewsViewerService, $controller,$rootScope) {

        $controller('BasePluginController', { $scope: $scope });

        $scope.model = {};
        $scope.model.posts = [];
        $scope.displayShowMore = false;
        $scope.sharedSessionService = sharedSessionService;
        
        $scope.refresh = function () {
            if ($scope.model.posts.length > 0) {
                $scope.controllingModel.DisplayLoadingBar = true;
                NewsViewerService.getNewsViewer($scope.model.posts[0].Id,$scope.portlet.Id).then(function (result) {
                    if (result.data.length > 0) {
                        $scope.model.posts = result.data.concat($scope.model.posts);
                    }                          
                }).catch(angular.noop).finally(function () {
                    $scope.controllingModel.DisplayLoadingBar = false;
                });
            }
        };

        $scope.displayDate = function (date) {
            return kendo.toString(kendo.parseDate(date), kendo.culture().calendar.patterns.d);
        }

        $scope.readNews = function(first) {

            $scope.controllingModel.DisplayLoadingBar = true;

            NewsViewerService.readNews($scope.PaginationModel, $scope.portlet.Id).then(function (result) {
                if (result.data.Posts.length > 0) {
                    $scope.model.posts = $scope.model.posts.concat(result.data.Posts);
                    $scope.displayShowMore = result.data.HasOlderPost;
                    $scope.PaginationModel.StartIndex = $scope.PaginationModel.StartIndex + $scope.PaginationModel.PageSize;

                    if (first) {
                        $scope.PaginationModel.StartId = $scope.model.posts[0].Id;
                    }
                }           
                $scope.controllingModel.DisplayContent = true;        
            }).catch(angular.noop).finally(function () {
                $scope.controllingModel.DisplayLoadingBar = false;
            });
        };

        $scope.initialize = function() {
            $scope.configurationModel = newsViewerConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = newsViewerConfigurationService.getControllingModel();

            $scope.PaginationModel = {};
            $scope.PaginationModel.PageSize = $scope.configurationModel.PageSize;
            $scope.PaginationModel.StartIndex = 0;

            $scope.readNews(true);
        };


        $scope.toggleList= function(post)
        {
            post.ShowAll = !post.ShowAll;
        }

        $scope.addComment = function(post) {

            if (post.CommentText) {
                $scope.controllingModel.DisplayLoadingBar = true;

                var comment = {};
                comment.PostId = post.Id;
                comment.Text = post.CommentText;

                NewsViewerService.addComment(comment,$scope.portlet.Id)
                .then(function (result) {
                    result.data.UserFullName = sharedSessionService.getUserName();
                    post.Comments.push(result.data);
                    post.CommentText = null;
           
                }).catch(angular.noop).finally(function () {
                    $scope.controllingModel.DisplayLoadingBar = false;
                });
            }
        }

        $scope.deleteComment = function(postId, commentId) {

            $scope.controllingModel.DisplayLoadingBar = true;

            NewsViewerService.deleteComment(commentId, $scope.portlet.Id)
            .then(function () {
                angular.forEach($scope.model.posts, function (post) {
                    for (var i = 0; i < post.Comments.length; i++)
                        if (post.Comments[i].Id === commentId) {
                            post.Comments.splice(i, 1);
                            break;
                        }
                });      
            }).catch(angular.noop).finally(function () {
                $scope.controllingModel.DisplayLoadingBar = false;
            });
        }

        $scope.showMore = function () {
            $scope.readNews();
        };

        $scope.initialize();

    }
])