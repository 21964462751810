angular.module("portal").controller("DataVisualizerCardNavigatorController", [
    "$scope", "$rootScope", "receiverService", "dataVisualizerCardNavigatorConfigurationService", "globalVariableService", "translationsService", "dataVisualizerCardNavigatorService", 'portletErrorService', '$controller', '$interval',
    function ($scope, $rootScope, receiverService, dataVisualizerCardNavigatorConfigurationService, globalVariableService, translationsService, dataVisualizerCardNavigatorService, portletErrorService, $controller, $interval) {

    $controller('BasePluginController', { $scope: $scope });

    $scope.controllingModel = dataVisualizerCardNavigatorConfigurationService.getControllingModel();
        $scope.configurationModel = dataVisualizerCardNavigatorConfigurationService.getConfigurationModel($scope.portlet.Properties);

        $scope.click = function (selectedPortlet) {

            $scope.currentlySelectedPortlet = selectedPortlet;
            $rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: selectedPortlet.DCV_DataRow });
        }

        $scope.refresh = function () {
            $scope.initialize();
        };

        $scope.$watch(null, function () {
            if ($scope.configurationModel.RefreshModel.Refreshable) {
                $scope.interval = $interval(function () {
                    if (!$scope.controllingModel.InEdit) {
                        $scope.refresh();
                    }
                }, 1000 * $scope.configurationModel.RefreshModel.RefreshTime);
            }
        });
        
        $scope.$on(
            "$destroy",
            function stop() {
                if (angular.isDefined($scope.interval)) {
                    $interval.cancel($scope.interval);
                    $scope.interval = undefined;
                }
            }
        );

        $scope.$on('onRowSelected',
        function (event, data) {
            if (data.SenderId === $scope.portlet.Id) {
                return;
            }
            if (!$scope.enumerableOfPortlet) {
                return;
            }

            var p = _.find($scope.enumerableOfPortlets, function (x) { return x.Id === data.SenderId; });

            if (p) {
                $scope.click(p);
            }
        });

        $scope.getClass = function (shownPortlet) {
            var classes = [];
            if (shownPortlet.DVCIsProcess) {
                classes.push("navigator-horizontal-list-item-content-process");
            }
               
            return classes;
        }

        $scope.getTemplateUrl = function (portlet) {
            if (!portlet) return; 

            var outputMode = _.find(portlet.Properties, function (x) { return x.PropertyKey === "OutputMode" }) || {};

            var result = "";
            
            if (outputMode.PropertyValue === 'process') {
                result = $scope.pluginsDir + '/DataVisualizerCard/Templates/processCardSmall.html';
            }
            else if (outputMode.PropertyValue === 'speedometer') {
                result = $scope.pluginsDir + '/DataVisualizerCard/Templates/speedometerSmall.html'
            }
            else {
                result = $scope.pluginsDir + '/DataVisualizerCard/Templates/defaultSmall.html';
            }

            return result; 
        }

        $scope.setProcessFlag = function(portlets) {

            angular.forEach(portlets,
                function (portlet) {

                    var processProp = _.find(portlet.Properties, function (x) { return x.PropertyKey === "OutputMode" }) || {};

                    portlet.DVCIsProcess = false;
                    if (processProp.PropertyValue == 'process') {
                        portlet.DVCIsProcess = true;
                    }
                });
        }

        $scope.initialize = function () {

            $scope.controllingModel.DisplayLoadingBar = true;

            var selectedIds = [];

            var sortedCardList = _.sortBy($scope.configurationModel.CardList, [function (card) { return card.Order; }]);
            angular.forEach(sortedCardList,
                function(card) {
                    selectedIds.push(card.PortletId);
                });

            dataVisualizerCardNavigatorService.getData({ PortletIds: selectedIds }, $scope.portlet.Id)
                .then(
                    function(result) {
                        $scope.portlets = result.data;
                        $scope.setProcessFlag($scope.portlets);
                        $scope.enumerableOfPortlets = $scope.portlets;                   
                   
                    }).catch(angular.noop).finally(
                        function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                        }
                    );
        }

        $scope.initialize();
    }
]);
