app.controller('DataVisualizerCardNavigator_CreateOrEditPortletController', ['$scope', '$rootScope', '$uibModalInstance',
    'createOrEditModalService', 'dataVisualizerCardNavigatorConfigurationService', 'data', 'connectorService', 'suggestionService', 'dataVisualizerCardNavigatorService',
function ($scope, $rootScope, $uibModalInstance, createOrEditModalService, dataVisualizerCardNavigatorConfigurationService, data, connectorService, suggestionService, dataVisualizerCardNavigatorService) {

    $scope.model = createOrEditModalService.getModel("DataVisualizerCardNavigator", data);
    $scope.configurationModel = dataVisualizerCardNavigatorConfigurationService.getConfigurationModel($scope.model.Properties);
    $scope.panelBarOptions = {
        expandMode: "single",
        select: $scope.expandCollapse
    };

    $scope.initialize = function () {     
        $scope.selectedIds = [];

        var sortedCardList = _.sortBy($scope.configurationModel.CardList, [function (card) { return card.Order; }]); 
        angular.forEach(sortedCardList, function (card) {
            $scope.selectedIds.push(card.PortletId);
        })

        $scope.selectOptions = {
            placeholder: "Select Cards",
            dataTextField: "InternalName",
            dataValueField: "Id",
            valuePrimitive: true,
            autoBind: false,
            dataSource: new kendo.data.DataSource({
                transport: {
                    read: function (options) {
                        dataVisualizerCardNavigatorService.getDvcList("dvcList/")
                            .then(
                                function (result) {
                                    options.success(result.data);
                                    $scope.enumerableOfavailablePortlets = result.data;
                                }).catch(function (error) {
                                    options.error(error);
                                })
                    }
                }
            }),
        };
        $scope.selectOptions.dataSource.read();

    }


    $scope.ok = function () {

        var orderNumber = 0;
        $scope.configurationModel.CardList = [];

        var availableIds = _.flatMap($scope.enumerableOfavailablePortlets, function (x) { return x.Id });

        angular.forEach($scope.selectedIds,
            function (cardId) {
                if (availableIds.includes(cardId)) {
                    $scope.configurationModel.CardList.push({ PortletId: cardId, Order: orderNumber });
                    orderNumber++;
                }
            });

        
        dataVisualizerCardNavigatorConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model); 
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');      
    };

    $scope.initialize();


}])