app.factory('chart2ConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.PluginsConstants = {};
    service.PluginsConstants.ChartTypes = [{ Name: "area" }, { Name: "bar" }, { Name: "column" },
        { Name: "donut" }, { Name: "funnel" }, { Name: "line" }, { Name: "pie" }, { Name: "radarArea" },
        { Name: "radarColumn" }, { Name: "radarLine" }, { Name: "verticalArea" }, { Name: "verticalLine" }];

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        var defaultchartOptions = {
            chartArea: { height: 400 }

        };
        configurationModel.ChartModel = $.parseJSON(propertyService
            .getPropertyValue(properties, "ChartModel", JSON.stringify({ ChartOptions: defaultchartOptions, SeriesDefinitions: [] })));


        configurationModel.PageSize = parseInt(propertyService.getPropertyValue(properties, "pageSize", "10"));
        configurationModel.IgnoreExecutionCheck = propertyService.getBoolPropertyValue(properties, "ignoreExecutionCheck");

        configurationModel.DefaultSortingModel = {};
        configurationModel.DefaultSortingModel.OrderBy = propertyService.getPropertyValue(properties, "DefaultOrderBy", undefined);
        configurationModel.DefaultSortingModel.Direction = propertyService.getPropertyValue(properties, "DefaultOrderByDirection", 'asc');

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }
    service.saveProperties = function (properties, configurationModel, operation) {

        configurationService.saveProperties(properties, configurationModel);

        propertyService.setProperty(properties, "pageSize", configurationModel.PageSize.toString());
        propertyService.setBoolProperty(properties, "ignoreExecutionCheck", configurationModel.IgnoreExecutionCheck);

        propertyService.setPropertyWithRemoveOnEmpty(properties, "DefaultOrderBy", configurationModel.DefaultSortingModel.OrderBy);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "DefaultOrderByDirection", configurationModel.DefaultSortingModel.Direction);


        if (configurationModel.ChartModel) {
            propertyService.setProperty(properties, "ChartModel", JSON.stringify(configurationModel.ChartModel));
        }

    }

    return service;
}]);