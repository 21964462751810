app.controller("Scheduler_CreateOrEditPortletController",
	['$scope', '$http', '$uibModalInstance', 'createOrEditModalService', 'configurationService', 'data', 'connectorService', 'pluginConfigurationService', 'editorService', 'kendoGridUtilService', 'dataEvalService', 'Guid',
		function ($scope, $http, $uibModalInstance, createOrEditModalService, configurationService, data, connectorService, pluginConfigurationService, editorService, kendoGridUtilService, dataEvalService, Guid) {
			
			$scope.numberOfDatasources = 3;
			$scope.connectorService = connectorService;
			$scope.editorService = editorService;

			$scope.model = createOrEditModalService.getModel("Scheduler", data);
			$scope.configurationModel = pluginConfigurationService.getConfigurationModel($scope.model.Configuration, "Scheduler");

			pluginConfigurationService.assignPositions($scope.configurationModel.ColorRulesConfiguration.ColorRules);
			pluginConfigurationService.assignPositions($scope.configurationModel.IconsConfiguration.Icons);
			pluginConfigurationService.assignPositions($scope.configurationModel.FlowConfiguration.CustomFlows);
			pluginConfigurationService.assignPositions($scope.configurationModel.TooltipConfiguration.Fields);

			var numberOfQueries = $scope.model.Queries.length;

			if (numberOfQueries !== $scope.numberOfDatasources) {
				for (var j = 0; j < ($scope.numberOfDatasources - numberOfQueries); j++) {
					$scope.model.Queries.push({ ConnectorName: null, Value: null });
				}
			}




			if (!$scope.configurationModel.ColorRulesConfiguration.DefaultColor) {
				$scope.configurationModel.ColorRulesConfiguration.DefaultColor = $.VisualPlanningColorList[0];
			}
			if ($scope.configurationModel.TooltipConfiguration != null) {
				if (!$scope.configurationModel.TooltipConfiguration.Code) {
					$scope.configurationModel.TooltipConfiguration.Code = Guid.newGuid();
				}
				if (Guid.isEmpty($scope.configurationModel.TooltipConfiguration.Code)) {
					$scope.configurationModel.TooltipConfiguration.Code = Guid.newGuid();
				}
			}

			$scope.viewsOptions = {
				dataSource: {
					data: ["day",
						"week",
						"workWeek",
						"month",
						"agenda",
						"timeline",
						"timelineWeek",
						"timelineMonth",
						"customTimeline"]
				}
			};


			$scope.createWorkflowTypes = [{
				"type": "dsWithPagingAndFiltering",
				"displayName": "Data Source with paging and filtering"
			}, {
				"type": "datasource",
				"displayName": "Data Source"
			}
			];

			$scope.colorPickerOptions = function () {
				return {
					columns: 12,
					tileSize: {
						width: 34,
						height: 34
					},
					palette: $.VisualPlanningColorList
				};
			}

			$scope.iconGrid = {
				editable: true,
				scrollable: false,
				toolbar: [{ name: "create", text: "Add" }],
				columns: [
					{ field: "Position", title: "Pos", width: 24, readonly: true, editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'), template: '<div id="iconId_#: Position #" kendo-droptarget k-drop="onIconDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>' },
					{ field: "Icon", title: "Icon", width: "50px" },
					{ field: "FirstValue", title: "First Value", width: "150px" },
					{ field: "Func", title: "Func", width: "100px", editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'), template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func') },
					{ field: "SecondValue", title: "Second Value", width: "150px" },
					{ command: [{ name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>" }], width: 24 }
				],
				dataSource: new kendo.data.DataSource({
					data: $scope.configurationModel.IconsConfiguration.Icons,
					autoSync: true,
					transport: {
						read: function (o) {
							o.success($scope.configurationModel.IconsConfiguration.Icons);
						},
						create: function (o) {
							var item = o.data;
							item.Position = $scope.configurationModel.IconsConfiguration.Icons.length + 1;
							$scope.configurationModel.IconsConfiguration.Icons.unshift(item);
							o.success(item);
						},
						update: function (o) {
							o.success();
						},
						destroy: function (o) {
							for (var index = 0; index < $scope.configurationModel.IconsConfiguration.Icons.length; index++) {
								if ($scope.configurationModel.IconsConfiguration.Icons[index].Position === o.data.Position) {
									$scope.configurationModel.IconsConfiguration.Icons.splice(index, 1);
									break;
								}
							}
							o.success();
						}
					},
					schema: {
						model: {
							id: "Position",
							fields: {
								Position: { type: "int", defaultValue: 0 },
								FirstValue: { type: "string" },
								Func: { type: "string", defaultValue: 1 },
								SecondValue: { type: "string" },
								Icon: { type: "string" }
							}
						}
					}
				})
			};
			editorService.createColumnNameComboBox($scope.iconGrid.columns[2], $scope.model);

			$scope.iconGrid.dataSource.fetch(function () {
				$scope.iconGrid.dataSource.sort({ field: "Position", dir: "asc" });
			});

			$scope.tooltipOptions = {
				editable: true,
				scrollable: false,
				toolbar: [
					{
						name: "create", text: "Add"
					}
				],
				columns: [
					{ field: "DatabaseColumn", title: "Datasource column", width: "400px" },
					{ field: "Editor", title: "Editor", width: "300px", editor: editorService.createBasicEditorDropDownList },
					{ field: "Length", title: "Length", width: "100px" },
					{
						command: [
							{
								name: "destroy",
								template:
									"<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
							}
						],
						width: 6
					}
				],

				dataSource: new kendo.data.DataSource({
					data: $scope.configurationModel.Mappers,
					autoSync: true,
					transport: {
						read: function (o) {
							if (!$scope.configurationModel.TooltipConfiguration.Fields) {
								$scope.configurationModel.TooltipConfiguration.Fields = [];
							}
							o.success($scope.configurationModel.TooltipConfiguration.Fields);
						},
						create: function (o) {
							var item = o.data;
							item.Position = $scope.configurationModel.TooltipConfiguration.Fields.length + 1;
							$scope.configurationModel.TooltipConfiguration.Fields.unshift(item);
							o.success(item);
						},
						update: function (o) {
							o.success();
						},
						destroy: function (o) {

							for (var index = 0; index < $scope.configurationModel.TooltipConfiguration.Fields.length; index++) {
								if ($scope.configurationModel.TooltipConfiguration.Fields[index].Position === o.data.Position) {
									$scope.configurationModel.TooltipConfiguration.Fields.splice(index, 1);
									break;
								}
							}

							o.success();
						}
					},
					schema: {
						model: {
							id: "Position",
							fields: {
								DatabaseColumn: { type: "string" },
								Editor: { defaultValue: "Text" },
								Length: { type: "number" }
							}
						}
					}
				})
			};
			
			editorService.createColumnNameComboBox($scope.tooltipOptions.columns[0], $scope.model);

			$scope.colorGrid = {
				editable: true,
				scrollable: false,
				toolbar: [{ name: "create", text: "Add" }],
				columns: [
					{
						field: "Position",
						title: "Pos",
						width: 24,
						readonly: true,
						editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'),
						template:
							'<div id="ruleId_#: Position #" kendo-droptarget k-drop="onRuleDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>'
					},
					{
						field: "Colour",
						title: "Colour",
						width: "100px",
						editor: kendoGridUtilService.getColorPickerFromPalette(),
						template: kendoGridUtilService.getColorTemplate()
					},
					{ field: "FirstValue", title: "First Value", width: "100px" },
					{
						field: "Func",
						title: "Func",
						width: "100px",
						editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
						template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
					},
					{ field: "SecondValue", title: "Second Value", width: "100px" },
					{
						command: [
							{
								name: "up",
								template: "<a class='k-button k-button-icontext gridButton' ng-click='columnUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
							},
							{
								name: "down",
								template: "<a class='k-button k-button-icontext gridButton' ng-click='columnDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
							},
							{
								name: "destroy",
								template:
									"<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
							}
						],
						width: 24
					}
				],
				dataSource: new kendo.data.DataSource({
					data: $scope.configurationModel.ColorRulesConfiguration.ColorRules,
					autoSync: true,
					transport: {
						read: function (o) {
							o.success($scope.configurationModel.ColorRulesConfiguration.ColorRules);
						},
						create: function (o) {
							var item = o.data;
							item.Position = $scope.configurationModel.ColorRulesConfiguration.ColorRules.length + 1;
							$scope.configurationModel.ColorRulesConfiguration.ColorRules.unshift(item);
							o.success(item);
						},
						update: function (o) {
							o.success();
						},
						destroy: function (o) {
							for (var index = 0; index < $scope.configurationModel.ColorRulesConfiguration.ColorRules.length; index++) {
								if ($scope.configurationModel.ColorRulesConfiguration.ColorRules[index].Position === o.data.Position) {
									$scope.configurationModel.ColorRulesConfiguration.ColorRules.splice(index, 1);
									break;
								}
							}
							o.success();
						}
					},
					schema: {
						model: {
							id: "Position",
							fields: {
								Position: { type: "int", defaultValue: 0 },
								FirstValue: { type: "string" },
								Func: { type: "string", defaultValue: 1 },
								SecondValue: { type: "string" },
								Colour: {
									type: "string",
									defaultValue: "#5cbae6",
									validation: {
										required: { message: "Field is required" }
									}
								}

							}
						}
					}
				})
			};

			editorService.createColumnNameComboBox($scope.colorGrid.columns[2], $scope.model);

			$scope.colorGrid.dataSource.fetch(function () {
				$scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
			});

			$scope.flowOptions = {
				editable: "inline",
				scrollable: false,
				edit: function (e) {
					var commandCell = e.container.find("td:last");
					commandCell.html(
						"<a class='k-button k-button-icontext k-grid-update gridButton'><span class='k-icon k-i-checkmark'></span></a>" +
						"<a class='k-button k-grid-cancel gridButton'><span class='k-icon k-i-cancel'></span></a>"
					);
				},
				toolbar: [{ name: "create", text: "Add" }],
				columns: [
					{ field: "DisplayName", title: "Display Name", width: "150px" },
					{
						field: "CustomFlow",
						title: "Custom Flow",
						width: "200px",
						editor: kendoGridUtilService.makeWorkflowTree("workflows", "Name", "Id"),
						template: '<span ng-bind="dataItem.CustomFlow.Name"></span>'
					},
					{
						field: "AlwaysEnabled",
						title: "Always Enabled",
						width: "70px",
						template: "<input type='checkbox' #= AlwaysEnabled ? checked='checked' : '' # ></input>",
						editor: kendoGridUtilService.makeKendoCheckboxFromValue("AlwaysEnabled"),

					},
					{ field: "LeftValue", title: "Left Value", width: "150px" },
					{
						field: "Func",
						title: "Func",
						width: "100px",
						editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
						template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
					},
					{ field: "RightValue", title: "Right Value", width: "150px" },
					{
						command: [
							{
								name: "edit",
								template: "<a class='k-button k-button-icontext k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>",
							},
							{
								name: "up",
								template: "<a class='k-button k-button-icontext gridButton' ng-click='customFlowUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
							},
							{
								name: "down",
								template: "<a class='k-button k-button-icontext gridButton' ng-click='customFlowDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
							},
							{
								name: "destroy",
								template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
							}
						],
						width: 24
					}],

				dataSource: new kendo.data.DataSource({
					data: $scope.configurationModel.FlowConfiguration.CustomFlows,
					autoSync: true,
					transport: {
						read: function (o) {
							o.success($scope.configurationModel.FlowConfiguration.CustomFlows);
						},
						create: function (o) {
							var item = o.data;
							item.Position = $scope.configurationModel.FlowConfiguration.CustomFlows + 1;
							item.Code = Guid.newGuid();
							$scope.configurationModel.FlowConfiguration.CustomFlows.unshift(item);
							o.success(item);
						},
						update: function (o) {
							o.success();
						},
						destroy: function (o) {
							for (var index = 0;
								index < $scope.configurationModel.FlowConfiguration.CustomFlows.length;
								index++) {
								if ($scope.configurationModel.FlowConfiguration.CustomFlows[index].Code === o.data.Code) {
									$scope.configurationModel.FlowConfiguration.CustomFlows.splice(index, 1);
									break;
								}
							}
							o.success();
						}
					},
					schema: {
						model: {
							id: "Code",
							fields: {
								DisplayName: {
									type: "string",
									validation: {
										required: { message: "Field is required" }
									}
								},
								CustomFlow: {
									validation: {
										required: true
									}
								},
								AlwaysEnabled: { type: "boolean" },
								LeftValue: { type: "string" },
								Func: { type: "string", defaultValue: "1" },
								RightValue: { type: "string" }
							}
						}
					}
				})
			};

			editorService.createNotMandatoryColumnNameComboBox($scope.flowOptions.columns[3], $scope.model);

			$scope.customFlowUp = function (e) {

				var dataItem = $scope.customWorkflowsGrid.dataItem($(e.currentTarget).closest("tr"));

				var index = null;
				var elements = $scope.customWorkflowsGrid.dataSource.data();
				for (var i = 0; i < elements.length; i++) {
					if (elements[i].uid === dataItem.uid) {
						index = i;
						break;
					}
				}
				if (index > 0) {
					var customFlow = $scope.configurationModel.FlowConfiguration.CustomFlows[index];
					$scope.configurationModel.FlowConfiguration.CustomFlows.splice(index, 1);
					$scope.configurationModel.FlowConfiguration.CustomFlows.splice(index - 1, 0, customFlow);
					$scope.customWorkflowsGrid.dataSource.read();
				}
			};

			$scope.customFlowDown = function (e) {
				var dataItem = $scope.customWorkflowsGrid.dataItem($(e.currentTarget).closest("tr"));

				var index = null;
				var elements = $scope.customWorkflowsGrid.dataSource.data();

				for (var i = 0; i < elements.length; i++) {
					if (elements[i].uid === dataItem.uid) {
						index = i;
						break;
					}
				}

				if (index < $scope.customWorkflowsGrid.dataSource.data().length - 1) {
					var customFlow = $scope.configurationModel.FlowConfiguration.CustomFlows[index];
					$scope.configurationModel.FlowConfiguration.CustomFlows.splice(index, 1);
					$scope.configurationModel.FlowConfiguration.CustomFlows.splice(index + 1, 0, customFlow);
					$scope.customWorkflowsGrid.dataSource.read();
				}
			};


			$scope.validateDataSource = function () {

				if ($scope.model.DataSourceType === 'Database') {
					if (($scope.model.Queries[0].ConnectorName == null || $scope.model.Queries[0].ConnectorName === '') || ($scope.model.Queries[0].Value == null || $scope.model.Queries[0].Value === '')) {
						return false;
					}
					if (($scope.model.Queries[1].ConnectorName == null || $scope.model.Queries[1].ConnectorName === '') || ($scope.model.Queries[1].Value == null || $scope.model.Queries[1].Value === '')) {
						return false;
					}

					if (($scope.model.Queries[2].ConnectorName == null || $scope.model.Queries[2].ConnectorName === '') || ($scope.model.Queries[2].Value == null || $scope.model.Queries[2].Value === '')) {
						return false;
					}
				
				}

				if ($scope.model.DataSourceType === 'Workflow' && $scope.model.Workflows.length !== $scope.numberOfDatasources) {
					return false;
				}

				if ($scope.model.DataSourceType === 'Workflow') {
					if (($scope.model.Workflows[0].Name == null || $scope.model.Workflows[0].Name === '') || ($scope.model.Workflows[0].OutputVariable == null || $scope.model.Workflows[0].OutputVariable === '')) {
						return false;
					}
					if (($scope.model.Workflows[1].Name == null || $scope.model.Workflows[1].Name === '') || ($scope.model.Workflows[1].OutputVariable == null || $scope.model.Workflows[1].OutputVariable === '')) {
						return false;
					}
					if (($scope.model.Workflows[2].Name == null || $scope.model.Workflows[2].Name === '') || ($scope.model.Workflows[2].OutputVariable == null || $scope.model.Workflows[2].OutputVariable === '')) {
						return false;
					}
				}

				if (!$scope.configurationModel.TasksDatasource.TaskIdColumnName)
					return false;
				if (!$scope.configurationModel.TasksDatasource.TaskTitleColumnName)
					return false;
				if (!$scope.configurationModel.TasksDatasource.TaskStartColumnName)
					return false;
				if (!$scope.configurationModel.TasksDatasource.TaskEndColumnName)
					return false;
				if (!$scope.configurationModel.ResourcesDatasource.ResourceIdColumnName)
					return false;

				if (!$scope.configurationModel.AssignmentsDatasource.AssignmentIdColumnName)
					return false;
				if (!$scope.configurationModel.AssignmentsDatasource.ResourceIdColumnName)
					return false;
				if (!$scope.configurationModel.AssignmentsDatasource.TaskIdColumnName)
					return false;

				return true;
			}

			$scope.panelBarOptions = {
				expandMode: "single",
				select: $scope.expandCollapse
			};
			$scope.cancel = function () {
				$uibModalInstance.dismiss('cancel');
			};
			$scope.ok = function () {
				configurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
				$scope.model.Configuration = JSON.stringify($scope.configurationModel);
				$uibModalInstance.close($scope.model);
			};
		}
	]);