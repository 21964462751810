app.factory('genericDocumentViewerConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);
        configurationModel.DataColumnName = propertyService.getPropertyValue(properties, "dataColumnName", "");
        configurationModel.MimeTypeColumnName = propertyService.getPropertyValue(properties, "mimeTypeColumnName", "");
        configurationModel.DefaultMimeType = propertyService.getPropertyValue(properties, "defaultMimeType", "");
        configurationModel.FileNameColumn = propertyService.getPropertyValue(properties, "fileNameColumn", "");
        
        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {
        configurationService.saveProperties(properties, configurationModel);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "dataColumnName", configurationModel.DataColumnName);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "mimeTypeColumnName", configurationModel.MimeTypeColumnName);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "defaultMimeType", configurationModel.DefaultMimeType);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "fileNameColumn", configurationModel.FileNameColumn);
    }

    return service;
}]);