app.controller("GenericDocumentViewerUrlController",
    [
        "$scope", "$rootScope", "GenericDocumentViewerUrlService", "receiverService", "genericDocumentViewerUrlConfigurationService",
        "sharedSessionService", "portletErrorService", "appConfig", "$sce", "$controller", 
        function ($scope,
            $rootScope,
            GenericDocumentViewerUrlService,
            receiverService,
            genericDocumentViewerUrlConfigurationService,
            sharedSessionService,
            portletErrorService,
            appConfig,
            $sce, $controller) {

            $controller('BasePluginController', { $scope: $scope });

            $scope.statusCode = "";
            $scope.hasStatus = false;
            $scope.isPdf = false;
            $scope.isImage = false;

            $scope.initialize = function () {

                $scope.configurationModel =
                    genericDocumentViewerUrlConfigurationService.getConfigurationModel($scope.portlet.Properties);
                $scope.controllingModel = genericDocumentViewerUrlConfigurationService.getControllingModel();
                $scope.controllingModel.DisplayLoadingBar = false;
                $scope.DocumentViewerViewModel = {};
                $scope.DocumentViewerViewModel.MimeType = $scope.configurationModel.MimeType;
                $scope.DocumentViewerViewModel.PortletId = $scope.portlet.Id;

            };

            $scope.refresh = function () {
                $scope.getDocument();
            };

            $scope.$on("onMaximized",
                function (event, pcPortlets) {
                    if ($scope.DocumentViewerViewModel.MimeType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=100";
                        $scope.setPdfSizes();
                    }
                });

            $scope.$on("onMinimized",
                function (event, pcPortlets) {
                    if ($scope.DocumentViewerViewModel.MimeType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=auto";
                        $scope.setPdfSizes();
                    }
                });

            $scope.$on("onRowSelected",
                function (event, value) {
                    if (receiverService.canReceive($scope.portlet, "onRowSelected", value)) {

                        var dataValues = value.Data;

                        $scope.DocumentViewerViewModel.Data = [];
                        for (var prop in dataValues) {
                            if (dataValues.hasOwnProperty(prop) &&
                                !angular.isFunction(dataValues[prop]) &&
                                !angular.isObject(dataValues[prop])) {
                                $scope.DocumentViewerViewModel.Data.push({ Key: prop, Value: dataValues[prop] });
                            }
                        }

                        $scope.getDocument();

                    }
                });

            $scope.$on("onRefresh",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.refresh();
                    }
                });

            $scope.getDocument = function () {

                if ($scope.DocumentViewerViewModel.Data.length > 0 && $scope.DocumentViewerViewModel.MimeType) {

                    $scope.controllingModel.DisplayLoadingBar = true;
                    $scope.fileUrl = null;
                    $scope.image = null;
                    $scope.hasStatus = false;
                    $scope.isPdf = false;
                    $scope.isImage = false;

                    GenericDocumentViewerUrlService.getDocument($scope.DocumentViewerViewModel,{}, $scope.portlet.Id)
                    .then(function (result) {
                        var data = result.data;

                        if (result.statusText !== "OK") {
                            $scope.statusCode = result.statusText;
                            $scope.hasStatus = true;
                        };

                        if (data.byteLength > 0) {

                            var file = new Blob([data], { type: $scope.DocumentViewerViewModel.MimeType });

                            if ($scope.DocumentViewerViewModel.MimeType === "application/pdf") {

                                $scope.isPdf = true;

                                var path = $.path +
                                    "/" + $.contentDir + "/pdfViewer/web/viewer.html?file=" +
                                    $sce.trustAsResourceUrl(URL.createObjectURL(file)) +
                                    "&lang=" +
                                    sharedSessionService.getLanguageCode();

                                $scope.fileUrl = path;
                                $scope.setPdfSizes();
                              
                            } else if ($scope.DocumentViewerViewModel.MimeType.startsWith("image")) {

                                var binary = "";
                                var bytes = new Uint8Array(data);
                                var len = bytes.byteLength;
                                for (var i = 0; i < len; i++) {
                                    binary += String.fromCharCode(bytes[i]);
                                }
                                $scope.image = window.btoa(binary);
                                $scope.isImage = true;
                            } else {
                                saveAs(file);
                            }
                        }
                    }).catch(angular.noop).finally(
                        function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                        });
                }
            };
            $scope.setPdfSizes = function() {
                $scope.pdfSizes = {
                    "width": "100%", "height": $("#doc" + $scope.portlet.Id).closest(".portletContainerBody").height() -
                        $("#doc" + $scope.portlet.Id).closest(".portletContainerBody").find("ul")
                        .height() -
                        10 + "px"
                }
            };
            $scope.initialize();
        }
    ]);
