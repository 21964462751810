app.controller("TableWorkflowExecutionController", ["$scope", "$rootScope", "flowService", "portletErrorService",
    "dataEvalService", "appConfig", "$controller", "$q",
    function ($scope,
        $rootScope,
        flowService,
        portletErrorService,
        dataEvalService,
        appConfig, $controller, $q
    ) {

        $controller('BasePluginController', { $scope: $scope });

        /**
         * Rerfresh confirmation
         */
        $scope.continue = false;

        $scope.openUnsavedChangesModal = function () {
            jQuery("#unsavedChangesModal").modal('show');
        }

        $scope.openModal = function (options) {
            jQuery("#refreshConfirmationModal_" + $scope.portlet.Id).modal('show');

            if (options)
                $scope.options = options;
        };

        $scope.refuseRefresh = function () {
            $scope.continue = false;

            if ($scope.options)
                $scope.options.error();

            jQuery("#refreshConfirmationModal_" + $scope.portlet.Id).modal('hide');
        };

        $scope.acceptRefresh = function () {
            $scope.continue = true;
            jQuery("#refreshConfirmationModal_" + $scope.portlet.Id).modal('hide');
            $scope.preventLostData.unsetForPortlet($scope.portlet.Id);
            this[$scope.refreshOkFn]($scope.options);
        };

        $scope.onRefreshOk = function () {
            $scope.tableGrid.setDataSource($scope.gridOptions.dataSource);
        };

        /**
         * Refreshing stuff
         * @returns {} 
         */
        $scope.refreshSilently = function () {

            //NFSIX-4526
            //$scope.tableGrid.setDataSource($scope.gridOptions.dataSource);
            if ($scope.featuresFlags.hasFeatureFlag("PortalPreventLostData")) {

                $scope.refreshOkFn = 'onRefreshOk';

                $scope.shouldManuallyRefresh().then(function (shouldContinue) {
                    $scope.openModal(null);
                }).catch(function (error) {
                    $scope[$scope.refreshOkFn]();
                });

            } else {
                $scope.onRefreshOk();
            }
        };


        $scope.hasWorkflow = function (workflowObj) {
            if (!workflowObj) {
                return false;
            }
            if (workflowObj === appConfig.TablePlugin.NO_WORKFLOW) {
                return false;
            }
            if (Object.keys(workflowObj).length > 0) {
                return true;
            }

            return false;
        }
        $scope.rowDblClickFromButton = function (e) {
            var dataItem = $scope.tableGrid.dataItem($(e.currentTarget).closest("tr"));
            $scope.callDblClick(dataItem);
        };

        $scope.rowDblClick = function () {
            if ($scope.hasWorkflow($scope.configurationModel.WorkFlowModel.DblClickWorkflow) &&
                !$scope.configurationModel.MultiSelect) {
                var dataItem = $scope.selectionModel.selectedRows[0];
                if (dataItem)
                    $scope.callDblClick($scope.selectionModel.selectedRows[0]);
            }
        };

        $scope.callDblClick = function (data) {

            $scope.controllingModel.DisplayLoadingBar = true;
            var dataToSend = [];
            dataToSend.push($scope.mapBooleanDataFrom(data));

            if ($scope.hasWorkflow($scope.configurationModel.WorkFlowModel.DblClickWorkflow)) {
                if ($scope.configurationModel.WorkFlowModel.DblClickUserActions) { //we need to put args into object as below
                    $scope.flowService.runFlowUserAction($scope,
                        $scope.configurationModel.WorkFlowModel.DblClickWorkflow.Id, //change name
                        dataToSend,
                        $scope.configurationModel.Mappers,
                        appConfig.WorkflowOperationType.DBL_CLICK_WORKFLOW,
                        $scope.PaginationModel.RowFilter,
                        function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                            $scope.refresh();
                        },
                        $scope.configurationModel.WorkFlowModel.DblClickWorkflow.RootElementName,
                        $scope.portlet.InternalName);
                } else {
                    var config = {
                        workflowIdentifier: $scope.configurationModel.WorkFlowModel.DblClickWorkflow.Id, //change name
                        dataObject: dataToSend,
                        callType: appConfig.WorkflowOperationType.DBL_CLICK_WORKFLOW,
                        onSuccessAction: function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                            $scope.refresh();
                        },
                        onErrorAction: function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                        },
                        filterDataObject: $scope.PaginationModel.RowFilter,
                        dataDefinitions: $scope.configurationModel.Mappers,
                        portletInternalName: $scope.portlet.InternalName
                    };
                    $scope.flowService.callFlowWithTableData(
                        $scope.configurationModel.WorkFlowModel.DblClickWorkflow.RootElementName,
                        config);
                }
            }
        };

        $scope.runCustomFlow = function (flowId, userActions, datasetName) {

            var hasDataToLost = $scope.preventLostData.getForPortlet($scope.portlet.Id);

            if (hasDataToLost) {
                $scope.openUnsavedChangesModal();
            } else {
                $scope.controllingModel.DisplayLoadingBar = true;

                var selectedRows = null;
                var dataToSend = null;
                var callType = null;

                if (userActions) {

                    if ($scope.selectionModel.selectedRows.length > 0) {
                        selectedRows = $scope.selectionModel.selectedRows;
                        callType = appConfig.WorkflowOperationType.CUSTOM_WORKFLOW;
                    };
                    $scope.flowService.runFlowUserAction($scope,
                        flowId,
                        selectedRows,
                        $scope.configurationModel.Mappers,
                        callType,
                        $scope.PaginationModel.RowFilter,
                        function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                            $scope.refresh();
                        },
                        datasetName,
                        $scope.portlet.InternalName
                    );
                }
                else {
                    if ($scope.selectionModel.selectedRows.length > 0) {

                        callType = appConfig.WorkflowOperationType.CUSTOM_WORKFLOW,
                            dataToSend = [];

                        angular.forEach($scope.selectionModel.selectedRows,
                            function (rowObject) {
                                dataToSend.push($scope.mapBooleanDataFrom(rowObject));
                            });
                    }
                    var objToSend = {
                        workflowIdentifier: flowId,
                        dataObject: dataToSend,
                        callType: callType,
                        onSuccessAction: function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                            $scope.refresh();
                        },
                        onErrorAction: function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                        },
                        filterDataObject: $scope.PaginationModel.RowFilter,
                        dataDefinitions: $scope.configurationModel.Mappers,
                        portletInternalName: $scope.portlet.InternalName
                    };
                    $scope.flowService.callFlowWithTableData(datasetName, objToSend);
                }
            }
        };

        $scope.mapBooleanDataFrom = function (data) {

            var toReturn = data;

            for (var property in toReturn) {
                if (toReturn.hasOwnProperty(property)) {
                    if (typeof toReturn[property] === "boolean") {
                        if (toReturn[property] === true) {
                            toReturn[property] = "1";
                        } else {
                            toReturn[property] = "0";
                        }
                    }
                }
            }

            return toReturn;
        };
        $scope.mapBooleanDataTo = function (data) {
            angular.forEach($scope.configurationModel.Mappers,
                function (m) {
                    if (m.Editor === "Checkbox") {
                        angular.forEach(data.Rows,
                            function (row) {
                                if (_.isString(row[m.DatabaseColumn])) {
                                    if (row[m.DatabaseColumn].toLowerCase() === "true") row[m.DatabaseColumn] = true;
                                    else row[m.DatabaseColumn] = false;
                                } else {
                                    if (row[m.DatabaseColumn] == true) { /*With two equal signs as we are fine with 1, "1", true*/
                                        row[m.DatabaseColumn] = true;
                                    } else {
                                        row[m.DatabaseColumn] = false;
                                    }
                                }
                            });
                    }
                });
        };

        $scope.callCrudFlow = function (options, WorkflowIdentifier, action) {

            $scope.controllingModel.DisplayLoadingBar = true;

            var rawData;
            var dataToSend = [];

            if (options.data.models) {
                rawData = options.data.models;

            } else {
                rawData = [options.data];
            }

            angular.forEach(rawData, function (object, index) {
                object.Type = action;
                dataToSend.push($scope.mapBooleanDataFrom(object));
            });

            if (WorkflowIdentifier) {
                $scope.controllingModel.DisplayLoadingBar = true;
                var config = {
                    workflowIdentifier: WorkflowIdentifier,
                    dataObject: dataToSend,
                    callType: action,
                    onSuccessAction: function () {
                        $scope.controllingModel.DisplayLoadingBar = false;
                        $scope.refresh();
                    },
                    onErrorAction: function () {
                        $scope.controllingModel.DisplayLoadingBar = false;
                    },
                    filterDataObject: $scope.PaginationModel.RowFilter,
                    dataDefinitions: $scope.configurationModel.Mappers,
                    portletInternalName: $scope.portlet.InternalName
                };
                $scope.flowService.callFlowWithTableData(
                    $scope.configurationModel.WorkFlowModel.CrudWorkflow.RootElementName,
                    config);
            }

            $scope.controllingModel.DisplayLoadingBar = false;
        };

        $scope.customFlowDisabled = function (flowConfigId) {
            var flowConfig = _.find($scope.enumerableFlowConfigs, function (item) { return item.Id === flowConfigId; });

            if (flowConfig.AlwaysEnabled) {
                return false;
            }

            if ($scope.selectionModel.emptySelection) {
                return true;
            }

            var result = _.some($scope.selectionModel.selectedRows, function (row) {
                return !dataEvalService.evaluateExpression(dataEvalService.createNewDataSet(row),
                    flowConfig.LeftValue,
                    flowConfig.Func,
                    flowConfig.RightValue);
            });

            return result;
        };

        $scope.initialize = function () {

            $scope.flowService = flowService;
            //luczpl 
            // $scope.errorService = new portletErrorService($scope, $scope.portlet);
        };

        $scope.initialize();

    }]);