app.controller("GanttController",
    [
        "$scope", "$rootScope", "$controller", "configurationService", "GanttService", "$timeout", "pluginConfigurationService", "translationFlowClientService",
        "dataEvalService", "translationsService", "editorService", "$compile", "imageService", "receiverService", "operationAndInputFlowService",
        function ($scope,
            $rootScope,
            $controller,
            configurationService,
            GanttService,
            $timeout,
            pluginConfigurationService,
            translationFlowClientService,
            dataEvalService,
            translationsService,
            editorService,
            $compile,
            imageService,
            receiverService,
            operationAndInputFlowService 
            ) {

            $controller("BasePluginController", { $scope: $scope });
            $controller("OperationAndInputExecutionController", { $scope: $scope });
            $scope.showFlag = true;
            $scope.configurationModel =
                pluginConfigurationService.getConfigurationModel($scope.portlet.Configuration, "Gantt");

         

            $scope.controllingModel = configurationService.getControllingModel();
            $scope.editorService = editorService;
            $scope.selectedItem = null;
            $scope.controllingModel.DisplayLoadingBar = true;


            $scope.$on("portletTabChanged",
                function(event, data) {
                    if (data && data.portlet && $scope.portlet.Id === data.portlet) {
                        $scope.redraw();
                    }
                });

            $scope.$on("onMaximized",
                function(event, data) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].Id === $scope.portlet.Id) {
                            $scope.redraw();
                            break;
                        }
                    }

                });

            $scope.$on("onMinimized",
                function(event, data) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].Id === $scope.portlet.Id) {
                            $scope.redraw();
                            break;
                        }
                    }
                });


            $scope.$on("resize",
                function(event, data) {
                    if (data) {
                        for (var i = 0; i < data.PortletIds.length; i++) {
                            if (data.PortletIds[i] === $scope.portlet.Id) {
                                $scope.redraw();
                                break;
                            }
                        }
                    }
                });

            $scope.$on(
                "$destroy",
                function () {
                    $timeout.cancel($scope.startTimer);
                    $timeout.cancel($scope.redrawTimer);
                }
            );

            $scope.$on("onRowSelected",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                        $scope.updateRequestsWithRowFilter(data);
                        $scope.refresh();
                    }
                });

            $scope.$on("onRefresh",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.refresh();
                    }
                });

            $scope.$on("onFilter",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
                        $scope.updateRequestsWithFilters(data);
                        $scope.refresh();
                    }
                });

            $scope.$on("onGenericTreeNodeSelected",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
                        $scope.updateRequestsWithRowFilter(data);
                        $scope.refresh();
                    }
                });




            $scope.createRequests = function() {
                $scope.tasksRequest = {};   
                $scope.tasksRequest.PortletId = $scope.portlet.Id;
                if ($scope.portlet.Queries[0]) {
                    $scope.tasksRequest.QueryId = $scope.portlet.Queries[0].Id;
                }
                if ($scope.portlet.Workflows[0]) {
                    $scope.tasksRequest.WorkflowId = $scope.portlet.Workflows[0].WorkflowId;
                    $scope.tasksRequest.OutputVariable = $scope.portlet.Workflows[0].OutputVariable;
                }
                $scope.tasksRequest.DataSourceType = $scope.portlet.DataSourceType;

                $scope.resourcesRequest = {};
                $scope.resourcesRequest.PortletId = $scope.portlet.Id;
                if ($scope.portlet.Queries[1]) {
                    $scope.resourcesRequest.QueryId = $scope.portlet.Queries[1].Id;
                }
                if ($scope.portlet.Workflows[1]) {
                    $scope.resourcesRequest.WorkflowId = $scope.portlet.Workflows[1].WorkflowId;
                    $scope.resourcesRequest.OutputVariable = $scope.portlet.Workflows[1].OutputVariable;
                }
                $scope.resourcesRequest.DataSourceType = $scope.portlet.DataSourceType;

                $scope.dependenciesRequest = {};
                $scope.dependenciesRequest.PortletId = $scope.portlet.Id;
                if ($scope.portlet.Queries[2]) {
                    $scope.dependenciesRequest.QueryId = $scope.portlet.Queries[2].Id;
                }
                if ($scope.portlet.Workflows[2]) {
                    $scope.dependenciesRequest.WorkflowId = $scope.portlet.Workflows[2].WorkflowId;
                    $scope.dependenciesRequest.OutputVariable = $scope.portlet.Workflows[2].OutputVariable;
                }
                $scope.dependenciesRequest.DataSourceType = $scope.portlet.DataSourceType;

                $scope.assignmentsRequest = {};

                $scope.assignmentsRequest.PortletId = $scope.portlet.Id;
                if ($scope.portlet.Queries[3]) {
                    $scope.assignmentsRequest.QueryId = $scope.portlet.Queries[3].Id;
                }
                if ($scope.portlet.Workflows[3]) {
                    $scope.assignmentsRequest.WorkflowId = $scope.portlet.Workflows[3].WorkflowId;
                    $scope.assignmentsRequest.OutputVariable = $scope.portlet.Workflows[3].OutputVariable;
                }
                $scope.assignmentsRequest.DataSourceType = $scope.portlet.DataSourceType;

            };

            $scope.updateRequestsWithRowFilter = function(data) {
                $scope.tasksRequest.RowFilter = data.Data;                
                $scope.dependenciesRequest.RowFilter = data.Data;
                $scope.resourcesRequest.RowFilter = data.Data;
                $scope.assignmentsRequest.RowFilter = data.Data;
            };

            $scope.updateRequestsWithFilters= function (data) {
                $scope.tasksRequest.Search = data.Data;
                $scope.dependenciesRequest.Search = data.Data;
                $scope.resourcesRequest.RowFilter = data.Data;
                $scope.assignmentsRequest.RowFilter = data.Data;
            };



            $scope.calculateTaskIcon = function (dataItem) {

                for (var i = 0; i < $scope.orderedIconRules.length; i++) {
                    var currentRule = $scope.orderedIconRules[i];
                    if (dataItem.Data) {
                        if ($scope.ruleIsMatching(dataItem.Data, currentRule)) {
                            return currentRule.Icon;
                        }
                    }
                }
                return $scope.configurationModel.IconsConfiguration.DefaultIcon;
            };

            $scope.calculateTaskColor = function(dataItem) {

                for (var i = 0; i < $scope.configurationModel.ColorRulesConfiguration.ColorRules.length; i++) {
                    var currentRule = $scope.configurationModel.ColorRulesConfiguration.ColorRules[i];

                    if ($scope.ruleIsMatching(dataItem.Data, currentRule)) {
                        return currentRule.Colour;
                    }
                }

                return $scope.configurationModel.ColorRulesConfiguration.DefaultColor;
            };

            $scope.ruleIsMatching = function(dataItem, rule) {
                return dataEvalService.evaluateExpression(
                    dataEvalService.createNewDataSet(dataItem),
                    "{" + rule.FirstValue + "}",
                    rule.Func,
                    rule.SecondValue);
            };

            $scope.createFields = function() {
                var toReturn = {
                    id: { from: "Id", type: "string" },
                    orderId: { from: "OrderId", type: "number", validation: { required: true } },
                    parentId: { from: "ParentId", type: "string", defaultValue: null, validation: { required: true } },
                    start: { from: "Start", type: "date" },
                    end: { from: "End", type: "date" },
                    title: { from: "Title", defaultValue: "", type: "string" },
                    color: { from: "Color", defaultValue: "", type: "string" },
                    percentComplete: { from: "PercentComplete", type: "number" },
                    summary: { from: "Summary", type: "boolean" },
                    expanded: { from: "Expanded", type: "boolean", defaultValue: true }
                };

                for (var i = 0; i < $scope.configurationModel.ColumnsConfiguration.Columns.length; i++) {

                    var colType = "string";

                    var editor = $scope.configurationModel.ColumnsConfiguration.Columns[i].Editor;

                    if (editor === "Date" || editor === "Time" || editor === "DateTime") {
                        colType = "date";
                    } else if (editor === "Numeric" || editor === "Decimal") {
                        colType = "number";
                    } else if (editor === "Checkbox") {
                        colType = "boolean";
                    }

                    toReturn[$scope.configurationModel.ColumnsConfiguration.Columns[i].DatabaseColumn] = {
                        from: "Data." + $scope.configurationModel.ColumnsConfiguration.Columns[i].DatabaseColumn,
                        defaultValue: "",
                        type: colType
                    };
                }

                return toReturn;

            };

            $scope.refresh = function () {
                var gantt = $("#gantt" + $scope.portlet.Id).getKendoGantt();
				if (gantt) {
					$rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });

                    $scope.tasksRequest.RefreshWorkflowCache = true;
                    $scope.dependenciesRequest.RefreshWorkflowCache = true;
                    $scope.resourcesRequest.RefreshWorkflowCache = true;
                    $scope.assignmentsRequest.RefreshWorkflowCache = true;
                    gantt.dataSource.read();
                    gantt.dependencies.read();
                    gantt.resources.dataSource.read();
					gantt.assignments.dataSource.read();
                }
            };

            $scope.redraw = function () {
                $scope.showFlag = false;
                $scope.redrawTimer = $timeout(function() {
                        $scope.initialized = false;
                        $scope.showFlag = true;
                    },
                    50);
            };

            

            $scope.customFlowDisabled = function(flowId) {

                var flowConfig = null;

                for (var i = 0; i < $scope.configurationModel.FlowConfiguration.CustomFlows.length; i++) {
                    if ($scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow.Id == flowId) {
                        flowConfig = $scope.configurationModel.FlowConfiguration.CustomFlows[i];
                    }
                }

                if (flowConfig && flowConfig.AlwaysEnabled) {
                    return false;
                }

                if (!$scope.selectedItem) {
                    return true;
                }

                var result = false;

                if (flowConfig &&
                    !dataEvalService.evaluateExpression(dataEvalService.createNewDataSet($scope.selectedItem.Data),
					"{" + flowConfig.LeftValue + "}",
                        flowConfig.Func,
                        flowConfig.RightValue)) {
                    result = true;
                }


                return result;
            };

            $scope.createToolbar = function () {

                var toolbar = [];

                if ($scope.configurationModel.ShowTaskSelector) {
                    toolbar.push({
                        template: (
                            "<select id='taskComboBox" +
                                $scope.portlet.Id +
                                "' kendo-combo-box k-options='taskOptions' style='width:250px;margin-right:8px;'></select>"
                        )
                    });
                }

                for (var i = 0; i < $scope.configurationModel.FlowConfiguration.CustomFlows.length; i++) {
                    if ($scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow) {
                        toolbar.push({
                            Id: $scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow.Id,
                            text: $scope.configurationModel.FlowConfiguration.CustomFlows[i].DisplayName,
                            template: '<button id="' +
                                + $scope.portlet.Id + $scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow.Id +
                                '" ng-click="runCustomFlow(\'' +
                                $scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow.Id +
                                "', '" +
                                $scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow
                                .IsMachineWorkflow +
                                '\')" class="k-button k-button-icontext k-grid-export" ng-disabled="customFlowDisabled(\'' +
                                $scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow.Id +
                                '\')">' +
                                translationsService.get(
                                    $scope.configurationModel.FlowConfiguration.CustomFlows[i].Code + "-DisplayName",
                                    $scope.configurationModel.FlowConfiguration.CustomFlows[i].DisplayName) +
                                "</button>"
                        });
                    }
                }


                return toolbar;
            };

            $scope.createTaskTooltip = function () {
                var tooltip = null;
                if ($scope.configurationModel.TooltipConfiguration &&
                    $scope.configurationModel.TooltipConfiguration.Tooltip) {
                    tooltip = translationsService.get(
                        $scope.configurationModel.TooltipConfiguration.Code + "-Tooltip",
                        $scope.configurationModel.TooltipConfiguration.Tooltip);

                    for (var i = 0; i < $scope.configurationModel.TooltipConfiguration.Fields.length; i++) {

                        var editor = $scope.configurationModel.TooltipConfiguration.Fields[i].Editor;

                        tooltip = tooltip.replace(new RegExp("{ ", "g"), "{");
                        tooltip = tooltip.replace(new RegExp(" }", "g"), "}");

                        if (editor === "Decimal") {

                            var length = $scope.configurationModel.TooltipConfiguration.Fields[i].Length;

                            if (length) {
                                tooltip = tooltip.replace(
                                    new RegExp(
                                        "{" + $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn + "}",
                                        "g"),
                                    '#= kendo.toString(kendo.parseFloat(task.Data.' +
                                    $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn +
                                    '),\'n' +
                                    length +
                                    '\')#');
                            }
                        }
                        else if (editor === "Date") {
                            tooltip = tooltip.replace(
                                new RegExp(
                                    "{" + $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn + "}",
                                    "g"),
                                '#= kendo.toString(kendo.parseDate(task.Data.' +
                                $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn +
                                '),\'' +
                                kendo.culture().calendar.patterns.d +
                                '\')#');
                        } else if (editor === "Time") {
                            tooltip = tooltip.replace(
                                new RegExp(
                                    "{" + $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn + "}",
                                    "g"),
                                '#= kendo.toString(kendo.parseDate(task.Data.' +
                                $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn +
                                '),\'' +
                                kendo.culture().calendar.patterns.T +
                                '\')#');
                        } else if (editor === "DateTime") {
                            tooltip = tooltip.replace(
                                new RegExp(
                                    "{" + $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn + "}",
                                    "g"),
                                '#= kendo.toString(kendo.parseDate(task.Data.' +
                                $scope.configurationModel.TooltipConfiguration.Fields[i].DatabaseColumn +
                                '),\'' +
                                kendo.culture().calendar.patterns.G +
                                '\')#');
                        }
                    }
                    tooltip = tooltip.replace(new RegExp('{', 'g'), "#=task.Data.");
                    tooltip = tooltip.replace(new RegExp('}', 'g'), "#");
                }

                return tooltip;
            };

            $scope.runCustomFlow = function(id, isMachineWorkflow) {

                var isUserAction = false;
                if (isMachineWorkflow === "false") {
                    isUserAction = true;
                }

                if ($scope.selectedItem) {

                   var operationInputData = {
                        OperationType: "CustomWorkflow",
                        TaskId: $scope.selectedItem.Id
                    };

                    $scope.runFlow(id, isUserAction, [$scope.selectedItem.Data], operationInputData);
                } else {
                    $scope.runFlow(id, isUserAction, [], { OperationType: "CustomWorkflow"});
                }
            };

            $scope.onEdit = function(e) {
                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.DoubleClickTask &&
                    $scope.configurationModel.FlowConfiguration.DoubleClickTask !== -1) {

                    var operationInputData = {
                        OperationType: "DoubleClick",
                        TaskId: e.task.id
                    };

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DoubleClickTask.Id,
                        !$scope.configurationModel.FlowConfiguration.DoubleClickTask.IsMachineWorkflow,
                        [e.task.Data],
                        operationInputData);
                }
            };

            $scope.onAdd = function(e) {

                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.CreateDependency &&
                    $scope.configurationModel.FlowConfiguration.CreateDependency !== -1) {
                    var operationInputData = {
                        OperationType: "CreateDependency",
                        PredecessorId: e.dependency.predecessorId,
                        SuccessorId: e.dependency.successorId,
                        DependencyType: e.dependency.type
                    };

                    var newDependency = {};
                    if ($scope.configurationModel.DependenciesDatasource.SuccessorIdColumnName)
                        newDependency[$scope.configurationModel.DependenciesDatasource.SuccessorIdColumnName] =
                            e.dependency.successorId;
                    if ($scope.configurationModel.DependenciesDatasource.PredecessorIdColumnName)
                        newDependency[$scope.configurationModel.DependenciesDatasource.PredecessorIdColumnName] =
                            e.dependency.predecessorId;
                    if ($scope.configurationModel.DependenciesDatasource.TypeColumnName)
                        newDependency[$scope.configurationModel.DependenciesDatasource.TypeColumnName] =
                            e.dependency.type;


                    $scope.runFlow($scope.configurationModel.FlowConfiguration.CreateDependency.Id,
                        !$scope.configurationModel.FlowConfiguration.CreateDependency.IsMachineWorkflow,
                        [newDependency],
                        operationInputData);
                }
            };

            $scope.onRemove = function(e) {

                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.DeleteDependency &&
                    $scope.configurationModel.FlowConfiguration.DeleteDependency !== -1) {
                    var operationInputData = {
                        OperationType: "DeleteDependency",
                        DependencyId: e.dependencies[0].id
                    };

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DeleteDependency.Id,
                        !$scope.configurationModel.FlowConfiguration.DeleteDependency.IsMachineWorkflow,
                        [e.dependencies[0].Data],
                        operationInputData);
                }
            };

            $scope.moveEnd = function(e) {
                $scope.controllingModel.DisplayLoadingBar = true;
                e.preventDefault();

                if ($scope.configurationModel.FlowConfiguration.DropTask &&
                    $scope.configurationModel.FlowConfiguration.DropTask !== -1) {
                    var operationInputData = {
                        OperationType: "Drop",
                        TaskId: e.task.id,
                        StartDate: e.start,
                        EndDate: e.end
                    };

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DropTask.Id,
                        !$scope.configurationModel.FlowConfiguration.DropTask.IsMachineWorkflow,
                        [e.task.Data],
                        operationInputData);

                }
            };

            $scope.resizeEnd = function(e) {
                $scope.controllingModel.DisplayLoadingBar = true;
                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.DropTask &&
                    $scope.configurationModel.FlowConfiguration.DropTask !== -1) {
                    var operationInputData = {
                        OperationType: "Drop",
                        TaskId: e.task.id,
                        StartDate: e.start,
                        EndDate: e.end
                    };

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DropTask.Id,
                        !$scope.configurationModel.FlowConfiguration.DropTask.IsMachineWorkflow,
                        [e.task.Data],
                        operationInputData);

                }
            };

            $scope.onDataBound = function(e) {
                
                $scope.startTimer = $timeout(function () {
                    var gantt = $("#gantt" + $scope.portlet.Id).data("kendoGantt");

                    if (!$scope.initialized) {
                        if (gantt) {
                            gantt.element.find("ul.k-reset.k-header.k-gantt-views").before(
                                "<a class='k-pager-refresh k-link' href='#' ng-click='refresh();$event.preventDefault();' style='float:right' id='refresh-button" +
                                $scope.portlet.Id +
                                "'><span class='k-icon k-i-refresh'></span></a>");
                            $compile($("#refresh-button" + $scope.portlet.Id))($scope);
                            $compile($("#taskComboBox" + $scope.portlet.Id))($scope);

                            for (var i = 0; i < $scope.configurationModel.FlowConfiguration.CustomFlows.length; i++) {
                                if ($scope.configurationModel.FlowConfiguration.CustomFlows[i] &&
                                    $scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow) {
                                    $compile($("#" + $scope.portlet.Id + $scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow.Id))(
                                        $scope);
                                }
                            }
                        }

                    }

                    $scope.initialized = true;


                    if (gantt) {
                        gantt.element.find(".k-task-milestone,.k-task-summary").each(function (e) {
                            var dataItem = gantt.dataSource.getByUid($(this).attr("data-uid"));
                            var color = $scope.calculateTaskColor(dataItem);

                            if (color) {
                                this.style.backgroundColor = color;
                                this.style.borderColor = color;
                            }
                        });
                    }

                    var ganttList = e.sender.list;

                    var dataItems = ganttList.dataSource.view();
                    for (var j = 0; j < dataItems.length; j++) {
                        var dataItem = dataItems[j];

                        var color = $scope.calculateTaskColor(dataItem);

                        if (color) {
                            var row = $("[data-uid='" + dataItem.uid + "']");

                            row.find("td").find("span").each(function (index) {
                                if ($(this).text() == 'null') {
                                    $(this).empty();
                                }
                            });

                            var span = row.find("td").eq($scope.TitleColumnIndex).find("span").last();
                            span
                                .parent()
                                .find(".gantt-treelist-circle")
                                .remove();
                            span.prepend("<div style='background:" + color + "' class='gantt-treelist-circle'></div>");
                        }
                    }
                    },
                    50);

                
            };

            $scope.onChange = function (e) {
                var gantt = e.sender;
                var selection = gantt.select();

                if (selection.length) {
                    $scope.selectedItem = gantt.dataItem(selection);
                    $scope.scrollToRight();
                } else {
                    $scope.selectedItem = null;
                }
                if (!$scope.$$phase) {
                    if($scope.selectedItem)
                        $rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: $scope.selectedItem.Data });

                    $scope.$apply();
                }
            };

            $scope.createColumns = function() {

                var toReturn = [];

                for (var i = 0; i < $scope.configurationModel.ColumnsConfiguration.Columns.length; i++) {
                    var column = {
                        title: translationsService.get($scope.configurationModel.ColumnsConfiguration.Columns[i].Code + '-DisplayName', $scope.configurationModel.ColumnsConfiguration.Columns[i].ColumnName),
                        editable: false,
                        sortable: false,
                        width: $scope.configurationModel.ColumnsConfiguration.Columns[i].Width
                    };

                    if ($scope.configurationModel.TasksDatasource.TaskTitleColumnName ===
                        $scope.configurationModel.ColumnsConfiguration.Columns[i].DatabaseColumn) {
                        column.field = "title";
                        $scope.TitleColumnIndex = i;
                    } else {
                        column.field = $scope.configurationModel.ColumnsConfiguration.Columns[i].DatabaseColumn;
                    }

                    var format = null;

                    var editor = $scope.configurationModel.ColumnsConfiguration.Columns[i].Editor;


                    if (editor === "Decimal") {

                        var length = $scope.configurationModel.ColumnsConfiguration.Columns[i].Length;

                        if (length) {
                            format = "{0:n" + length + "}";
                        }
                    } else if (editor === "Date") {
                        format = "{0:" + kendo.culture().calendar.patterns.d + "}";
                    } else if (editor === "Time") {
                        format = "{0:" + kendo.culture().calendar.patterns.T + "}";
                    } else if (editor === "DateTime") {
                        format = "{0:" + kendo.culture().calendar.patterns.G + "}";
                    }


                    if (format) {
                        column.format =
                            format;
                    }

                    toReturn.push(column);
                }

                var hasTitle = false;
                for (var j = 0; j < toReturn.length; j++) {
                    if (toReturn[j].field === "title") {
                        $scope.TitleColumnIndex = j;
                        hasTitle = true;
                    }
                }

                if (!hasTitle) {
                    toReturn.unshift({ field: "title", title: "Title", editable: false, sortable: false, width: 200 });
                    $scope.TitleColumnIndex = 0;
                }


                return toReturn;
            };

            $scope.scrollToTask = function (ganttId, taskUid) {

                //NCRND-3217
                $(ganttId).closest('.portletContainerContent').animate({
                   scrollTop: 0
                });

                var taskTr = $('#gantt' + $scope.portlet.Id + ' tr[data-uid="' + taskUid + '"]');

                if (taskTr.length > 0) {
                    var ypos = taskTr[0].offsetTop;

                    $(ganttId).find(".k-grid-content").animate({
                            scrollTop: ypos
                        },
                        500);
                }
            };

            $scope.scrollToRight = function () {
                var index = $("#gantt" + $scope.portlet.Id).data("kendoGantt").select().index();
                var taskWrap = $('#gantt' + $scope.portlet.Id).find('.k-gantt-tasks').find('tr:eq(' + index + ')')
                    .find(".k-t" +
                        "ask-wrap");

                if (taskWrap.length > 0) {
                    var xpos = taskWrap[0].offsetLeft;

                    $("#gantt" + $scope.portlet.Id).find(".k-grid-content:eq(1)").animate({
                            scrollLeft: xpos
                        },
                        500);
                }
            };

            $scope.taskOptions = {
                filter: "contains",
                dataTextField: "Title",
                dataValueField: "Id",
                filtering: function() {
                    $("#gantt" + $scope.portlet.Id).data("kendoGantt").clearSelection();
                },
                open: function() {
                    var tasks = [];
                    var dataItems = $("#gantt" + $scope.portlet.Id).data("kendoGantt").dataSource.view();
                    for (var j = 0; j < dataItems.length; j++) {
                        tasks.push({ Title: dataItems[j].title, Id: dataItems[j].uid });
                    }
                    this.dataSource.data(_.sortBy(tasks, 'Title'));
                },
                select: function (e) {
                    if (e.dataItem) {
                        var gantt = $("#gantt" + $scope.portlet.Id).data("kendoGantt");
                        gantt.select('tr[data-uid="' + e.dataItem.Id + '"]');
                        $scope.scrollToTask("#gantt" + $scope.portlet.Id, e.dataItem.Id);
                    }
                },
                dataSource: []

                
            };

            $scope.getOptions = function () {
                var toolBarContent = $scope.createToolbar();
                var tooltipContent = $scope.createTaskTooltip();

                var toReturn = {
                    dataSource: new kendo.data.GanttDataSource({
                        batch: true,
                        transport: {
                            read: function (options) {
                                $scope.controllingModel.DisplayLoadingBar = true;
                                GanttService.getTasks($scope.tasksRequest, $scope.portlet.Id)
                                    .then(function (result) {
                                        for (var i = 0; i < result.data.length; i++) {

                                            result.data[i].Color = $scope.calculateTaskColor(result.data[i]);
                                            //icon per task
                                            var iconToUse=$scope.calculateTaskIcon(result.data[i]);
                                            if (iconToUse) {
                                                result.data[i].Icon = iconToUse;
                                            } else
                                                result.data[i].Icon = null;
                                        }
                                        options.success(result.data);
                                    }).catch(function () {
                                        options.success([]);
                                    })
                                    .finally(function () {
                                        $scope.tasksRequest.RefreshWorkflowCache = false;
                                        $scope.controllingModel.DisplayLoadingBar = false;
                                    });
                            },
                            parameterMap: function (options, operation) {
                                if (operation !== "read") {
                                    return { models: kendo.stringify(options.models || [options]) };
                                }
                            }
                        },
                        schema: {
                            model: {
                                id: "id",
                                fields: $scope.createFields()
                            }
                        }
                    }),
                    toolbar: toolBarContent.length ? toolBarContent : undefined,
                    tooltip: tooltipContent ? {
                        visible: true,
                        template: tooltipContent
                    } : {
                        visible: false
                    },
                    edit: $scope.onEdit,
                    add: $scope.onAdd,
                    moveEnd: $scope.moveEnd,
                    resizeEnd: $scope.resizeEnd,
                    change: $scope.onChange,
                    remove: $scope.onRemove,
                    dataBound: $scope.onDataBound,
                    dataBinding: $scope.onDataBinding,
                    views: [
                        {
                            type: "day",
                            title: translationFlowClientService.getValueFromDictionary('DaysMode', 'Days'),
                            dayHeaderTemplate: kendo.template("#=kendo.toString(start, 'd')#")
                        },
                        {
                            type: "week",
                            selected: true,
                            title: translationFlowClientService.getValueFromDictionary('WeeksMode', 'Weeks'),
                            weekHeaderTemplate: "#=kendo.toString(start, 'd')# - #=kendo.toString(kendo.date.addDays(end, -1), 'd')#",
                            dayHeaderTemplate: kendo.template("#=kendo.toString(start, 'd')#")
                        },
                        {
                            type: "month",
                            title: translationFlowClientService.getValueFromDictionary("MonthsMode", "Months"),
                            monthHeaderTemplate: "#=kendo.toString(start, 'yyyy MMM')#",
                            weekHeaderTemplate:
                                "#=kendo.toString(start, 'MM-dd')# - #=kendo.toString(kendo.date.addDays(end, -1), 'MM-dd')#"
                        },
                        {
                            type: "year",
                            title: translationFlowClientService.getValueFromDictionary("YearsMode", "Years"),
                            monthHeaderTemplate: "#=kendo.toString(start, 'yyyy MMM')#",
                            yearHeaderTemplate: "#=kendo.toString(start, 'yyyy')#"
                        }
                    ],
                    columns: $scope.createColumns(),

                    showWorkHours: $scope.configurationModel.ShowWorkHours,
                    showWorkDays: $scope.configurationModel.ShowWorkDays,
                    taskTemplate: $("#task-template").html(),
                    height: parseInt($scope.configurationModel.Height),
                    listWidth: parseInt($scope.configurationModel.ListWidth),
                    editable: {
                        confirmation: false,
                        dragPercentComplete: false,
                        destroy: false,
                        reorder:false,
                        create: false
                    }
                };

                if ($scope.configurationModel.ShowDependencies) {
                    toReturn.dependencies = new kendo.data.GanttDependencyDataSource({
                        transport: {
                            read: function (options) {
                                GanttService.getDependencies($scope.dependenciesRequest, $scope.portlet.Id)
                                    .then(function (result) {
                                        options.success(result.data);
                                    }).catch(function () {
                                        options.success([]);
                                    })
                                    .finally(function () {
                                        $scope.dependenciesRequest.RefreshWorkflowCache = false;
                                    });
                            },
                            parameterMap: function (options, operation) {
                                if (operation !== "read") {
                                    return { models: kendo.stringify(options.models || [options]) };
                                }
                            }
                        },
                        schema: {
                            model: {
                                id: "id",
                                fields: {
                                    id: { from: "Id", type: "string" },
                                    predecessorId: { from: "PredecessorId", type: "string" },
                                    successorId: { from: "SuccessorId", type: "string" },
                                    type: { from: "Type", type: "number" }
                                }
                            }
                        }
                    });;
                }

                if ($scope.configurationModel.ShowResources) {
                    toReturn.resources = {
                        field: "resources",
                        dataTextField: "Title",
                        dataSource: {
                            transport: {
                                read: function (options) {
                                    GanttService.getResources($scope.resourcesRequest, $scope.portlet.Id)
                                        .then(function (result) {
                                            options.success(result.data);
                                        }).catch(function () {
                                            options.success([]);
                                        }).finally(function () {
                                            $scope.resourcesRequest.RefreshWorkflowCache = false;
                                        });
                                }
                            },
                            schema: {
                                model: {
                                    id: "id",
                                    fields: {
                                        id: { from: "Id", type: "string" }
                                    }
                                }
                            }
                        }
                    };
                    toReturn.assignments = {
                        dataTaskIdField: "TaskId",
                        dataResourceIdField: "ResourceId",
                        dataValueField: "Units",
                        dataSource: {
                            transport: {
                                read: function (options) {
                                    GanttService.getAssignments($scope.assignmentsRequest, $scope.portlet.Id)
                                        .then(function (result) {
                                            options.success(result.data);
                                        }).catch(function () {
                                            options.success([]);
                                        }).finally(function () {
                                            $scope.assignmentsRequest.RefreshWorkflowCache = false;
                                        });
                                },
                                parameterMap: function (options, operation) {
                                    if (operation !== "read") {
                                        return { models: kendo.stringify(options.models || [options]) };
                                    }
                                }
                            },
                            schema: {
                                model: {
                                    id: "Id",
                                    fields: {
                                        Id: { type: "string" },
                                        ResourceId: { type: "string" },
                                        Units: { type: "number" },
                                        TaskId: { type: "string" }
                                    }
                                }
                            }
                        }
                    };
                }
                return toReturn;
            };

            $scope.initialize = function() {
                $scope.imageService = imageService;
                $scope.operationAndInputFlowService = operationAndInputFlowService;

                $scope.orderedIconRules = _.sortBy($scope.configurationModel.IconsConfiguration.Icons,
                    function (icon) {
                        return icon.Position;
                    });
           
                $scope.createRequests();

                $scope.startTimer = $timeout(function() {
                    $scope.ganttOptions = $scope.getOptions();
                    },
                    50);
            }();
        }
    ]);