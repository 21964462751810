app.service('dataVisualizerCardService', ['basePluginService',  'appConfig', '$q',
    function (basePluginService, appConfig, $q) {

        angular.extend(this, basePluginService);

        var service = {};

        service.getData = function (paginationModel, portletId) {
            return basePluginService.backend.post('queryExecution', paginationModel, portletId);
        };
        return service;


    }]);