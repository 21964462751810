app.factory('inboxPluginConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);
        configurationModel.inboxId = propertyService.getPropertyValue(properties, "inboxId");
        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }

    service.saveProperties = function (properties, configurationModel, operation) {
        configurationService.saveProperties(properties, configurationModel);
        propertyService.setProperty(properties, "inboxId", configurationModel.inboxId);
    }

    return service;
}]);