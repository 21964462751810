app.service('TableService', ['basePluginService','$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getTableHeader = function (paginationModel,portletId) {
            return basePluginService.backend.post("tableHeader", paginationModel,portletId);
        };

        service.getTableData = function (paginationModel, portletId) {
            return basePluginService.backend.post("table", paginationModel, portletId);
        };

        service.readLovQuery = function (dataTosend, portletId) {
            return basePluginService.backend.post("lovQuery", dataTosend, portletId);
        };

        service.exportTable = function (paginationModel, portletId) {
            return basePluginService.backend.postWithHeaders("action/tableExport", paginationModel, {}, portletId,true);
        };

        return service;

}]);