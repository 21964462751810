app.controller('RecordView_CreateOrEditPortletController', [
    '$scope', '$uibModalInstance', '$rootScope', 'createOrEditModalService', 'recordViewConfigurationService', 'data', 'suggestionService',
    'editorService', 'connectorService', 'Guid', 'kendoGridUtilService', 'appConfig',
    function ($scope, $uibModalInstance, $rootScope, createOrEditModalService, recordViewConfigurationService, data,
        suggestionService, editorService, connectorService, Guid, kendoGridUtilService, appConfig) {

        $scope.connectorService = connectorService;     

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.model = createOrEditModalService.getModel("RecordView", data);

        $scope.configurationModel = recordViewConfigurationService.getConfigurationModel($scope.model.Properties);
    
        
        $scope.saveWorkflowSelected = function () {  
            var cfgModel = $scope.configurationModel.SaveWorkFlow;

            if (angular.isUndefined(cfgModel) || cfgModel == null) {
                return false;
            }
            if (cfgModel == appConfig.TablePlugin.NO_WORKFLOW) {
                return false;
            }
            if (angular.isUndefined(cfgModel.Id) || angular.isUndefined(cfgModel.Name)) {
                return false;
            }
            return true;
        };

        $scope.numberOfColumnsDataSource = new kendo.data.DataSource({
            data: [1, 2, 3, 4]
        });

        $scope.onclickHidden = function (dataItem) {
            if (dataItem.IsHidden) {
                dataItem.IsHidden = false;
            } else {
                dataItem.IsHidden = true;
            }
        }
        $scope.onclickMultline = function (dataItem) {
            if (dataItem.IsMultiline) {
                dataItem.IsMultiline = false;
            } else {
                dataItem.IsMultiline = true;
            }
        }

        $scope.onclickEditable = function (dataItem) {
            if (dataItem.IsEditable) {
                dataItem.IsEditable = false;
            } else {
                dataItem.IsEditable = true;
            }
        }

        $scope.onclickUserActions = function (dataItem) {
            if (dataItem.UserActions) {
                dataItem.UserActions = false;
            } else {
                dataItem.UserActions = true;
            }
            angular.forEach($scope.configurationModel.FlowConfigurations, function (rowObject) {
                if (rowObject.Id == dataItem.Id) {
                    rowObject.UserActions = dataItem.UserActions;
                }
            });
        }

        $scope.querySuggestions = suggestionService.getSuggestions();

        $scope.groupHeaderOptions = {
            editable: {
                createAt: "bottom"
            },
            scrollable: false,
            navigatable: true,
            save: function (e) {
                for (var p = 0; p < $scope.mapperGrid.dataSource.data().length; p++) {
                    if ($scope.mapperGrid.dataSource.data()[p].Group == e.model.GroupName) {
                        $scope.mapperGrid.dataSource.data()[p].Group = e.values.GroupName;
                    }
                };
            },
            toolbar: [{ name: "create", text: "Add" }],
            columns: [
                { field: "GroupName", title: "Group name" },
                {
                    command: [{
                        name: "up",
                        template: "<a class='k-button k-button-icontext gridButton' ng-click='groupUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
                    },
                        {
                            name: "down",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='groupDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
                        },
                             { name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>" }],
                    width: 100
                }
            ],
            dataSource: new kendo.data.DataSource({
                batch: true,
                data: $scope.configurationModel.GroupHeaders,
                schema: {
                    model: {
                        fields: {

                            Code: { type: "string" },
                            IsVisible: { type: "boolean", defaultValue: true },
                            GroupName: {
                                type: "string",
                                validation: {
                                    required: true
                                }
                            }
                        }
                    }
                }
            })
        };

        $scope.groupUp = function (e) {
            for (var p = 0; p < $scope.groupGrid.dataSource.data().length; p++) {
                $scope.groupGrid.dataSource.data()[p].Position = p;
            }

            var dataItem = $scope.groupGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.groupGrid.dataSource.data();
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            if (index > 0) {
                $scope.groupGrid.dataSource.remove(dataItem);
                $scope.groupGrid.dataSource.insert(index - 1, dataItem);
            }
        };

        $scope.groupDown = function (e) {
            for (var p = 0; p < $scope.groupGrid.dataSource.data().length; p++) {
                $scope.groupGrid.dataSource.data()[p].Position = p;
            }

            var dataItem = $scope.groupGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.groupGrid.dataSource.data();

            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            $scope.groupGrid.dataSource.remove(dataItem);
            $scope.groupGrid.dataSource.insert(index + 1, dataItem);

        };

        $scope.editorService = editorService;

        $scope.mapperOptions = {
            editable: {
                createAt: "bottom"
            },
            scrollable: false,
            navigatable: true,

            toolbar: [
                {
                     name: "create", text: "Add"
                },
                {
                    name: "errInfo",
                    template: '<span ng-show="hasMappersDuplicated" style="margin-left:10px; font-size:12px;" class="label label-danger">Rows duplicated!</span>'
                }],
            columns: [
                { field: "DatabaseColumn", title: "Database column", width: "210px" },
                { field: "DisplayName", title: "Display Name", width: "210px" },
                { field: "Group", title: "Group", width: "180px", editor: groupDropDownEditor },
                { field: "Lov", title: "Lov", width: "200px", editor: editorService.createLovComboBox },
                { field: "Editor", title: "Editor", width: "150px", editor: editorService.createEditorDropDownListWithoutCheckbox },
                { field: "Length", title: "Length", width: "100px" },
                { field: "IsHidden", title: "Hidden", width: "70px", template: "<input ng-click=onclickHidden(dataItem) type='checkbox' #= IsHidden ? checked='checked' : '' # ></input>" },
                { field: "IsEditable", title: "Editable", width: "70px", template: "<input ng-click=onclickEditable(dataItem) type='checkbox' #= IsEditable ? checked='checked' : '' # ></input>" },
                { field: "IsMultiline", title: "Multiline", width: "70px", template: "<input ng-click=onclickMultline(dataItem) type='checkbox' #= IsMultiline ? checked='checked' : '' # ></input>" },
                {
                    command: [{
                        name: "up",
                        template: "<a class='k-button k-button-icontext gridButton' ng-click='mapperUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
                    },
                        {
                            name: "down",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='mapperDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
                        },
                              {
                                  name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                              }],
                    width: 105
                }
            ],

            dataSource: new kendo.data.DataSource({
                data: $scope.configurationModel.Mappers,
                schema: {
                    model: {
                        fields: {
                            DatabaseColumn: {
                                type: "string", validation: { required: { message: "Field is required"} } },
                            DisplayName: { type: "string" },
                            Code: { type: "string" },
                            Editor: {
                                defaultValue: "Text"
                            },
                            Length: { type: "string" },
                            IsEditable: { type: "boolean", defaultValue: true },
                            Lov: { type: "string" },
                            IsHidden: { type: "boolean", defaultValue: false },
                            IsMultiline: { type: "boolean", defaultValue: false }

                        }
                    }
                }
            })
        };

        
        var column = editorService.createColumnNameComboBox($scope.mapperOptions.columns[0], $scope.model);
        var dataSource = editorService.getDdlDataSource($scope.mapperOptions.columns[0], $scope.model);

        /**
         * Overridden - see parent function in Editor Service. 
         * @param {} container 
         * @param {} options 
         * @returns {} 
         */
        column.editor = function (container, options) {
            $('<input required data-text-field="ColumnDescription" data-value-field="ColumnCode" data-bind="value:' + options.field + '"/>')
                .appendTo(container)
                .kendoComboBox({
                    filter: "contains",
                    dataSource: dataSource,
                    open: function () {
                        this.dataSource.data([]);
                        this.dataSource.read();
                    },
                    change: function (e) {
                        if ($("#mapperOptions").length) {
                            var mappers = $("#mapperOptions").data("kendoGrid").dataSource.data();

                            var dbColumns = _.flatMap(mappers, function (x) {
                                return x.DatabaseColumn;
                            });

                            var dbClumnsWithoutDuplicates = _.uniq(dbColumns);
                            var hasMappersDuplicated = dbClumnsWithoutDuplicates.length < dbColumns.length;
                            $scope.hasMappersDuplicated = hasMappersDuplicated;
                        }
                    }
                });
        };

        /**
         * 
         * @param {} container 
         * @param {} options 
         * @returns {} 
         */
        function groupDropDownEditor(container, options) {
            $('<input data-bind="value:' + options.field + '"/>')
                .appendTo(container)
                .kendoComboBox({
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: function (opts) {
                                var toShow = [];
                                for (var p = 0; p < $scope.groupGrid.dataSource.data().length; p++) {
                                    toShow.push($scope.groupGrid.dataSource.data()[p].GroupName);
                                }
                                opts.success(toShow);
                            }
                        }
                    })
                });
        }

        $scope.mapperUp = function (e) {
            for (var p = 0; p < $scope.mapperGrid.dataSource.data().length; p++) {
                $scope.mapperGrid.dataSource.data()[p].Position = p;
            }

            var dataItem = $scope.mapperGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.mapperGrid.dataSource.data();
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            if (index > 0) {
                $scope.mapperGrid.dataSource.remove(dataItem);
                $scope.mapperGrid.dataSource.insert(index - 1, dataItem);
            }
        };

        $scope.mapperDown = function (e) {

            for (var p = 0; p < $scope.mapperGrid.dataSource.data().length; p++) {
                $scope.mapperGrid.dataSource.data()[p].Position = p;
            }

            var dataItem = $scope.mapperGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.mapperGrid.dataSource.data();

            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            $scope.mapperGrid.dataSource.remove(dataItem);
            $scope.mapperGrid.dataSource.insert(index + 1, dataItem);

        };

        $scope.isCustomWorkflowsGridInEditMode = function () {
            var inEditMode = $('#customWorkflowsGrid').find('.k-grid-edit-row').length > 0 ? true : false;
            if (inEditMode) {
                return true;
            };
            return false;
        }

        $scope.flowOptions = {
            editable: "inline",
            scrollable: false,
            edit: function (e) {
                //change default action buttons
                var commandCell = e.container.find("td:last");
                commandCell.html(
                    "<a class='k-button k-button-icontext k-grid-update gridButton'><span class='k-icon k-i-checkmark'></span></a>" +
                    "<a class='k-button k-grid-cancel gridButton'><span class='k-icon k-i-cancel'></span></a>"
                    );
            },
            toolbar: [{ name: "create", text: "Add" }],
            columns: [
                { field: "DisplayName", title: "Display Name", width: "150px" },
                {
                    field: "CustomFlow",
                    title: "Custom Flow",
                    width: "200px",
                    editor: kendoGridUtilService.makeWorkflowTree("workflows", "Name", "Id"),
                    template: '<span ng-bind="dataItem.CustomFlow.Name"></span>'
                },
                {
                    field: "DatasetName",
                    title: "Workflow input table",
                    width: "150px"
                },
                {
                    field: "UserActions",
                    title: "User actions",
                    width: "70px",
                    template: "<input type='checkbox' #= UserActions ? checked='checked' : '' # ></input>",
                    editor: kendoGridUtilService.makeKendoCheckboxFromValue("UserActions"),
                },
                {
                    command: [
                      {
                          name: "edit",
                          template: "<a class='k-button k-button-icontext k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>",
                      },
                        {
                            name: "destroy",
                            template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                        }
                    ],
                    width: 24
                }],

            dataSource: new kendo.data.DataSource({
                data: $scope.configurationModel.FlowConfigurations,
                autoSync: false,
                transport: {
                    read: function (o) {
                        o.success($scope.configurationModel.FlowConfigurations);
                    },
                    create: function (o) {
                        var item = o.data;
                        item.Id = $scope.configurationModel.FlowConfigurations + 1;
                        item.Code = Guid.newGuid();
                        $scope.configurationModel.FlowConfigurations.unshift(item);
                        o.success(item);
                    },
                    update: function (o) {
                        o.success();
                    },
                    destroy: function (o) {
                        for (var index = 0; index < $scope.configurationModel.FlowConfigurations.length; index++) {
                            if ($scope.configurationModel.FlowConfigurations[index].Id == o.data.Id) {
                                $scope.configurationModel.FlowConfigurations.splice(index, 1);
                                break;
                            }
                        }

                        o.success();
                    },
                },
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            DisplayName: {
                                type: "string", validation: { required: { message: "Field is required"}  } },
                            CustomFlow: {
                                validation: {
                                    required: true,
                                    customflowvalidation: function (input) {

                                        if (input.is("[name='CustomFlow']")) {
                                            input.attr("data-customflowvalidation-msg", "Field is required");
                                            var spanFlowName = input.siblings('span');
                                            var spanFlowNameLabel = jQuery(spanFlowName).find('label');
                                            var spanFlowNameLabelClass = spanFlowNameLabel.attr("class");
                                            if (spanFlowNameLabelClass) {
                                                if (spanFlowNameLabelClass.indexOf("label-warning") > -1) {
                                                    return false;
                                                }
                                            }
                                        }

                                        return true;
                                    }
                                }
                            },
                            UserActions: { type: "boolean" },
                            DatasetName: {
                                type: "string",
                                validation: {
                                    required: { message: "Field is required"} }
                            }
                        }
                    }
                }
            })
        };

        $scope.getCrudMapperData = function () {
            return $scope.mapperGrid.dataSource.data();
        };

        $scope.validateDataSource = function () {
            if (!$scope.model.DataSourceType) {
                return false;
            }
            if ($scope.model.DataSourceType === 'Workflow') {
                if (!$scope.portletConfigurationForm.outputVariable) {
                    return false;
                }
                return $scope.model.Workflows.length > 0;
            }
            if ($scope.model.DataSourceType === 'Database') {
                if (!$scope.model.Queries[0].ConnectorName) {
                    return false;
                }
            }
            return true;
        };


        $scope.ok = function () {
            $scope.configurationModel.GroupHeaders = [];
            var headers = $scope.groupGrid.dataSource.data();
            for (var p = 0; p < headers.length; p++) {
                delete headers[p].Position;
                $scope.configurationModel.GroupHeaders.push(headers[p]);
                if (!headers[p].Code) {
                    headers[p].Code = Guid.newGuid();
                }
            }

            $scope.configurationModel.Mappers = [];
            var mappers = $scope.mapperGrid.dataSource.data();

            for (var p = 0; p < mappers.length; p++) {
                delete mappers[p].Position;
                $scope.configurationModel.Mappers.push(mappers[p]);
                if (!mappers[p].Code) {
                    mappers[p].Code = Guid.newGuid();
                }
            }

            $scope.flowOptions.dataSource.sync();
            recordViewConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel, data.operation);
            $uibModalInstance.close($scope.model);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        //recordview
        $scope.initialize = function () {
            $scope.createWorkflowTypes = [{
                    "type": "dsWithPagingAndFiltering",
                    "displayName": "Data Source with paging and filtering",
                }
            ];
        }();

    }])
