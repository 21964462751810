app.controller("DocumentViewerController",
    [
        "$scope", "$rootScope", "DocumentViewerService", "receiverService", "portletErrorService",
        "configurationService", "appConfig", "$sce", "sharedSessionService", "$controller", 
        function ($scope,
            $rootScope,
            DocumentViewerService,
            receiverService,
            portletErrorService,
            configurationService,
            appConfig,
            $sce,
            sharedSessionService, $controller) {

            $controller('BasePluginController', { $scope: $scope });

            $scope.$on("onMaximized",
                function (event, pcPortlets) {
                    if ($scope.mediaType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=100";
                        $scope.setPdfSizes();
                    }
                });

            $scope.$on("onMinimized",
                function (event, pcPortlets) {
                    if ($scope.mediaType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=auto";
                        $scope.setPdfSizes();
                    }
                });

            $scope.statusCode = "";
            $scope.hasStatus = false;
            $scope.isPdf = false;
            $scope.isImage = false;
            $scope.mediaType = "";

            $scope.initialize = function () {

                $scope.configurationModel = configurationService.getConfigurationModel($scope.portlet.Properties);
                $scope.controllingModel = configurationService.getControllingModel();
                $scope.controllingModel.DisplayLoadingBar = false;
                $scope.DocumentViewerViewModel = {};
            };

            $scope.refresh = function () {
                $scope.getDocument();
            };
            $scope.$on("updateDocument",
                function (event, value) {
                    if (receiverService.canReceive($scope.portlet, "updateDocument", value)) {
                        $scope.DocumentViewerViewModel.DocumentId = value.Id;
                        $scope.getDocument();
                    }
                });

            $scope.$on("onRefresh",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.refresh();
                    }
                });

            $scope.getDocument = function () {

                if ($scope.DocumentViewerViewModel.DocumentId) {

                    $scope.controllingModel.DisplayLoadingBar = true;
                    $scope.fileUrl = null;
                    $scope.image = null;
                    $scope.hasStatus = false;
                    $scope.isPdf = false;
                    $scope.isImage = false;


                    var dataToSend = {};
                    dataToSend.Id = $scope.DocumentViewerViewModel.DocumentId;

                    DocumentViewerService.getDocument(dataToSend, {}, $scope.portlet.Id)
                        .then(function(response) {

                            var data = response.data;
                            var headers = response.headers;

                            if (response.statusText !== "OK") {
                                $scope.statusCode = response.statusText;
                                $scope.hasStatus = true;
                            };

                            if (data.byteLength > 0) {

                                $scope.mediaType = headers("content-type");

                                var file = new Blob([data], { type: $scope.mediaType });

                                if ($scope.mediaType === "application/pdf") {

                                    $scope.isPdf = true;

                                    $scope.path = $.path +
                                        "/" +
                                        $.contentDir +
                                        "/pdfViewer/web/viewer.html?file=" +
                                        $sce.trustAsResourceUrl(URL.createObjectURL(file)) +
                                        "&lang=" +
                                        sharedSessionService.getLanguageCode();

                                    $scope.fileUrl = $scope.path;

                                    $scope.setPdfSizes();

                                } else if ($scope.mediaType.startsWith("image")) {

                                    var binary = "";
                                    var bytes = new Uint8Array(data);
                                    var len = bytes.byteLength;
                                    for (var i = 0; i < len; i++) {
                                        binary += String.fromCharCode(bytes[i]);
                                    }
                                    $scope.image = window.btoa(binary);
                                    $scope.isImage = true;
                                } else {
                                    saveAs(file);
                                }
                            }
                        }).catch(angular.noop).finally(
                            function() {
                                $scope.controllingModel.DisplayLoadingBar = false;
                            }
                        );
                }
            };

            $scope.setPdfSizes = function() {
                $scope.pdfSizes = {
                    "width": "100%",
                    "height": $("#doc" + $scope.portlet.Id).closest(".portletContainerBody")
                        .height() -
                        $("#doc" + $scope.portlet.Id).closest(".portletContainerBody").find("ul")
                        .height() -
                        10 +
                        "px"
                }
            };

            $scope.initialize();
        }
    ]);

