app.service('GenericTreeService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getNodes = function (configurationModel, portletId) {
            return basePluginService.backend.post("genericTree", configurationModel, portletId);
        };

        return service;

    }]);