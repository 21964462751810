app.controller('Html_CreateOrEditPortletController', ['$scope', '$rootScope', '$uibModalInstance', 'createOrEditModalService', 'htmlConfigurationService', 'data', 'connectorService', 'suggestionService', 'editorService', function ($scope, $rootScope, $uibModalInstance, createOrEditModalService, htmlConfigurationService, data, connectorService, suggestionService, editorService) {

    $scope.model = createOrEditModalService.getModel("Html", data);
    $scope.querySuggestions = suggestionService.getSuggestions();
    $scope.connectorService = connectorService;
    $scope.configurationModel = htmlConfigurationService.getConfigurationModel($scope.model.Properties);
    $scope.panelBarOptions = {
        expandMode: "single",
        select: $scope.expandCollapse
    };


    if ($scope.model.DataSourceType === null) {
        $scope.model.DataSourceType = 'None';
    }

    $scope.validateDataSource = function () {
        if ($scope.model.DataSourceType === 'Workflow') {

            if (!$scope.portletConfigurationForm.outputVariable) {
                return false;
            }

            if ($scope.model.Workflows.length > 0)
                return true;
            else
                return false;
        } else
            return true;
    };

    $scope.ok = function () {

        htmlConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    //html

    $scope.fieldOptions = {
        editable: true,
        scrollable: false,
        toolbar: [
            {
                name: "create", text: "Add"
            }
        ],
        columns: [
            { field: "databaseColumn", title: "Datasource column", width: "400px" },
            { field: "type", title: "Type", width: "300px", editor: editorService.createBasicEditorDropDownList },
            { field: "length", title: "Length", width: "100px" },
            {
                command: [
                    {
                        name: "destroy",
                        template:
                            "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                    }
                ],
                width: 6
            }
        ],

        dataSource: new kendo.data.DataSource({
            autoSync: true,
            transport: {
                read: function (o) {
                    if (!$scope.configurationModel.HtmlModel.Fields) {
                        $scope.configurationModel.HtmlModel.Fields = [];
                    }
                    o.success($scope.configurationModel.HtmlModel.Fields);
                },
                create: function (o) {
                    var item = o.data;
                    item.Position = $scope.configurationModel.HtmlModel.Fields.length + 1;
                    $scope.configurationModel.HtmlModel.Fields.unshift(item);
                    o.success(item);
                },
                update: function (o) {
                    o.success();
                },
                destroy: function (o) {

                    for (var index = 0; index < $scope.configurationModel.HtmlModel.Fields.length; index++) {
                        if ($scope.configurationModel.HtmlModel.Fields[index].Position === o.data.Position) {
                            $scope.configurationModel.HtmlModel.Fields.splice(index, 1);
                            break;
                        }
                    }

                    o.success();
                }
            },
            schema: {
                model: {
                    id: "Position",
                    fields: {
                        databaseColumn: { type: "string" },
                        type: { defaultValue: "Text" },
                        length: { type: "number" }
                    }
                }
            }
        })
    };

    editorService.createColumnNameComboBox($scope.fieldOptions.columns[0], $scope.model);

    $scope.initialize = function () {
        $scope.createWorkflowTypes = [{
                "type": "dsWithPagingAndFiltering",
                "displayName": "Data Source with paging and filtering",
            }, {
                "type": "datasource",
                "displayName": "Data Source"
            }
        ];
    }();

}])