app.controller('GenericTree_CreateOrEditPortletController', ['$scope', '$rootScope', '$uibModalInstance', 'createOrEditModalService', 'genericTreeConfigurationService', 'data', 'propertyService', 'suggestionService', 'connectorService', 'editorService', function ($scope, $rootScope, $uibModalInstance, createOrEditModalService, genericTreeConfigurationService, data, propertyService, suggestionService, connectorService, editorService) {

    $scope.panelBarOptions = {
        expandMode: "single",
        select: $scope.expandCollapse
    };

    $scope.connectorService = connectorService;
    $scope.editorService = editorService;
    $scope.model = createOrEditModalService.getModel("GenericTree", data);
    $scope.configurationModel = genericTreeConfigurationService.getConfigurationModel($scope.model.Properties);
    $scope.querySuggestions = suggestionService.getSuggestions();

    $scope.validateDataSource = function () {

        if (!$scope.model.DataSourceType) {
            return false;
        }
        if ($scope.model.DataSourceType === 'Workflow') {
            if (!$scope.portletConfigurationForm.outputVariable) {
                return false;
            }
            return $scope.model.Workflows.length > 0;
        }
        if ($scope.model.DataSourceType === 'Database') {
            if (!$scope.model.Queries[0].ConnectorName) {
                return false;
            }
        }
        return true;
    };

    $scope.ok = function () {
        genericTreeConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    //Generic tree
    $scope.initialize = function() {
        $scope.createWorkflowTypes = [{
                "type": "dsWithPagingAndFiltering",
                "displayName": "Data Source with paging and filtering",
            }
        ];
    }();

}])