app.controller('FilterController', [
    '$scope', '$rootScope', 'propertyService', 'receiverService', 'editorService', 'portletErrorService', 'filterConfigurationService', 'bootstrapColumnClassService', 'translationsService', 'translationFlowClientService', 'appConfig', '$controller', 'cascadeLovService',
function ($scope, $rootScope, propertyService, receiverService, editorService, portletErrorService, filterConfigurationService, bootstrapColumnClassService, translationsService, translationFlowClientService, appConfig, $controller, cascadeLovService) {

        $controller('BasePluginController', { $scope: $scope });

        $scope.editorService = editorService;
        $scope.translationFlowClientService = translationFlowClientService;

        $scope.$watch('groups',
				function () {
				    cascadeLovService.setCurrentCascadeObject($scope.portlet.Id, $scope.flattenSearchView());
				},
				true);

        $scope.search = function() {

            //Extract all the columns that has valus and then filter them out
            var searchData = [];
            var rowData = {};

            angular.forEach($scope.groups, function (group) {
                angular.forEach(group.Rows, function (row) {
                    angular.forEach(row, function (column) {
                            var value;
                            var editor = column.Editor;
                            var operator = 'eq';

                            if (column.Operator.Value) {
                                operator = column.Operator.Value;
                            }
                        if (column.Value && column.Value !== "") {
                            if (editor == "Date" || editor == "Time" || editor == "DateTime") {


                                var pattern = kendo.culture().calendar.patterns.d;

                                if (editor == "Time") {
                                    pattern = kendo.culture().calendar.patterns.T;
                                } else if (editor == "DateTime") {
                                    pattern = kendo.culture().calendar.patterns.G;
                                }

                                value = kendo.toString(kendo.parseDate(column.Value, pattern),
                                    appConfig.DEFAULT_DATE_FORMAT);

                                searchData.push({
                                    Key: column.Name,
                                    Value: value,
                                    Editor: editor,
                                    Operator: operator,
                                    Logic: ''
                                });

                            } else if (editor == "Checkbox") {

                                value = column.Value;

                                searchData.push({
                                    Key: column.Name,
                                    Value: value,
                                    Editor: 'Checkbox',
                                    Operator: 'eq',
                                    Logic: ''
                                });

                            } else {

                                value = column.Value;

                                searchData.push({
                                    Key: column.Name,
                                    Value: value,
                                    Editor: editor,
                                    Operator: operator,
                                    Logic: ''
                                });

                            }
                        }

                        if (!column.Value) {
                                rowData[column.Name] = null;
                            } else {
                                rowData[column.Name] = value;
                            }

                    });
                });
            });

            $rootScope.$broadcast('onFilter', { SenderId: $scope.portlet.Id, Data: searchData });
            $rootScope.$broadcast('onRowSelected', { SenderId: $scope.portlet.Id, Data: rowData });

        };

        $scope.flattenSearchView = function () {

            var rowData = {};

            angular.forEach($scope.groups, function (group) {
                angular.forEach(group.Rows, function (row) {
                    angular.forEach(row, function (column) {
                        var value;
                        var editor = column.Editor;

                        if (column.Value && column.Value !== "") {
                            if (editor == "Date" || editor == "Time" || editor == "DateTime") {


                                var pattern = kendo.culture().calendar.patterns.d;

                                if (editor == "Time") {
                                    pattern = kendo.culture().calendar.patterns.T;
                                } else if (editor == "DateTime") {
                                    pattern = kendo.culture().calendar.patterns.G;
                                }

                                value = kendo.toString(kendo.parseDate(column.Value, pattern),
                                    appConfig.DEFAULT_DATE_FORMAT);

                            }
                            else {
                                value = column.Value;
                            }
                        }

                        if (value) {
                            rowData[column.Name] = value;
                        }

                    });
                });
            });

            return rowData;

        };

        $scope.createGroups = function() {

            angular.forEach($scope.configurationModel.GroupHeaders, function(groupHeader) {
                var gr = {
                    GroupName: translationsService.get(groupHeader.Code + '-GroupName', groupHeader.GroupName),
                    IsVisible: groupHeader.IsVisible,
                    Rows: $scope.createRows(groupHeader.GroupName)

                };
                $scope.groups.push(gr);
            });

            $scope.createUnclassifiedColumnsGroup();
        };

        $scope.createUnclassifiedColumnsGroup = function () {
            var gr = {
                GroupName: "",
                IsVisible: false,
                Rows: $scope.createRows("")
            };
            if (gr.Rows.length > 0) {
                $scope.groups.push(gr);
            }
        };

        $scope.createRows = function(groupName) {

            var rows = [];
            var columns = [];

            angular.forEach($scope.configurationModel.Mappers, function(mapper) {
                if (mapper.Group == groupName) {
                    var element = {
                        Name: mapper.DatabaseColumn,
                        DisplayName: translationsService.get(mapper.Code + '-DisplayName', mapper.DisplayName),
                        IsMultiline: mapper.IsMultiline,
                        ColumnSize: bootstrapColumnClassService.getClassSize($scope.configurationModel.NumberOfColumns, mapper.ColumnSize),
                        IsEditable: true,
                        Editor: mapper.Editor,
                        Operator: mapper.Operator,
                        Length: mapper.Length,
                        Lov: mapper.Lov
                    };

                    if (mapper.Operator)
                        element.Operator = mapper.Operator;
                    else {
                        element.Operator = 'eq';
                    }

                    columns.push(element);
                    if (columns.length == $scope.configurationModel.NumberOfColumns) {
                        rows.push(columns);
                        columns = [];
                    }
                }
            });

            if (columns.length > 0) {
                rows.push(columns);
            }

            return rows;
        };

        $scope.initialize = function() {

            $scope.groups = [];

            $scope.configurationModel = filterConfigurationService.getConfigurationModel($scope.portlet.Properties);

            $scope.controllingModel = filterConfigurationService.getControllingModel();

            var columnSizes = [];

            angular.forEach($scope.configurationModel.Mappers, function(mapper) {
                columnSizes.push(bootstrapColumnClassService.getClassSize($scope.configurationModel.NumberOfColumns, mapper.ColumnSize));
            });

            $scope.searchButtonClassSize = bootstrapColumnClassService.getAvailableClassSize(columnSizes);

            $scope.createGroups();
        };

        $scope.initialize();
    }
]);
