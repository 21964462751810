app.controller('FlowMenu_CreateOrEditPortletController',
[
    '$scope', '$uibModalInstance', 'createOrEditModalService', 'flowMenuConfigurationService', 'data', 'Guid', '$q', 'appConfig', 'flowMenuService',
    function ($scope, $uibModalInstance, createOrEditModalService, flowMenuConfigurationService, data, Guid, $q, appConfig, flowMenuService) {

        $scope.model = createOrEditModalService.getModel("FlowMenu", data);
        $scope.configurationModel = flowMenuConfigurationService.getConfigurationModel($scope.model.Properties);

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        /**
         * this should be in configuration service
         */
        var init = function () {

            flowMenuService.getFlowMenus()
                .then(function(result) {

                    var flowMenuExists = false;
                    angular.forEach(result.data, function (value) {
                        if (value.Id === $scope.configurationModel.flowMenuId) {
                            flowMenuExists = true;
                        }
                    });

                    if (!flowMenuExists) {
                        $scope.configurationModel.flowMenuId = "";
                    }

                    $scope.flowMenus = {
                        dataTextField: "Title",
                        dataValueField: "Id",
                        dataSource: result.data
                    };
                }).catch(angular.noop);
        }();

        $scope.ok = function() {      
            flowMenuConfigurationService.saveProperties($scope.model.Properties,
                $scope.configurationModel,
                data.operation);
            $uibModalInstance.close($scope.model);   
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');   
        };
    }
]);