app.service('flowMenuService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);
        var service = {};

        service.getFlowMenus = function () {
            return basePluginService.backend.get("portalMenus");
        };

        service.getMenus = function (flowMenuId) {
            return basePluginService.backend.get("menus/" + flowMenuId);
        };
        
        return service;

    }]);