app.service('DocManTreeService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getTreeData = function (nodeId, portletId) {

            var url ='node/';
            if (nodeId)
                url = url + nodeId;
         
            return basePluginService.backend.get(url, portletId);
        };  

        return service;

    }]);