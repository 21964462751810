app.factory('recordViewConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        configurationModel.Mappers = $.parseJSON(propertyService.getPropertyValue(properties, "mappers", "[]"));

        configurationModel.GroupHeaders = $.parseJSON(propertyService.getPropertyValue(properties, "groupHeaders", "[]"));

        configurationModel.NumberOfColumns = parseInt(propertyService.getPropertyValue(properties, "numberOfColumns", "2"));
        configurationModel.SaveWorkFlow = propertyService.getPropertyValue(properties, "SaveWorkFlow", "");
        configurationModel.ReadOnly = propertyService.getBoolPropertyValue(properties, "readOnly");

        configurationModel.FlowConfigurations = $.parseJSON(propertyService.getPropertyValue(properties, "flowConfigurations", "[]"));
        configurationModel.SaveWorkFlow = $.parseJSON(propertyService.getPropertyValue(properties, "SaveWorkFlow", "{}"));

        configurationService.assignIds(configurationModel.Mappers);

        configurationService.assignIds(configurationModel.FlowConfigurations);

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel, operation) {
        configurationService.saveProperties(properties, configurationModel);

        if (configurationModel.Mappers && operation == "copy") {
            configurationService.assignCodes(configurationModel.Mappers);
        }

        if (configurationModel.Mappers) {
            propertyService.setProperty(properties, "mappers", JSON.stringify(configurationModel.Mappers));
        }

        if (configurationModel.GroupHeaders && operation == "copy") {
            configurationService.assignCodes(configurationModel.GroupHeaders);
        }

        if (configurationModel.GroupHeaders) {
            propertyService.setProperty(properties, "groupHeaders", JSON.stringify(configurationModel.GroupHeaders));
        }

        if (configurationModel.FlowConfigurations && operation == "copy") {
            configurationService.assignCodes(configurationModel.FlowConfigurations);
        }

        if (configurationModel.FlowConfigurations) {
            propertyService.setProperty(properties, "flowConfigurations", JSON.stringify(configurationModel.FlowConfigurations));
        }

        if (configurationModel.SaveWorkFlow) {
            propertyService.setProperty(properties, "SaveWorkFlow", JSON.stringify(configurationModel.SaveWorkFlow));
        }

        propertyService.setBoolProperty(properties, "readOnly", configurationModel.ReadOnly);

        propertyService.setProperty(properties, "numberOfColumns", configurationModel.NumberOfColumns.toString());
    }

    return service;
}]);