app.service('dataVisualizerCardNavigatorService', ['basePluginService',  'appConfig', '$q',
    function (basePluginService, appConfig, $q) {

        angular.extend(this, basePluginService);        

        var service = {};

        service.getData = function (portlets, portletId) {
            return basePluginService.backend.post('portlets', portlets, portletId);
        };


        service.getDvcList = function (portletId) {
            return basePluginService.backend.get('dvcList', portletId);
        };




        return service;


}]);