app.controller('ReassignTaskController',
    ['$scope','$uibModalInstance','translationFlowClientService','FlowApplicationTasksService','params',function($scope,$uibModalInstance,translationFlowClientService,FlowApplicationTasksService,params){


            $scope.ok = function () {

                var reassignTaskModel = {
                    HandoverIdentity:params.HandoverIdentity,
                    NewPeggedUser:$scope.NewPeggedUser
                };

                FlowApplicationTasksService.reassignTask(reassignTaskModel, params.PortletId).then(function(result) {
                    if (result.data) {
                        $uibModalInstance.close($scope.model);
                    } else {
                        $scope.failure = true;
                    }
                }).catch(angular.noop);
            };

            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            $scope.usersToReassign = function () {
                var usersDataSource = {
                    transport: {
                        read: function (opts) {
                            FlowApplicationTasksService.getLogins(params.PortletId).then(function(result) {
                                opts.success(result.data);
                            }).catch(angular.noop);
                        }
                    }
                };

                return {
                    filter: "contains",
                    dataSource: usersDataSource,
                    dataTextField: "FullName",
                    dataValueField: "Login"
                };
            } 

            $scope.initialize = function() {

                $scope.translationFlowClientService = translationFlowClientService;
            }

            $scope.initialize();
        }
    ]);
