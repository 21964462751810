app.controller('DataVisualizerCard_CreateOrEditPortletController',
[
    '$scope', '$uibModalInstance', '$rootScope', 'createOrEditModalService', 'dataVisualizerCardConfigurationService',
    'data', 'suggestionService', 'editorService', 'connectorService', 'Guid', 'kendoGridUtilService', 'dataEvalService','pluginConfigurationService',
    function($scope,
        $uibModalInstance,
        $rootScope,
        createOrEditModalService,
        dataVisualizerCardConfigurationService,
        data,
        suggestionService,
        editorService,
        connectorService,
        Guid,
        kendoGridUtilService,
        dataEvalService,
        pluginConfigurationService) {

        $scope.connectorService = connectorService;
        $scope.querySuggestions = suggestionService.getSuggestions();
        $scope.editorService = editorService;

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.model = createOrEditModalService.getModel("DataVisualizerCard", data);

        $scope.configurationModel = dataVisualizerCardConfigurationService.getConfigurationModel($scope.model.Properties);

        $scope.draggableHint = function (e) {
            return $('<div class="k-grid k-widget"><table><tbody><tr>' + $(e).parent().parent().html() + '</tr></tbody></table></div>');
        }

        $scope.createColorGrid = function(name, colorRuleDef) {
            var toReturn = {
                editable: {
                    createAt: "bottom"
                },
                scrollable: false,
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    {
                        field: "Colour",
                        title: "Colour",
                        width: "50px",
                        editor: kendoGridUtilService.getColorPicker(),
                        template: kendoGridUtilService.getColorTemplate()
                    },
                    { field: "FirstValue", title: "First Value", width: "475px" },
                    {
                        field: "Func",
                        title: "Func",
                        width: "100px",
                        editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
                        template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
                    },
                    { field: "SecondValue", title: "Second Value", width: "475px" },
                    {
                        command: [
                            {
                                name: "up",
                                template: "<a class='k-grid-up k-button k-button-icontext gridButton'><span class='k-icon k-i-arrow-up'></span></a>",
                                click: function(e) {
                                    $scope.changePosition(e, "up", name, colorRuleDef.colours);
                                }
                            },
                            {
                                name: "down",
                                template: "<a class='k-grid-down k-button k-button-icontext gridButton'><span class='k-icon k-i-arrow-down'></span></a>",
                                click: function(e) {
                                    $scope.changePosition(e, "down", name, colorRuleDef.colours);
                                }
                            },
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ]
                    }
                ],
                dataSource: new kendo.data.DataSource({
                    data: colorRuleDef.colours,
                    autoSync: true,
                    transport: {
                        read: function(o) {
                            o.success(colorRuleDef.colours);
                        },
                        create: function(o) {
                            var item = o.data;
                            item.Position = colorRuleDef.colours.length + 1;
                            colorRuleDef.colours.push(item);
                            o.success(item);
                        },
                        update: function(o) {
                            o.success();
                        },
                        destroy: function(o) {
                            _.remove(colorRuleDef.colours,
                                function(i) {
                                    return i.Position === o.data.Position;
                                });

                            pluginConfigurationService.assignPositions(colorRuleDef.colours);
                            o.success();
                        }
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                FirstValue: { type: "string" },
                                Func: { type: "string", defaultValue: 1 },
                                SecondValue: { type: "string" },
                                Colour: {
                                    type: "string",
                                    defaultValue: "#000000",
                                    validation: {
                                        required: { message: "Field is required"}  }
                                }

                            }
                        }
                    }
                })
            };

            return toReturn;
        };

        $scope.createScaleColorGrid = function (name, colorRuleDef) {
            var toReturn = {
                editable: {
                    createAt: "bottom"
                },
                scrollable: false,
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    {
                        field: "Colour",
                        title: "Colour",
                        width: "50px",
                        editor: kendoGridUtilService.getColorPicker(),
                        template: kendoGridUtilService.getColorTemplate()
                    },
                    {
                        field: "FirstValue",
                        title: "From",
                        width: "525px"
                    },
                    {
                        field: "SecondValue",
                        title: "To",
                        width: "525px"
                    },
                    {
                        command: [
                            {
                                name: "up",
                                template: "<a class='k-grid-up k-button k-button-icontext gridButton'><span class='k-icon k-i-arrow-up'></span></a>",
                                click: function(e) {
                                    $scope.changePosition(e, "up", name, colorRuleDef.colours);
                                }
                            },
                            {
                                name: "down",
                                template: "<a class='k-grid-down k-button k-button-icontext gridButton'><span class='k-icon k-i-arrow-down'></span></a>",
                                click: function(e) {
                                    $scope.changePosition(e, "down", name, colorRuleDef.colours);
                                }
                            },
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ]
                    }
                ],
                dataSource: new kendo.data.DataSource({
                    data: colorRuleDef.colours,
                    autoSync: true,
                    transport: {
                        read: function (o) {
                            o.success(colorRuleDef.colours);
                        },
                        create: function (o) {
                            var item = o.data;
                            item.Position = colorRuleDef.colours.length + 1;
                            colorRuleDef.colours.push(item);
                            pluginConfigurationService.assignPositions(colorRuleDef.colours);
                            o.success(item);
                        },
                        update: function (o) {
                            o.success();
                        },
                        destroy: function (o) {
                            _.remove(colorRuleDef.colours,
                                function (i) {
                                    return i.Position === o.data.Position;
                                });

                            pluginConfigurationService.assignPositions(colorRuleDef.colours);
                            o.success();
                        },
                    },
                    schema: {
                        model: {
                            id: "Position",
                            fields: {
                                FirstValue: {
                                    type: "int",
                                    validation: {
                                        required: true,
                                        firstvaluevalidation: function(input) {
                                            var firstVal = parseFloat(input.val());
                                            var secondVal = parseFloat(input.context.nextSibling.textContent);
                                            input.attr("data-firstvaluevalidation-msg", "First value have to be smaller then second value.");

                                            if (!isNaN(firstVal) && !isNaN(secondVal)) {
                                                return firstVal < secondVal;
                                            }

                                            return true;
                                        }
                                    }
                                },
                                SecondValue: {
                                    type: "int",
                                    validation: {
                                        required: true,
                                        secondValueValidation: function (input) {
                                            var firstVal = parseFloat(input.context.previousSibling.textContent);
                                            var secondVal = parseFloat(input.val());
                                            input.attr("data-firstvaluevalidation-msg", "Second value value have to be bigger then first value.");

                                            if (!isNaN(secondVal) && !isNaN(firstVal)) {
                                                return firstVal < secondVal;
                                            }

                                            return true;
                                        }
                                    }
                                },
                                Colour: {
                                    type: "string",
                                    defaultValue: "#000000",
                                    validation: {
                                        required: { message: "Field is required"}  }
                                }

                            }
                        }
                    }
                })
            };

            return toReturn;
        };

        $scope.changePosition = function (e, direction, gridName, model ) {
            var loverCaseDirection = typeof direction == 'string' ? direction.toLowerCase() : null;
            if (loverCaseDirection !== 'up' && direction !== 'down') throw "wrong direction: " + direction;
            if (model == null) throw "model is empty"; 

            var grid = $scope[gridName];
            if (grid == null) throw "grid not found: " + gridName;

            var gridItem = grid.dataItem($(e.currentTarget).closest("tr"));
            var allGridItems = grid.dataSource.data();

            var itemToMove = _.find(model, function(i) { return i.Position === gridItem.Position });
            var index = _.findIndex(model, function (i) { return i.Position === itemToMove.Position });
            model.splice(index, 1);

            var newIndex = index;
            if (direction === "up" && index > 0) newIndex = index - 1;
            else if (direction === "down" && index < allGridItems.length - 1) newIndex = index + 1;

            model.splice(newIndex, 0, itemToMove);
            pluginConfigurationService.assignPositions(model);
            grid.dataSource.read();
        }

        $scope.backgroundColorGrid = $scope.createColorGrid("backgroundColorGrid",$scope.configurationModel.ColorBackground);
        $scope.textColorGrid = $scope.createColorGrid("textColorGrid",$scope.configurationModel.ColorText);
        $scope.speedometerLineBackgroundColorGrid = $scope.createColorGrid("speedometerLineBackgroundColorGrid",$scope.configurationModel.SpeedometerLineBackground);
        $scope.speedometerLineBackgroundScaleColorGrid =
            $scope.createScaleColorGrid("speedometerLineBackgroundScaleColorGrid", $scope.configurationModel.SpeedometerLineBackgroundScale);

        $scope.speedometerLineForegroundColorGrid = $scope.createColorGrid("speedometerLineForegroundColorGrid",$scope.configurationModel.SpeedometerLineForeground);
        $scope.speedometerBackgroundColorGrid = $scope.createColorGrid("speedometerBackgroundColorGrid",$scope.configurationModel.SpeedometerBackground);
        $scope.speedometerTextColorGrid = $scope.createColorGrid("speedometerTextColorGrid", $scope.configurationModel.SpeedometerTextColor);       

        $scope.querySuggestions = suggestionService.getSuggestions();

        $scope.validateDataSource = function () {

            if (!$scope.model.DataSourceType) {
                return false;
            }
            if ($scope.model.DataSourceType === 'Workflow') {
                if (!$scope.portletConfigurationForm.outputVariable) {
                    return false;
                }
                return $scope.model.Workflows.length > 0;
            }
            if ($scope.model.DataSourceType === 'Database') {
                if (!$scope.model.Queries[0].ConnectorName) {
                    return false;
                }
            }
            return true;
        };

        $scope.ok = function() {
            dataVisualizerCardConfigurationService
                .saveProperties($scope.model.Properties, $scope.configurationModel, data.operation);
            $uibModalInstance.close($scope.model);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        //Data Visualizer Card
        $scope.initialize = function () {
            $scope.createWorkflowTypes = [{
                    "type": "dsWithPagingAndFiltering",
                    "displayName": "Data Source with paging and filtering",
                }
            ];
        }();

    }
])
