app.controller('FlowApplicationOverviewController', [
    '$scope',
    'FlowApplicationOverviewService',
    'FlowApplicationOverviewConfigurationService',
    'translationsService',
    'sharedSessionService',
    '$controller',
    function ($scope,
    FlowApplicationOverviewService,
    FlowApplicationOverviewConfigurationService,
    translationsService,
    sharedSessionService,
    $controller
 ) {

        $controller('BaseTableController', { $scope: $scope });
        $controller('TableWorkflowExecutionController', { $scope: $scope });
        
        $scope.selectionModel = {};
        $scope.selectionModel.selectedRows = [];
        $scope.selectionModel.emptySelection = true;
        $scope.width = 0;
        $scope.shouldApplyWidth = false;
        
        
        $scope.createToolbar = function () {
            var toolbar = [];

            angular.forEach($scope.configurationModel.WorkFlowModel.FlowConfigurations,
              function (flowConfig) {

                  if (flowConfig.CustomFlow) {

                      var datasetName = null;

                      if (flowConfig.DatasetName)
                          datasetName = flowConfig.DatasetName;

                      this.push({
                          Id: flowConfig.CustomFlow.Id,
                          text: flowConfig.DisplayName,
                          template: '<button ng-click="runCustomFlow(\'' +
                              flowConfig.CustomFlow.Id +
                              "', " +
                              flowConfig.UserActions +
                              ",'" +
                              datasetName + "'" +
                              ')" class="k-button k-button-icontext k-grid-export" ng-disabled="customFlowDisabled(' +
                              flowConfig.Id +
                              ')">' +
                              translationsService.get(flowConfig.Code + "-DisplayName", flowConfig.DisplayName) +
                              "</button>"
                      });
                  }
              },
              toolbar);

            toolbar.push({
                Id: 'emptyButton',
                template: '<button hidden="true"> Btn</button>'
            });

            return toolbar;
        };
        
        $scope.search = function () {
            $scope.gridOptions.dataSource.read();
        };

        $scope.getTableOptions = function (result) {

            var toolBarContent = $scope.createToolbar();

            var options = {
                toolbar: toolBarContent.length ? toolBarContent : undefined,
                editable: false,
                groupable: $scope.configurationModel.Groupable,
                navigatable: true,
                pageable: {
                    refresh: true
                },
                columnMenu: true,
                dataBinding: $scope.onDataBinding,
                dataBound: $scope.onDataBound,
                columnResize: $scope.onColumnResize,
                columnReorder: $scope.onColumnReorder,
                change: $scope.onChange,
                selectable: $scope.configurationModel.MultiSelect ? "multiple" : true,
                resizable: $scope.configurationModel.Resizable,
                reorderable: $scope.configurationModel.Reorderable,
                columnShow: $scope.onColumnShow,
                columnHide: $scope.onColumnHide,
                sortable: true,
                filterable: false,
                dataSource: new kendo.data.DataSource({
                    pageSize: $scope.PaginationModel.PageSize,
                    batch: true,

                    transport: {
                        read: function (options) {

                            if ($scope.searchText)
                                $scope.PaginationModel.StringFieldsFilter = $scope.searchText;
                            else
                                $scope.PaginationModel.StringFieldsFilter = undefined;

                            $scope.PaginationModel.PageNumber = options.data.page;

                            $scope.setPaginationSort($scope.PaginationModel,
                                options,
                                $scope.configurationModel);

                            if (options.data.group && options.data.group.length === 2) {
                                options.success([]);
                            } else {
                                $scope.setPaginationGroup($scope.PaginationModel, options);

                                FlowApplicationOverviewService.getApplicationInstances($scope.PaginationModel, $scope.portlet.Id)
                                    .then(function (result) {
                                        options.success(result.data);
                                    }).catch(function (error) {
                                        options.error(error);
                                    })
                                    .finally(function () {
                                        $scope.controllingModel.DisplayLoadingBar = false;
                                    }
                                    );
                            }
                        },
                    },
                    schema: {
                        data: "Rows",
                        total: "Total",
                        groups: "Groups",
                        model: {
                            id: "id",
                            fields: $scope.createFields(result.data.Headers)
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    serverGrouping: true
                }),
                columns: $scope.createColumns(result.data.Headers)
            };

            if ($scope.configurationModel.Groupable && $scope.PaginationModel.GroupBy) {
                options.dataSource.group([
                    {
                        aggregates: [],
                        dir: "asc",
                        field: $scope.PaginationModel.GroupBy
                    }
                ]);
            }

            return options;
        };

        $scope.getData = function () {

            FlowApplicationOverviewService.getApplicationHeader($scope.PaginationModel, $scope.portlet.Id)
                .then(function (result) {
                    $scope.controllingModel.DisplayLoadingBar = false;
                    $scope.headerResult = result;

                    if (result.data.Headers.length > 0) {
                        $scope.gridOptions = $scope.getTableOptions($scope.headerResult);
                    }
                }).catch(angular.noop).finally(
                    function () {
                        $scope.controllingModel.DisplayLoadingBar = false;
                    }
                );
        }
        
        $scope.initialize = function (rowFilter) {

            $scope.FlowApplicationOverviewService = FlowApplicationOverviewService;
            $scope.configurationModel =
                FlowApplicationOverviewConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = FlowApplicationOverviewConfigurationService.getControllingModel();

            $scope.enumerableFlowConfigs = $scope.configurationModel.WorkFlowModel.FlowConfigurations;
            $scope.orderedColorRulesBackground = _.sortBy($scope.configurationModel.colorRulesBackground, [function (rule) { return rule.Position; }]);

            $scope.PaginationModel = {};
            $scope.PaginationModel.CurrentUICulture = kendo.culture().name;
            $scope.PaginationModel.PortletId = $scope.portlet.Id;
            $scope.PaginationModel.GroupBy = $scope.configurationModel.GroupingModel.GroupBy;
            $scope.PaginationModel.Search = [];
            $scope.PaginationModel.PageSize = $scope.configurationModel.PageSize;
            $scope.PaginationModel.IgnoreExecutionCheck = $scope.configurationModel.IgnoreExecutionCheck;
            $scope.PaginationModel.ApplicationIdentifier = $scope.configurationModel.Application.Id;

            if (rowFilter) {
                $scope.PaginationModel.RowFilter = rowFilter;
            }

            $scope.getData();
        }

        $scope.initialize();
    }
])