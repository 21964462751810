app.controller('DocumentViewer_CreateOrEditPortletController', ['$scope', '$uibModalInstance', 'propertyService', 'createOrEditModalService', 'configurationService', 'data', function ($scope, $uibModalInstance, propertyService, createOrEditModalService, configurationService, data) {

    $scope.model = createOrEditModalService.getModel("DocumentViewer", data);
    $scope.configurationModel = configurationService.getConfigurationModel($scope.model.Properties);
    
    $scope.panelBarOptions = {
        expandMode: "single",
        select: $scope.expandCollapse
    };

    $scope.ok = function () {
        configurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model);
        //kendo.destroy($('body > div:gt(0)'));
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
        //kendo.destroy($('body > div:gt(0)'));
    };
}])