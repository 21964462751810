﻿app.controller("SchedulerController",
    [
        "$scope", "$rootScope", "$controller", "configurationService", "SchedulerService", "$timeout",
        "pluginConfigurationService", "translationFlowClientService",
        "dataEvalService", "translationsService", "editorService", "$compile", "imageService", "receiverService",
        "operationAndInputFlowService", "portletErrorService", "$q",
        function($scope,
            $rootScope,
            $controller,
            configurationService,
            SchedulerService,
            $timeout,
            pluginConfigurationService,
            translationFlowClientService,
            dataEvalService,
            translationsService,
            editorService,
            $compile,
            imageService,
            receiverService,
            operationAndInputFlowService,
            portletErrorService,
            $q) {
            $controller("BasePluginController", { $scope: $scope });
            $controller("OperationAndInputExecutionController", { $scope: $scope });
            $scope.configurationModel =
                pluginConfigurationService.getConfigurationModel($scope.portlet.Configuration, "Scheduler");

            $scope.controllingModel = configurationService.getControllingModel();
            $scope.editorService = editorService;
            $scope.selectedItem = null;

            $scope.daysOffset = 2;
            $scope.prevOrNextClicked = false;

            var customTimeline = kendo.ui.TimelineView.extend({
                options: {
                    name: "customTimeline",
                    title: "Custom Timeline",
                    selectedDateFormat: "{0:D} - {1:D}",
                    selectedShortDateFormat: "{0:d} - {1:d}",
                    columnWidth: $scope.configurationModel.CustomTimeline.ColumnWidth,
                    numberOfDays: $scope.configurationModel.CustomTimeline.NoOfFollowingDays,
                    dateOfStart: kendo.date.addDays(new Date(), - $scope.daysOffset)
                },
                name: "customTimeline",
                calculateDateRange: function () {
                    
                    if ($scope.todayClicked) {
                        this.options.dateOfStart = kendo.date.addDays(new Date(), - $scope.daysOffset);
                        $scope.todayClicked = false;
                    }
                    var start = this.options.dateOfStart;
                    var idx,
                        length,
                        dates = [];
                    for (idx = 0, length = this.options.numberOfDays; idx < length; idx++) {
                        dates.push(start);
                        start = kendo.date.nextDay(start);
                    }

                    this._render(dates);
                },
                previousDate: function() {
                    var date = new Date(this.startDate());
                    date.setDate(date.getDate() - this.options.numberOfDays + $scope.daysOffset);
                    this.options.dateOfStart.setDate(new Date(this.startDate()).getDate() - this.options.numberOfDays);
                    return date;
                },
                nextDate: function() {
                    var date = new Date(this.startDate());
                    date.setDate(date.getDate() + this.options.numberOfDays + $scope.daysOffset);
                    this.options.dateOfStart.setDate(new Date(this.startDate()).getDate() + this.options.numberOfDays);
                    return date;
                },
            });

            $scope.$on("onRowSelected",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                        $scope.updateRequestsWithRowFilter(data);
                        $scope.refresh();
                    }
                });

            $scope.$on("onRefresh",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.refresh();
                    }
                });

            $scope.$on("onFilter",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
                        $scope.updateRequestsWithFilters(data);
                        $scope.refresh();
                    }
                });

            $scope.$on("onGenericTreeNodeSelected",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
                        $scope.updateRequestsWithRowFilter(data);
                        $scope.refresh();
                    }
                });

            $scope.createRequests = function() {
                $scope.tasksRequest = {};
                $scope.tasksRequest.PortletId = $scope.portlet.Id;

                $scope.tasksRequest.TasksQueryId = $scope.portlet.Queries[0] ? $scope.portlet.Queries[0].Id : null;
                $scope.tasksRequest.AssignmentsQueryId =
                    $scope.portlet.Queries[2] ? $scope.portlet.Queries[2].Id : null;

                $scope.tasksRequest.TasksWorkflowId =
                    $scope.portlet.Workflows[0] ? $scope.portlet.Workflows[0].WorkflowId : null;
                $scope.tasksRequest.AssignmentsWorkflowId =
                    $scope.portlet.Workflows[2] ? $scope.portlet.Workflows[2].WorkflowId : null;

                $scope.tasksRequest.TasksOutputVariable =
                    $scope.portlet.Workflows[0] ? $scope.portlet.Workflows[0].OutputVariable : null;
                $scope.tasksRequest.AssignmentsOutputVariable =
                    $scope.portlet.Workflows[2] ? $scope.portlet.Workflows[2].OutputVariable : null;
                $scope.tasksRequest.IgnoreExecutionCheck = $scope.configurationModel.IgnoreExecutionCheck;

                $scope.tasksRequest.DataSourceType = $scope.portlet.DataSourceType;

                $scope.resourcesRequest = {};
                $scope.resourcesRequest.PortletId = $scope.portlet.Id;
                $scope.resourcesRequest.IgnoreExecutionCheck = $scope.configurationModel.IgnoreExecutionCheck;
                if ($scope.portlet.Queries[1]) {
                    $scope.resourcesRequest.QueryId = $scope.portlet.Queries[1].Id;
                }
                if ($scope.portlet.Workflows[1]) {
                    $scope.resourcesRequest.WorkflowId = $scope.portlet.Workflows[1].WorkflowId;
                    $scope.resourcesRequest.OutputVariable = $scope.portlet.Workflows[1].OutputVariable;
                }
                $scope.resourcesRequest.DataSourceType = $scope.portlet.DataSourceType;
            };

            $scope.updateRequestsWithRowFilter = function(data) {
                $scope.tasksRequest.RowFilter = data.Data;
                $scope.resourcesRequest.RowFilter = data.Data;
            };

            $scope.updateRequestsWithFilters = function(data) {
                $scope.tasksRequest.Search = data.Data;
                $scope.resourcesRequest.RowFilter = data.Data;
            };

            $scope.calculateTaskIcon = function(dataItem) {

                for (var i = 0; i < $scope.orderedIconRules.length; i++) {
                    var currentRule = $scope.orderedIconRules[i];
                    if (dataItem.Data) {
                        if ($scope.ruleIsMatching(dataItem.Data, currentRule)) {
                            return currentRule.Icon;
                        }
                    }
                }
                return $scope.configurationModel.IconsConfiguration.DefaultIcon;
            };

            $scope.scrollToTask = function(taskId) {

                var scheduler = $("#scheduler" + $scope.portlet.Id).getKendoScheduler();
                var event = scheduler.dataSource.get(taskId);


                // No event taskId
                if (!event) {
                    return;
                }

                var contentDiv = scheduler.element.find("div.k-scheduler-content");
                var contentDivPosition = contentDiv.position();
                var eventUID = event.uid;
                var eventDiv = $(".k-event[data-uid=" + eventUID + "]");

                if (!eventDiv.length) return;

                var eventDivOffset = eventDiv.offset();


                // Scroll to the div with given data-uid attribute            
                contentDiv.scrollLeft(eventDivOffset.left + contentDiv.scrollLeft() - contentDivPosition.left - 100);
                contentDiv.scrollTop(eventDivOffset.top + contentDiv.scrollTop() - contentDivPosition.top);

                // Select the Event in question
                scheduler.select([event.uid]);

            };

            $scope.parseVariable = function(text, item) {
                if (!text) {
                    return "";
                }
                var variables = text.match(/\{(.*?)\}/g);
                if (!variables) {
                    return text;
                }
                variables.forEach(function(v) {
                    var variableName = v.replace("{", "").replace("}", "");
                    var variableValue = item[variableName];

                    var editor = null;
                    for (var j = 0; j < $scope.configurationModel.TooltipConfiguration.Fields.length; j++) {
                        if ($scope.configurationModel.TooltipConfiguration.Fields[j].DatabaseColumn === variableName)
                            editor = $scope.configurationModel.TooltipConfiguration.Fields[j].Editor;
                    }

                    if (editor === "Date") {
                        variableValue =
                            kendo.toString(kendo.parseDate(variableValue, kendo.culture().calendar.patterns.d));

                    } else if (editor === "Time") {
                        variableValue =
                            kendo.toString(kendo.parseDate(variableValue, kendo.culture().calendar.patterns.T));
                    } else if (editor === "DateTime") {
                        variableValue =
                            kendo.toString(kendo.parseDate(variableValue, kendo.culture().calendar.patterns.G));
                    }

                    text = text.replace("{" + variableName + "}", variableValue === null ? "" : variableValue);
                });
                return text;
            }

            $scope.calculateTaskColor = function(dataItem) {

                for (var i = 0; i < $scope.orderedColorRulesBackground.length; i++) {
                    var currentRule = $scope.orderedColorRulesBackground[i];

                    if ($scope.ruleIsMatching(dataItem.Data, currentRule)) {
                        return currentRule.Colour;
                    }
                }
                return $scope.configurationModel.ColorRulesConfiguration.DefaultColor;
            };

            $scope.ruleIsMatching = function(dataItem, rule) {
                return dataEvalService.evaluateExpression(
                    dataEvalService.createNewDataSet(dataItem),
                    "{" + rule.FirstValue + "}",
                    rule.Func,
                    rule.SecondValue);
            };


            $scope.calculateTooltip = function(dataItem) {
                var tooltip = null;
                if (dataItem) {
                    if ($scope.configurationModel.TooltipConfiguration &&
                        $scope.configurationModel.TooltipConfiguration.Tooltip) {
                        tooltip = translationsService.get(
                            $scope.configurationModel.TooltipConfiguration.Code + "-Tooltip",
                            $scope.configurationModel.TooltipConfiguration.Tooltip);

                        tooltip = $scope.parseVariable(tooltip, dataItem.Data);
                    }
                }
                return tooltip;
            };

            $scope.onDataBound = function () {

                var scheduler = $("#scheduler" + $scope.portlet.Id).data("kendoScheduler");
                
                if (!$scope.initialized) {
                    if (scheduler) {

                        if ($scope.configurationModel.TooltipConfiguration &&
                            $scope.configurationModel.TooltipConfiguration.Tooltip) {

                            $("#scheduler" + $scope.portlet.Id).kendoTooltip({
                                filter: ".k-event",
                                position: "top",
                                width: 250,
                                content: function(e) {
                                    var scheduler = $("#scheduler" + $scope.portlet.Id).getKendoScheduler();

                                    var uid = e.target.data("uid");
                                    //new approach- loop through uids
                                    //old apporach left as safety point
                                    if (uid) {
                                        var event = scheduler.occurrenceByUid(uid);
                                        if (event)
                                            return $scope.calculateTooltip(event);
                                    } else {
                                        var slot = scheduler.slotByElement(e.target);
                                        if (slot && slot.startDate && slot.endDate) {
                                            var events = scheduler.occurrencesInRange(slot.startDate, slot.endDate);
                                            return $scope.calculateTooltip(events[0]);
                                        }
                                        return '';
                                    }
                                }
                            });
                        }

                        scheduler.element.find("ul.k-reset.k-header.k-scheduler-views").before(
                            "<a class='k-pager-refresh k-link' href='#' ng-click='refresh();$event.preventDefault();' style='float:right' id='refresh-button" +
                            $scope.portlet.Id +
                            "'><span class='k-icon k-i-refresh'></span></a>");
                        $compile($("#refresh-button" + $scope.portlet.Id))($scope);

                    }

                    $scope.initialized = true;
                }

                if (scheduler) {
                    if (!$scope.configurationModel.ShowTimesHeader) {
                        var view = scheduler.view();
                        view.timesHeader.hide();
                        $(".k-scheduler-header-wrap > table > tbody > tr:eq(1)").hide();
                    }
                }

            };

            $scope.refresh = function() {
                var scheduler = $("#scheduler" + $scope.portlet.Id).data("kendoScheduler");
                if (scheduler) {
                    $rootScope.$broadcast("onRefresh",
                        {
                            SenderId: $scope.portlet.Id
                        });

                    $scope.tasksRequest.RefreshWorkflowCache = true;
                    $scope.resourcesRequest.RefreshWorkflowCache = true;

                    var promise1 = scheduler.dataSource.read();
                    var promise2 = scheduler.resources[0].dataSource.read();

                    $q.all([promise1, promise2]).then(function() {
                        setView(scheduler);
                        $scope.scrollToTask($scope.recentTaskId);
                    });
                }
            };

            $scope.customFlowDisabled = function(flowId) {

                var flowConfig = null;

                for (var i = 0; i < $scope.configurationModel.FlowConfiguration.CustomFlows.length; i++) {
                    if ($scope.configurationModel.FlowConfiguration.CustomFlows[i].CustomFlow.Id == flowId) {
                        flowConfig = $scope.configurationModel.FlowConfiguration.CustomFlows[i];
                    }
                }

                if (flowConfig && flowConfig.AlwaysEnabled) {
                    return false;
                }

                if (!$scope.selectedItem) {
                    return true;
                }

                var result = false;

                if (flowConfig &&
                    !dataEvalService.evaluateExpression(dataEvalService.createNewDataSet($scope.selectedItem.Data),
                        "{" + flowConfig.LeftValue + "}",
                        flowConfig.Func,
                        flowConfig.RightValue)) {
                    result = true;
                }

                return result;
            };

            $scope.runCustomFlow = function(id, isMachineWorkflow) {

                var isUserAction = false;
                if (!isMachineWorkflow) {
                    isUserAction = true;
                }

                if ($scope.selectedItem) {

                    var operationInputData = {
                        OperationType: "CustomWorkflow",
                        TaskId: $scope.selectedItem.id
                    };

                    $scope.runFlow(id, isUserAction, [$scope.selectedItem.Data], operationInputData);
                } else {
                    $scope.runFlow(id, isUserAction, [], { OperationType: "CustomWorkflow" });
                }
            };

            $scope.removeEvent = function(e) {
                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.DeleteTask &&
                    $scope.configurationModel.FlowConfiguration.DeleteTask !== -1) {
                    var operationInputData = {
                        OperationType: "DeleteTask",
                        TaskId: e.event.id
                    };

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DeleteTask.Id,
                        !$scope.configurationModel.FlowConfiguration.DeleteTask.IsMachineWorkflow,
                        [e.event.Data],
                        operationInputData);

                } else {
                    portletErrorService.showError('Please set delete task workflow!', $scope.portlet.Id);
                    $scope.$apply();
                }
            };
            $scope.onResizeEnd = function(e) {
                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.DropTask &&
                    $scope.configurationModel.FlowConfiguration.DropTask !== -1) {
                    var operationInputData = {
                        OperationType: "ResizeTask",
                        TaskId: e.event.id,
                        TargetResourceId: e.resources.resourceIds[0],
                        StartDate: e.start,
                        EndDate: e.end
                    };

                    $scope.recentTaskId = e.event.id;

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DropTask.Id,
                        !$scope.configurationModel.FlowConfiguration.DropTask.IsMachineWorkflow,
                        [e.event.Data],
                        operationInputData);
                } else {
                    portletErrorService.showError('Please set Drag and Drop workflow!', $scope.portlet.Id);
                    $scope.$apply();
                }
            }

            $scope.onMoveEnd = function(e) {
                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.DropTask &&
                    $scope.configurationModel.FlowConfiguration.DropTask !== -1) {
                    var operationInputData = {
                        OperationType: "DropTask",
                        TaskId: e.event.id,
                        TargetResourceId: e.resources.resourceIds[0],
                        StartDate: e.start,
                        EndDate: e.end
                    };

                    $scope.recentTaskId = e.event.id;

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DropTask.Id,
                        !$scope.configurationModel.FlowConfiguration.DropTask.IsMachineWorkflow,
                        [e.event.Data],
                        operationInputData);

                    var scheduler = $("#scheduler" + $scope.portlet.Id).data("kendoScheduler");
                    scheduler.select(null);
                } else {
                    portletErrorService.showError('Please set Drag and Drop workflow!', $scope.portlet.Id);
                    $scope.$apply();
                }
            }

            $scope.onDoubleClick = function(e) {

                e.preventDefault();
                if ($scope.configurationModel.FlowConfiguration.DoubleClickTask &&
                    $scope.configurationModel.FlowConfiguration.DoubleClickTask !== -1) {
                    var operationInputData = {
                        OperationType: "DoubleClick",
                        TaskId: e.event.id
                    };

                    $scope.recentTaskId = e.event.id;

                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DoubleClickTask.Id,
                        !$scope.configurationModel.FlowConfiguration.DoubleClickTask.IsMachineWorkflow,
                        [e.event.Data],
                        operationInputData);
                } else {
                    portletErrorService.showError('Please set Double Click workflow!', $scope.portlet.Id);
                    $scope.$apply();
                }

            };
            $scope.addEvent = function(e) {
                e.preventDefault();

                var data = [{}];
                var operationInputData = {
                    OperationType: 'Create',
                    StartDate: e.event.start,
                    EndDate: e.event.end,
                    TargetResourceId: e.event.resourceIds[0]
                };

                if ($scope.configurationModel.FlowConfiguration.CreateTask &&
                    ($scope.configurationModel.FlowConfiguration.CreateTask !== -1))
                    $scope.runFlow($scope.configurationModel.FlowConfiguration.CreateTask.Id,
                        !$scope.configurationModel.FlowConfiguration.CreateTask.IsMachineWorkflow,
                        data,
                        operationInputData);
                else {

                    portletErrorService.showError('Please set create workflow!', $scope.portlet.Id);
                    $scope.$apply();
                }
            };


            $scope.onChange = function(e) {
                e.preventDefault();
                $scope.selectedItem = null;
                //allow to select only one item
                if (!$scope.$$phase) {
                    if (e.events.length) {
                        $scope.selectedItem = e.events[0];
                        $rootScope.$broadcast("onRowSelected",
                            { SenderId: $scope.portlet.Id, Data: $scope.selectedItem.Data });
                    }
                    $scope.$apply();
                }
            }

            $scope.filterGroup = function() {

                var selected = $scope.resourceGroups.filter(function(elem) {
                    return elem.Checked;
                });
                var selectedGroups = _.map(selected,
                    function(item) {
                        return item.Title;
                    });

                var scheduler = $("#scheduler" + $scope.portlet.Id).data("kendoScheduler");
                var resources = scheduler.resources[0].dataSource.data();

                var resourcesInSelectedGroups = resources.filter(function(r) {
                    return _.includes(selectedGroups, r.group);;
                });

                var resourcesIdsInSelectedGroups = _.map(resourcesInSelectedGroups, "value");

                //handle tasks filtering
                scheduler.dataSource.filter({
                    operator: function(task) {
                        return _.intersection(task.resourceIds, resourcesIdsInSelectedGroups).length > 0;
                    }
                });

                //handle resource filtering
                scheduler.resources[0].dataSource.filter({
                    operator: function(resource) {
                        return _.includes(selectedGroups, resource.group);
                    }
                });

                setView(scheduler);
            }

            function setView(scheduler) {
                var currentViewTitle = scheduler.view().title;

                var currentViewName = null;
                _.forOwn(scheduler.views,
                    function (value, key) {
                        if (scheduler.views[key].title === currentViewTitle)
                            currentViewName = key;
                    });

                scheduler.view(currentViewName);
            }

            $scope.schedulerViews = function() {
                var views = [
                    {
                        type: "day",
                        title: translationFlowClientService.getValueFromDictionary("Day", "Day")
                    },
                    {
                        type: "week",
                        title: translationFlowClientService.getValueFromDictionary("Week", "Week")
                    },
                    {
                        type: "workWeek",
                        title: translationFlowClientService.getValueFromDictionary("WorkWeek", "WorkWeek")
                    },
                    {
                        type: "month",
                        title: translationFlowClientService.getValueFromDictionary("Month", "Month")
                    },
                    {
                        type: "agenda"
                    },
                    {
                        type: "timeline",
                        title: translationFlowClientService.getValueFromDictionary("Timeline", "Timeline")
                    },
                    {
                        type: "timelineWeek",
                        title: translationFlowClientService.getValueFromDictionary("TimelineWeek", "Timeline Week")
                    },
                    {
                        type: "timelineMonth",
                        title: translationFlowClientService.getValueFromDictionary("TimelineMonth", "Timeline Month")
                    },
                    {
                        type: customTimeline,
                        title: "Custom Timeline"
                    }
                ];

                var sortedViews = [];

                if ($scope.configurationModel.SchedulerViews) {

                    $scope.configurationModel.SchedulerViews.map(function(sv) {
                        views.map(function(v) {
                            if (v.type === sv) {
                                sortedViews.push(v);
                            }
                            if (sv === "customTimeline") {
                                if (!sortedViews.filter(function(sv1){return sv1.type === customTimeline}).length)
                                {
                                    sortedViews.push({
                                        type: customTimeline,
                                        title: $scope.configurationModel.CustomTimeline.Title,
                                        selected: true
                                    });
                                }
                            }
                        });
                    });


                }
                return sortedViews;
            }

            $scope.calculateStartDate = function() {
                return kendo.parseDate($scope.configurationModel.StartTime);
            };


            $scope.getOptions = function() {
                var now = new Date();

				return {
                    date: now,
                    startTime: $scope.calculateStartDate(),
                    endTime: kendo.parseDate($scope.configurationModel.EndTime),
                    workDayStart: kendo.parseDate($scope.configurationModel.BusinessStartTime),
                    workDayEnd: kendo.parseDate($scope.configurationModel.BusinessEndTime),
                    height: parseInt($scope.configurationModel.Height),
                    eventHeight: 10,
                    // mobile: $scope.browserUtils.isIPad(),
                    dateHeaderTemplate: kendo.template("#=kendo.toString(date, 'd')#"),
                    majorTick: parseInt($scope.configurationModel.MajorTick),
                    views: $scope.schedulerViews(),
                    editable: {
                        confirmation: false,
                        resize: !$scope.configurationModel.ReadOnly,
                        move: !$scope.configurationModel.ReadOnly,
                        destroy: false,
                        update: !$scope.configurationModel.ReadOnly,
                        create: !$scope.configurationModel.ReadOnly
                    },
                    selectable: true,
                    change: $scope.onChange,
                    edit: $scope.onDoubleClick,
                    moveEnd: $scope.onMoveEnd,
                    resizeEnd: $scope.onResizeEnd,
                    remove: $scope.removeEvent,
                    add: $scope.addEvent,
                    dataBound: $scope.onDataBound,
                    navigate: function(e) {
                        if (e.action === "today") {
                            $scope.todayClicked = true;
                        }
                    },
                    allDayEventTemplate: $("#all-day-event-template").html(),
                    eventTemplate: $("#event-template").html(),
                    dataSource: new kendo.data.SchedulerDataSource({
                        batch: true,
                        transport: {
                            read: function(options) {
                                SchedulerService.getTasks($scope.tasksRequest, $scope.portlet.Id)
                                    .then(function(result) {
                                        for (var i = 0; i < result.data.length; i++) {
                                            result.data[i].Color = $scope.calculateTaskColor(result.data[i]);
                                            //icon per task
                                            var iconToUse = $scope.calculateTaskIcon(result.data[i]);
                                            if (iconToUse) {
                                                result.data[i].Icon = iconToUse;
                                            } else
                                                result.data[i].Icon = null;
                                        }
                                        options.success(result.data);
                                    }).catch(function() {
                                        options.success([]);
                                    })
                                    .finally(function() {
                                        $scope.tasksRequest.RefreshWorkflowCache = false;
                                        $scope.controllingModel.DisplayLoadingBar = false;
                                    });
                            },
                            parameterMap: function(options, operation) {
                                if (operation !== "read") {
                                    return { models: kendo.stringify(options.models || [options]) };
                                }
                            }
                        },
                        schema: {
                            model: {
                                id: "id",
                                fields: SchedulerService.createFields()
                            }
                        }
                    }),
                    group: {
                        resources: ["Resources"],
                        orientation: $scope.configurationModel.HorizontalGrouping ? "horizontal" : "vertical"
                    },
                    resources: [
                        {
                            field: "resourceIds",
                            name: "Resources",
                            multiple: true,
                            dataSource: {
                                transport: {
                                    read: function(options) {
                                        SchedulerService.getResources($scope.resourcesRequest, $scope.portlet.Id)
                                            .then(function(result) {
                                                $scope.resourceGroups = SchedulerService.buildGroups(result.data,
                                                    $scope.configurationModel.ResourcesDatasource
                                                    .ResourceGroupColumnName);
                                                options.success(result.data);
                                                if ($scope.configurationModel.ResourcesDatasource
                                                    .ResourceGroupColumnName)
                                                    $scope.filterGroup();
                                            }).catch(function() {
                                                options.success([]);
                                            }).finally(function() {
                                                $scope.resourcesRequest.RefreshWorkflowCache = false;
                                                $scope.controllingModel.DisplayLoadingBar = false;
                                            });
                                    }
                                },
                                schema: {
                                    model: {
                                        id: "Id",
                                        fields: {
                                            text: { from: "Title", type: "string" },
                                            value: { from: "Id", type: "string" },
                                            group: { from: "Group", type: "string" }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                };
            }

            $scope.initialize = function() {

                $scope.initialized = false;

                $scope.imageService = imageService;
                $scope.operationAndInputFlowService = operationAndInputFlowService;

                $scope.orderedIconRules = _.sortBy($scope.configurationModel.IconsConfiguration.Icons,
                    function(icon) {
                        return icon.Position;
                    });

                $scope.orderedColorRulesBackground = _.sortBy(
                    $scope.configurationModel.ColorRulesConfiguration.ColorRules,
                    function(rule) {
                        return rule.Position;
                    });

                $scope.createRequests();
                $scope.schedulerOptions = $scope.getOptions();

            }
            $scope.initialize();
        }
    ]);