app.controller("DocManTreeController",
    [
        "$scope", "$rootScope", "DocManTreeService", "appConfig", "sharedSessionService", "$controller",
        function($scope, $rootScope, DocManTreeService, appConfig, sharedSessionService, $controller) {

            $controller("BasePluginController", { $scope: $scope });

            $scope.selectItem = function(item) {
                $rootScope.$broadcast("updateDocumentManagementList",
                    { SenderId: $scope.portlet.Id, SelectedNodeValue: item.id });
            };

            $scope.treeData = new kendo.data.HierarchicalDataSource({
                transport: {
                    read:
                        function(e) {
                            DocManTreeService.getTreeData(e.data.id, $scope.portlet.Id).then(function(result) {
                                    e.success(result.data);
                                }).catch(function(error) {
                                    e.error(error);
                                })
                                .finally(function() {
                                });
                        }
                },
                schema: {
                    model: {
                        fields: {
                            id: "Id",
                            hasChildren: "HasChildren"
                        }
                    }
                }
            });
        }
    ])