app.controller('ProcessOverview_CreateOrEditPortletController', [
    '$scope', '$uibModalInstance', '$rootScope', 'createOrEditModalService', 'processOverviewConfigurationService', 'data', 'propertyService', 'suggestionService', 'editorService', 'connectorService', 'kendoGridUtilService', 'Guid', 'dataEvalService', 'appConfig',
        function ($scope, $uibModalInstance, $rootScope, createOrEditModalService, processOverviewConfigurationService, data, propertyService, suggestionService, editorService, connectorService, kendoGridUtilService, Guid, dataEvalService, appConfig) {

    $scope.querySuggestions = suggestionService.getSuggestions();
    $scope.connectorService = connectorService;

            $scope.panelBarOptions = {
                expandMode: "single",
                select: $scope.expandCollapse
            };

    $scope.model = createOrEditModalService.getModel("ProcessOverview", data);

    $scope.configurationModel = processOverviewConfigurationService.getConfigurationModel($scope.model.Properties);


    $scope.dblClickWorkflowSelected = function () {  
        var cfgModel = $scope.configurationModel.WorkFlowModel.workFlowDoubleClick;

        if (angular.isUndefined(cfgModel) || cfgModel == null) {
            return false;
        }
        if (cfgModel == appConfig.TablePlugin.NO_WORKFLOW) {
            return false;
        }
        if (angular.isUndefined(cfgModel.Id) || angular.isUndefined(cfgModel.Name)) {
            return false;
        }
        return true;
    };



    $scope.draggableHint = function (e) {
        return $('<div class="k-grid k-widget"><table><tbody><tr>' + $(e).parent().parent().html() + '</tr></tbody></table></div>');
    }

    $scope.colorGrid = {
        editable: true,
        scrollable: false,
        toolbar: [{ name: "create", text: "Add" }],
        columns: [
            { field: "Position", title: "Pos", width: 24, readonly: true, editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'), template: '<div id="ruleId_#: Position #" kendo-droptarget k-drop="onRuleDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>' },
            { field: "Colour", title: "Colour", width: "50px", editor: kendoGridUtilService.getColorPicker(), template: kendoGridUtilService.getColorTemplate() },
            { field: "FirstValue", title: "First Value", width: "150px" },
            { field: "Func", title: "Func", width: "100px", editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'), template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func') },
            { field: "SecondValue", title: "Second Value", width: "150px" },
            { command: [{ name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>" }], width: 24 }
        ],
        dataSource: new kendo.data.DataSource({
            data: $scope.configurationModel.CardDefinition.colours,
            autoSync: true,
            transport: {
                read: function(o) {
                    o.success($scope.configurationModel.CardDefinition.colours);
                },
                create: function(o) {
                    var item = o.data;
                    item.Position = $scope.configurationModel.CardDefinition.colours.length + 1;
                    $scope.configurationModel.CardDefinition.colours.unshift(item);
                    o.success(item);
                },
                update: function(o) {
                    o.success();
                },
                destroy: function(o) {
                    for (var index = 0; index < $scope.configurationModel.CardDefinition.colours.length; index++) {
                        if ($scope.configurationModel.CardDefinition.colours[index].Id == o.data.Id) {
                            $scope.configurationModel.CardDefinition.colours.splice(index, 1);
                            break;
                        }
                    }
                    o.success();
                },
            },
            schema: {
                model: {
                    id: "Position",
                    fields: {
                        Position: { type: "int", defaultValue: 0 },
                        FirstValue: { type: "string" },
                        Func: { type: "string", defaultValue: 1 },
                        SecondValue: { type: "string" },
                        Colour: {
                            type: "string", defaultValue: "#000000", validation: { required: { message: "Field is required"} } }
                        
                    }
                },
            }
        })
    };

    $scope.colorGrid.dataSource.fetch(function () {
        $scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
    });



    $scope.onRuleDrop = function (e) {

        var id = $(e.draggable.element).attr('id').split('_')[1];

        var target = $scope.colorGrid.dataSource.get(id),
            dest = $(e.dropTarget);

        var dropTargetId = $(e.dropTarget).attr('id').split('_')[1];

        if (dest.is("th")) {
            return;
        }
        dest = $scope.colorGrid.dataSource.get(dropTargetId);

        //not on same item
        if (target.get("id") !== dest.get("id")) {
            //reorder the items
            var tmp = target.get("Position");
            target.set("Position", dest.get("Position"));
            dest.set("Position", tmp);

            $scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
        }
    }

    $scope.iconGrid = {
        editable: true,
        scrollable: false,
        toolbar: [{ name: "create", text: "Add" }],
        columns: [
            { field: "Position", title: "Pos", width: 24, readonly: true, editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'), template: '<div id="iconId_#: Position #" kendo-droptarget k-drop="onIconDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>' },
            { field: "Icon", title: "Icon", width: "50px" },
            { field: "FirstValue", title: "First Value", width: "150px" },
            { field: "Func", title: "Func", width: "100px", editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'), template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func') },
            { field: "SecondValue", title: "Second Value", width: "150px" },
            { command: [{ name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>" }], width: 24 }
        ],
        dataSource: new kendo.data.DataSource({
            data: $scope.configurationModel.CardDefinition.icons,
            autoSync: true,
            transport: {
                read: function (o) {
                    o.success($scope.configurationModel.CardDefinition.icons);
                },
                create: function (o) {
                    var item = o.data;
                    item.Position = $scope.configurationModel.CardDefinition.icons.length + 1;
                    $scope.configurationModel.CardDefinition.icons.unshift(item);
                    o.success(item);
                },
                update: function (o) {
                    o.success();
                },
                destroy: function (o) {
                    for (var index = 0; index < $scope.configurationModel.CardDefinition.icons.length; index++) {
                        if ($scope.configurationModel.CardDefinition.icons[index].Id == o.data.Id) {
                            $scope.configurationModel.CardDefinition.icons.splice(index, 1);
                            break;
                        }
                    }
                    o.success();
                },
            },
            schema: {
                model: {
                    id: "Position",
                    fields: {
                        Position: { type: "int", defaultValue: 0 },
                        FirstValue: { type: "string" },
                        Func: { type: "string", defaultValue: 1 },
                        SecondValue: { type: "string" },
                        Icon: { type: "string" }

                    }
                },
            }
        })
    };

    $scope.iconGrid.dataSource.fetch(function () {
        $scope.iconGrid.dataSource.sort({ field: "Position", dir: "asc" });
    });


    $scope.onIconDrop = function (e) {

        var id = $(e.draggable.element).attr('id').split('_')[1];

        var target = $scope.iconGrid.dataSource.get(id),
            dest = $(e.dropTarget);

        var dropTargetId = $(e.dropTarget).attr('id').split('_')[1];

        if (dest.is("th")) {
            return;
        }
        dest = $scope.iconGrid.dataSource.get(dropTargetId);

        //not on same item
        if (target.get("id") !== dest.get("id")) {
            //reorder the items
            var tmp = target.get("Position");
            target.set("Position", dest.get("Position"));
            dest.set("Position", tmp);

            $scope.iconGrid.dataSource.sort({ field: "Position", dir: "asc" });
        }
    }

    $scope.requerifyLanes = function () {
        if ($scope.configurationModel.LaneModel.lanes.length) {

            var queriesEnumerable = $scope.model.Queries;

            $scope.configurationModel.LaneModel.CommonConnectorName = (_.first(queriesEnumerable) || { ConnectorName: '' }).ConnectorName;

            for (var i = 0; i < $scope.configurationModel.LaneModel.lanes.length; i++) { 

                var query = _.find(queriesEnumerable, function (element) {
                    return element.Id.toUpperCase() === $scope.configurationModel.LaneModel.lanes[i].QueryId.toUpperCase();
                });

                if (query) {
                    $scope.configurationModel.LaneModel.lanes[i].Query = query.Value;
                } 
                //Check if we are acting on a reset object and if so, redo the Id's for Queries
                //We dont need to modify the Query object, since they will be cleared out on save anyway.
                else {
                    var laneQuery = _.find(queriesEnumerable, function (element) {
                        return element.Id.toUpperCase() === $scope.configurationModel.LaneModel.lanes[i].QueryId.toUpperCase();
                    });

                    if (laneQuery) {
                        $scope.configurationModel.LaneModel.lanes[i].Query = laneQuery.Value;
                    } else {
                        $scope.configurationModel.LaneModel.lanes[i].Query = $scope.model.Queries[i].Value;
                    } 

                    $scope.configurationModel.LaneModel.lanes[i].QueryId = Guid.newGuid();
                }
            };
        }
    }

    $scope.requerifyLanes();
    
    $scope.laneDataSource = new kendo.data.DataSource({
        data: $scope.configurationModel.LaneModel.lanes,
        autoSync: false, 
        transport: {
            read: function(o) {
                o.success($scope.configurationModel.LaneModel.lanes);
            },
            create: function(o) {
                var item = o.data;
                item.Position = $scope.configurationModel.LaneModel.lanes.length + 1;
                item.QueryId = Guid.newGuid();
                item.Code = Guid.newGuid();
                $scope.configurationModel.LaneModel.lanes.unshift(item);
                o.success(item);
            },
            update: function(o) {
                o.success();
            },
            destroy: function(o) {
                for (var index = 0; index < $scope.configurationModel.LaneModel.lanes.length; index++) {
                    if ($scope.configurationModel.LaneModel.lanes[index].Id == o.data.Id) {
                        $scope.configurationModel.LaneModel.lanes.splice(index, 1);
                        break;
                    }
                }

                o.success();
            },
        },
        schema: {
            model: {
                id: "Position",
                fields: {
                    Position: {type: "int", defaultValue: 0},
                    Title: { type: "string" },
                    Query: { type: "string" },
                    QueryId: { type: "string" },
                    HeaderColour: {
                        type: "string", defaultValue: "#000000", validation: { required: { message: "Field is required"} } },
                    HeaderBgColour: {
                        type: "string", defaultValue: "#FFFFFF", validation: { required: { message: "Field is required"}  } },
                    SortColumn: { type: "string" },
                    SortDirection: { type: "string", defaultValue: "asc" },
                    Code: {type: "string"}
                }
            }
        }
    });
    
    $scope.laneDataSource.fetch(function () {
        $scope.laneDataSource.sort({ field: "Position", dir: "asc" });
    });

            $scope.laneGrid = {
                editable: 'popup',
                edit: function(e) {
                    //This is to hide the position field in the edit dialog
                    e.container.find(".k-edit-label:first").hide();
                    e.container.find(".k-edit-field:first").hide();

                    //Suggested by telerik, makes me cringe though :S
                    window.setTimeout(function() {
                        $(".k-edit-form-container").parent().width(800).height(500).data("kendoWindow").center();
                    }, 100);
                },
                scrollable: false,
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                { field: "Position", title: "Pos", width: 24, readonly: true, editor: kendoGridUtilService.getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'), template: '<div id="laneId_#: Position #" kendo-droptarget k-drop="onLaneDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>' },
                { field: "Title", title: "Title", width: "210px" },
                { field: "HeaderBgColour", title: "Background", width: "50px", editor: kendoGridUtilService.getColorPicker(), template: kendoGridUtilService.getColorTemplate("HeaderBgColour") },
                { field: "HeaderColour", title: "Text", width: "25px", editor: kendoGridUtilService.getColorPicker(), template: kendoGridUtilService.getColorTemplate("HeaderColour") },
                { field: "Query", title: "Query", width: "210px", editor: kendoGridUtilService.getQueryTextEditor(250, 500, "querySuggestions"), template: kendoGridUtilService.getShortContentTemplate('Query', 30) },
                {
                    field: "SortColumn", title: "OrderBy", width: "100px", editor: editorService.createColumnNameComboBoxForInrowQuery('Query', function() {
                        return $scope.configurationModel.LaneModel.CommonConnectorName;
                    }),
                    template: kendoGridUtilService.getNullToEmptyTemplate("SortColumn")
                },
                { field: "SortDirection", title: "Direction", width: "100px", editor: kendoGridUtilService.makeKendoDropDownFromList($scope.configurationModel.SortOrders, 'Display', 'Data'), template: kendoGridUtilService.getListTemplate($scope.configurationModel.SortOrders, 'Display', 'Data', 'SortDirection') },

            {
                command: [
                    { name: "edit", template: "<a class='k-button k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>" },
                    { name: "destroy", template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>" }], width: 50
            }
        ],
            
        dataSource: $scope.laneDataSource
    };

    $scope.onLaneDrop = function (e) {

        var id = $(e.draggable.element).attr('id').split('_')[1];

        var target = $scope.laneDataSource.get(id),
            dest = $(e.dropTarget);

        var dropTargetId = $(e.dropTarget).attr('id').split('_')[1];

        if (dest.is("th")) {
            return;
        }
        dest = $scope.laneDataSource.get(dropTargetId);

        //not on same item
        if (target.get("id") !== dest.get("id")) {
            //reorder the items
            var tmp = target.get("Position");
            target.set("Position", dest.get("Position"));
            dest.set("Position", tmp);

            $scope.laneDataSource.sort({ field: "Position", dir: "asc" });
        }
    }

    $scope.ok = function () {


        $scope.laneGrid.dataSource.sync();
        $scope.colorGrid.dataSource.sync();

        //Clear old
        $scope.model.Queries = [];

        angular.forEach($scope.configurationModel.LaneModel.lanes, function (lane) {
            var newLaneQuery = {
                Id: lane.QueryId,
                Value: lane.Query,
                ConnectorName: $scope.configurationModel.LaneModel.CommonConnectorName
            }
            $scope.model.Queries.push(newLaneQuery);
            delete lane.Query;

        });;

        processOverviewConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model);
        //kendo.destroy($('body > div:gt(0)'));
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
        //kendo.destroy($('body > div:gt(0)'));
    };

}])
