app.controller('IframeController', [
    '$scope', '$rootScope', 'propertyService', 'receiverService', 'iframeConfigurationService','variableService','$controller', 
    function($scope, $rootScope,propertyService, receiverService, iframeConfigurationService, variableService, $controller) {

        $controller('BasePluginController', { $scope: $scope });

        $scope.variableService = variableService;

        $scope.filterData = undefined;

        $scope.$on('onRowSelected', function (event, data) {
            if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                $scope.filterData = data.Data;
                $scope.refresh();
            }
        });

        $scope.$on('onRefresh', function (event, data) {
            if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                $scope.refresh();
            }
        });

        $scope.inflateAdress = function() {
            if ($scope.filterData) {
                $scope.Adress = $scope.variableService.variableReplacer($scope.portlet.Content, $scope.filterData);
                
            } else {
                $scope.Adress = $scope.portlet.Content;
            }

            $scope.Adress = $scope.variableService.globalVariableReplacer($scope.Adress, function (newAdress) {
                $scope.Adress = newAdress;
            });

        };

        $scope.refresh = function () {
            $scope.controllingModel.DisplayLoadingBar = true;

            $scope.inflateAdress();
            $scope.controllingModel.DisplayLoadingBar = false;
        }

        $scope.initialize = function() {

            $scope.configurationModel = iframeConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = iframeConfigurationService.getControllingModel();

            $scope.inflateAdress();

            $scope.controllingModel.DisplayLoadingBar = false;

        }

        $scope.initialize();


    }
]);
