app.factory('dataVisualizerCardConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        configurationModel.CardData = {};
        configurationModel.CardData.OutputMode = propertyService.getPropertyValue(properties, "OutputMode", "default");

        configurationModel.CardDataMapping = {};
        configurationModel.CardDataMapping.Description = propertyService.getPropertyValue(properties, "Description", undefined);
        configurationModel.CardDataMapping.DescriptionDefault = propertyService.getPropertyValue(properties, "DescriptionDefault", undefined);
        configurationModel.CardDataMapping.MainData = propertyService.getPropertyValue(properties, "MainData", undefined);
        configurationModel.CardDataMapping.Unit = propertyService.getPropertyValue(properties, "Unit", undefined);
        configurationModel.CardDataMapping.Priority = propertyService.getPropertyValue(properties, "Priority", undefined);
        configurationModel.CardDataMapping.Trend = propertyService.getPropertyValue(properties, "Trend", undefined);
        configurationModel.CardDataMapping.SpeedometerMin = propertyService.getPropertyValue(properties, "SpeedometerMin", undefined);
        configurationModel.CardDataMapping.SpeedometerMax = propertyService.getPropertyValue(properties, "SpeedometerMax", undefined);

        var showScalePropertyValue = propertyService.getPropertyValue(properties, "SpeedometerShowScale", "true"); 
        configurationModel.CardData.SpeedometerShowScale = showScalePropertyValue === "true";

        configurationModel.CardData.SpeedometerThickness = propertyService.getIntPropertyValue(properties, "SpeedometerThickness", 6);
        configurationModel.CardData.SpeedometerBackgroundThickness = propertyService.getIntPropertyValue(properties, "SpeedometerBackgroundThickness", configurationModel.CardData.SpeedometerThickness);
        
        
        //the definitionkey must be a viable JS-property or variable name
        //Since it is going to be used as such dynamically in the controller.
        configurationModel.SpeedometerBackground = $.parseJSON(propertyService.getPropertyValue(properties,
            "SpeedometerBackground",
            JSON.stringify(
                {
                    definitionKey: 'background',
                    defaultColor: '#edf8e1',
                    colours: []
                })));

        configurationModel.SpeedometerLineBackground = $.parseJSON(propertyService.getPropertyValue(properties,
            "SpeedometerLineBackground",
            JSON.stringify(
                {
                    definitionKey: 'background',
                    defaultColor: '#c9d6d3',
                    colours: []
                })));

        configurationModel.SpeedometerLineBackgroundScale = $.parseJSON(propertyService.getPropertyValue(properties,
            "SpeedometerLineBackgroundScale",
            JSON.stringify(
                {
                    definitionKey: 'background',
                    defaultColor: '#c9d6d3',
                    colours: []
                })));


        configurationModel.SpeedometerLineForeground = $.parseJSON(propertyService.getPropertyValue(properties,
            "SpeedometerLineForeground",
            JSON.stringify(
                {
                    definitionKey: 'background',
                    defaultColor: '#ffcc66',
                    colours: []
                })));

        configurationModel.ColorBackground = $.parseJSON(propertyService.getPropertyValue(properties,
            "ColorBackground",
            JSON.stringify(
            {
                definitionKey: 'background',
                defaultColor: '#CCCCCC',
                colours: []
            })));


        configurationModel.ColorText = $.parseJSON(propertyService.getPropertyValue(properties,
            "ColorText",
            JSON.stringify(
            {
                definitionKey: 'text',
                defaultColor: '#000000',
                colours: []
            })));

        configurationModel.SpeedometerTextColor = $.parseJSON(propertyService.getPropertyValue(properties,
            "SpeedometerTextColor",
            JSON.stringify(
                {
                    definitionKey: 'text',
                    defaultColor: '#000000',
                    colours: []
                })));
        
        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }

    service.saveProperties = function (properties, configurationModel, operation) {
        configurationService.saveProperties(properties, configurationModel);

        propertyService.setProperty(properties, "OutputMode", configurationModel.CardData.OutputMode); 

        propertyService.setPropertyWithRemoveOnEmpty(properties, "Description", configurationModel.CardDataMapping.Description);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "DescriptionDefault", configurationModel.CardDataMapping.DescriptionDefault);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "MainData", configurationModel.CardDataMapping.MainData);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "Unit", configurationModel.CardDataMapping.Unit);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "Priority", configurationModel.CardDataMapping.Priority);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "Trend", configurationModel.CardDataMapping.Trend);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "SpeedometerBackgroundThickness", configurationModel.CardData.SpeedometerBackgroundThickness);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "SpeedometerThickness", configurationModel.CardData.SpeedometerThickness);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "SpeedometerMin", configurationModel.CardDataMapping.SpeedometerMin);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "SpeedometerMax", configurationModel.CardDataMapping.SpeedometerMax);
        propertyService.setProperty(properties, "SpeedometerShowScale", configurationModel.CardData.SpeedometerShowScale);

        if (configurationModel.SpeedometerBackground) {
            propertyService.setProperty(properties, "SpeedometerBackground", JSON.stringify(configurationModel.SpeedometerBackground));
        }

        if (configurationModel.SpeedometerLineBackground) {
            propertyService.setProperty(properties, "SpeedometerLineBackground", JSON.stringify(configurationModel.SpeedometerLineBackground));
        }

        if (configurationModel.SpeedometerLineBackgroundScale) {
            propertyService.setProperty(properties, "SpeedometerLineBackgroundScale", JSON.stringify(configurationModel.SpeedometerLineBackgroundScale));
        }

        if (configurationModel.SpeedometerLineForeground) {
            propertyService.setProperty(properties, "SpeedometerLineForeground", JSON.stringify(configurationModel.SpeedometerLineForeground));
        }

        if (configurationModel.ColorBackground) {
            propertyService.setProperty(properties, "ColorBackground", JSON.stringify(configurationModel.ColorBackground));
        }
        if (configurationModel.ColorText) {
            propertyService.setProperty(properties, "ColorText", JSON.stringify(configurationModel.ColorText));
        }
        if (configurationModel.SpeedometerTextColor) {
            propertyService.setProperty(properties, "SpeedometerTextColor", JSON.stringify(configurationModel.SpeedometerTextColor));
        }
    }

    return service;
}]);