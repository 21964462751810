app.controller("InboxPluginController",
    [
        "$scope", "$controller", "inboxPluginConfigurationService",
        function($scope, $controller, inboxPluginConfigurationService) {

            $controller("homeController", { $scope: $scope });
            $scope.portlet.ParsedTitle = $scope.portlet.Title;
            $scope.configurationModel = inboxPluginConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = inboxPluginConfigurationService.getControllingModel();
        }
    ]);
