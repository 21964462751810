app.controller("GenericDocumentViewerController",
    [
        "$scope", "$rootScope", "GenericDocumentViewerService", "receiverService", "genericDocumentViewerConfigurationService",
        "portletErrorService", "translationsService", "globalVariableService",
        "appConfig", "sharedSessionService", "$sce",  "$controller", 
        function ($scope,
            $rootScope,
            GenericDocumentViewerService,
            receiverService,
            genericDocumentViewerConfigurationService,
            portletErrorService,
            translationsService,
            globalVariableService,
            appConfig,
            sharedSessionService,
            $sce, $controller) {

            $controller('BasePluginController', { $scope: $scope });

            $scope.statusCode = "";
            $scope.hasStatus = false;
            $scope.isPdf = false;
            $scope.isImage = false;
            $scope.mediaType = "";

            $scope.initialize = function () {
                $scope.configurationModel =genericDocumentViewerConfigurationService.getConfigurationModel($scope.portlet.Properties);
                $scope.controllingModel = genericDocumentViewerConfigurationService.getControllingModel();
                $scope.controllingModel.DisplayLoadingBar = false;
                $scope.DocumentViewerViewModel = {};
                $scope.DocumentViewerViewModel.DefaultMimeType = $scope.configurationModel.DefaultMimeType;
                $scope.DocumentViewerViewModel.DataColumnName = $scope.configurationModel.DataColumnName;
                $scope.DocumentViewerViewModel.MimeTypeColumnName = $scope.configurationModel.MimeTypeColumnName;
                $scope.DocumentViewerViewModel.FileNameColumn = $scope.configurationModel.FileNameColumn;
                $scope.DocumentViewerViewModel.QueryId = (_.first($scope.portlet.Queries) || { Id: null }).Id;
                $scope.DocumentViewerViewModel.WorkflowId = (_.first($scope.portlet.Workflows) || { WorkflowId: null }).WorkflowId;
                $scope.DocumentViewerViewModel.OutputVariable = (_.first($scope.portlet.Workflows) || { OutputVariable: null }).OutputVariable; 

                $scope.DocumentViewerViewModel.DataSourceType = $scope.portlet.DataSourceType;
                $scope.DocumentViewerViewModel.GlobalVariables = globalVariableService.getAvailableGlobalVariables();
                $scope.DocumentViewerViewModel.RowFilter = null;

            };

            $scope.refresh = function () {
                $scope.getDocument();
            };

            $scope.$on("onMaximized",
                function (event, pcPortlets) {
                    if ($scope.mediaType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=100";
                        $scope.setPdfSizes();
                    }
                });

            $scope.$on("onMinimized",
                function (event, pcPortlets) {
                    if ($scope.mediaType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=auto";
                        $scope.setPdfSizes();
                    }
                });

            $scope.$on("onRowSelected",
                function (event, value) {
                    if (receiverService.canReceive($scope.portlet, "onRowSelected", value) &&
                        receiverService.commandsAreConfigured($scope.portlet)) {
                        $scope.DocumentViewerViewModel.RowFilter = value.Data;
                        $scope.getDocument();
                    }
                });

            $scope.$on("onGenericTreeNodeSelected",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data) &&
                        receiverService.commandsAreConfigured($scope.portlet)) {
                        $scope.DocumentViewerViewModel.RowFilter = data.Data;
                        $scope.getDocument();
                    }
                });

            $scope.$on("onRefresh",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.refresh();
                    }
                });

            $scope.handleStatus = function(status, statusText) {
                if (status !== 200) {

                    if (status === 204) {
                        if (statusText !== "") {
                            $scope.statusCode = statusText;
                        } else {
                            $scope.statusCode = "NoContent";
                        }
                    }

                    $scope.hasStatus = true;
                };
            }

            $scope.getDocument = function () {

                if ($scope.DocumentViewerViewModel.RowFilter != null &&
                    !$.isEmptyObject($scope.DocumentViewerViewModel.RowFilter)) {

                    $scope.controllingModel.DisplayLoadingBar = true;
                    $scope.fileUrl = null;
                    $scope.image = null;
                    $scope.hasStatus = false;
                    $scope.isPdf = false;
                    $scope.isImage = false;

                    GenericDocumentViewerService.getDocument($scope.DocumentViewerViewModel, $scope.portlet.Id)
                   .then(function (result) {
                       var data = result.data;
                       var headers = result.headers;

                       $scope.handleStatus(result.status, result.statusText);

                       if (data.byteLength > 0) {

                           $scope.mediaType = headers("content-type");

                           var file = new Blob([data], { type: $scope.mediaType });

                           if ($scope.mediaType === "application/pdf") {

                               $scope.isPdf = true;

                               var path = $.path +
                                   "/" + $.contentDir + "/pdfViewer/web/viewer.html?file=" +
                                   $sce.trustAsResourceUrl(URL.createObjectURL(file)) +
                                   "&lang=" +
                                   sharedSessionService.getLanguageCode();


                               $scope.fileUrl = path;
                               $scope.setPdfSizes();
                              
                           } else if ($scope.mediaType.indexOf("image") === 0) {

                               var binary = "";
                               var bytes = new Uint8Array(data);
                               var len = bytes.byteLength;
                               for (var i = 0; i < len; i++) {
                                   binary += String.fromCharCode(bytes[i]);
                               }
                               $scope.image = window.btoa(binary);
                               $scope.isImage = true;
                           } else {
                               saveAs(file);
                           }
                       }
                   }).catch(function(e) {
                            $scope.handleStatus(e.status, e.statusText);
                        }).finally(function () {
                       $scope.controllingModel.DisplayLoadingBar = false;
                   });
                }
            };

            $scope.setPdfSizes = function () {
                $scope.pdfSizes = {
                    "width": "100%", "height": $("#doc" + $scope.portlet.Id).closest(".portletContainerBody").height() -
                        $("#doc" + $scope.portlet.Id).closest(".portletContainerBody").find("ul")
                        .height() -10 + "px"
                }
            };

            $scope.initialize();
        }
    ]);

