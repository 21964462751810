app.controller('TextController', [
    '$scope','translationsService','variableService','receiverService', '$controller', 
    function ($scope, translationsService, variableService, receiverService, $controller) {

        $controller('BasePluginController', { $scope: $scope });  
      
        $scope.lastRowData = undefined;
        $scope.translatedContent = undefined;

        $scope.finishedContent = undefined;
        
        $scope.$on("onRowSelected",
        function (event, data) {
            if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                $scope.lastRowData = data.Data;
                $scope.refresh();
            }
        });

        $scope.refresh = function () {

            if (!$scope.translatedContent) {
                $scope.translatedContent = translationsService.get($scope.portlet.Code + '-Content', $scope.portlet.Content);
            }
            if ($scope.lastRowData) {
                $scope.finishedContent = variableService.variableReplacer($scope.translatedContent, $scope.lastRowData);
            } else {
                $scope.finishedContent = variableService.variableReplacer($scope.translatedContent);
            }

        }

        $scope.init = function() {
            $scope.refresh();
        };

        $scope.init();



    }
])