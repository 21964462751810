app.service('ProcessOverviewService', ['basePluginService', '$q',
    function (basePluginService,$q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getQueryExecutionHeader = function (paginationModel, portletId) {
            return basePluginService.backend.post("queryExecutionHeader", paginationModel, portletId);
        };

        service.processOverviewQueryExecution = function (paginationModel, portletId) {
            return basePluginService.backend.post("queryExecution", paginationModel, portletId);
        };

        return service;

    }]);