app.controller('ExportController', ['$scope', '$uibModalInstance', 'translationFlowClientService', function ($scope, $uibModalInstance, translationFlowClientService) {
    $scope.method = "Current page";
    $scope.translationFlowClientService = translationFlowClientService;

    $scope.ok = function () {
        $uibModalInstance.close($scope.method);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

}])