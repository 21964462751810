app.factory('htmlConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {

        var configurationModel = configurationService.getConfigurationModel(properties);
        configurationModel.HtmlModel = {};
        configurationModel.HtmlModel.Header = propertyService.getPropertyValue(properties, "htmlHeader", "");
        configurationModel.HtmlModel.Fields = propertyService.getObjectPropertyValue(properties, "htmlFields", []);
        configurationModel.PageSize = parseInt(propertyService.getPropertyValue(properties, "pageSize", "10"));
        configurationModel.MandatoryDataSource = false;
        //include or exclude dataSource items( cacheable) 
        configurationModel.datasourceWorkflowPath = "dataSourceWorkflows/true";

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();        
    }


    service.saveProperties = function (properties, configurationModel) {
        configurationService.saveProperties(properties, configurationModel);
        propertyService.setProperty(properties, "htmlHeader", configurationModel.HtmlModel.Header.toString());
        propertyService.setObjectProperty(properties, "htmlFields", configurationModel.HtmlModel.Fields);
        propertyService.setProperty(properties, "pageSize", configurationModel.PageSize.toString());
    }

    return service;
}]);