app.controller('DocManListController', [
    '$scope', '$rootScope', 'DocManListService', 'translationFlowClientService', 'receiverService', 'docManListConfigurationService',
    'portletErrorService', 'msieVersionService', 'appConfig', '$controller', 
    function ($scope, $rootScope, DocManListService, translationFlowClientService, receiverService, docManListConfigurationService,
        portletErrorService, msieVersionService, appConfig, $controller) {

        $controller('BasePluginController', { $scope: $scope });

        $scope.initialize = function () {

            $scope.configurationModel = docManListConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = docManListConfigurationService.getControllingModel();
            $scope.PaginationModel = {};
            $scope.PaginationModel.Search = "";
            $scope.PaginationModel.PageSize = $scope.configurationModel.PageSize;
            $scope.PaginationModel.StartNodeValue = $scope.configurationModel.StartNodeValue;
            $scope.PaginationModel.IncludeSubFolders = $scope.configurationModel.IncludeSubFolders;
            $scope.PaginationModel.ViewDoc = $scope.configurationModel.ViewDoc;

            $scope.docListOptions = {
                pageable: {
                    refresh: true
                },
                change: $scope.onChange,
                selectable: true,
                scrollable: false,
                sortable: {
                    mode: "single"
                },
                toolbar: kendo.template($("#templateDocMan").html()),
                dataSource: new kendo.data.DataSource({
                    pageSize: $scope.PaginationModel.PageSize,
                    serverPaging: true,
                    serverSorting: true,
                    transport: {
                        read: function (options) {

                            $scope.PaginationModel.StartIndex = options.data.skip;

                            if (options.data.sort && options.data.sort.length > 0) {
                                $scope.PaginationModel.Order = options.data.sort[0].dir;
                            }

                            if ($scope.PaginationModel.SelectedNodeValue || $scope.PaginationModel.StartNodeValue) {

                                DocManListService.getDocumentManagementList($scope.PaginationModel,$scope.portlet.Id)
                                .then(function (result) {
                                    options.success(result.data);                               
                                }).catch(function (error) {
                                    options.error(error);
                                })                    
                            } else {
                                options.success({ Documents: [], Total: 0 });
                            }
                        }
                    },
                    schema: { data: "Documents", total: "Total" }
                }),
                columns: [
                    { field: "Title", title: translationFlowClientService.get('SearchInDocumentTitle'), template: kendo.template($("#cellImgTemplate").html()) }
                ]
            };

            if ($scope.PaginationModel.ViewDoc) {
                $scope.docListOptions.columns.push({ command: { text: translationFlowClientService.get('View'), click: $scope.viewDocument }, title: " ", width: "50px" });
            }
        };

        $scope.$on('updateDocumentManagementList', function (event, value) {
            if (receiverService.canReceive($scope.portlet, "updateDocumentManagementList", value)) {
                $scope.PaginationModel.SelectedNodeValue = value.SelectedNodeValue;
                $scope.docListOptions.dataSource.read();
            }
        });

        $scope.search = function () {
            $scope.docListOptions.dataSource.read();
        }

        $scope.refresh = function () {
            $scope.docListOptions.dataSource.read();
            $rootScope.$broadcast('onRefresh', { SenderId: $scope.portlet.Id });
        }

        $scope.viewDocument = function (e) {

            e.preventDefault();
            var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

            var dataToSend = {};
            dataToSend.Id = dataItem.Id;

            DocManListService.getDocument(dataToSend, {}, $scope.portlet.Id)
                .then(function (result) {
                    var file = new Blob([result.data], { type: dataItem.MimeType });
                    var fileUrl = URL.createObjectURL(file);

                    if (msieVersionService.isMS()) {
                        saveAs(file);
                    } else {
                        if (dataItem.DocumentType == "pdf" || dataItem.DocumentType == "image") {
                            var x = screen.width / 2 - 1200 / 2;
                            window.open(fileUrl, '_blank', 'height=900,width=1200,left=' + x + ',top=25');
                        } else {
                            window.open(fileUrl, '_self', false);
                        }
                    }
                }).catch(angular.noop);
        };
        $scope.onChange = function (arg) {
            var currentDataItem = arg.sender.dataItem(this.select());
            $rootScope.$broadcast('updateDocument', { SenderId: $scope.portlet.Id, Id: currentDataItem.Id });
        };

        $scope.initialize();
    }
]);

