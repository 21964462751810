app.controller('Obsolete_CreateOrEditPortletController', [
    '$scope', '$uibModalInstance', 'createOrEditModalService', 'newsViewerConfigurationService', 'propertyService', 'data', function ($scope, $uibModalInstance, createOrEditModalService, newsViewerConfigurationService, propertyService, data) {

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.model = createOrEditModalService.getModel("Html", data);

        $scope.ok = function () {
            $uibModalInstance.close($scope.model);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }])