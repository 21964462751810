app.controller('Iframe_CreateOrEditPortletController', ['$scope', '$uibModalInstance', 'createOrEditModalService', 'iframeConfigurationService', 'data', function ($scope, $uibModalInstance, createOrEditModalService, iframeConfigurationService, data) {

    $scope.model = createOrEditModalService.getModel("Iframe", data);
    $scope.configurationModel = iframeConfigurationService.getConfigurationModel($scope.model.Properties);
    $scope.panelBarOptions = {
        expandMode: "single",
        select: $scope.expandCollapse
    };

    $scope.ok = function () {
        iframeConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}])