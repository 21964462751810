app.factory('genericTreeConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);
        configurationModel.ParentNode = propertyService.getPropertyValue(properties, "parentNode", "");
        configurationModel.Node = propertyService.getPropertyValue(properties, "node", "");
        configurationModel.Title = propertyService.getPropertyValue(properties, "title", "");
        configurationModel.StartNodeValue
                                = propertyService.getPropertyValue(properties, "startNodeValue", "");
        configurationModel.HasChildren
            = propertyService.getPropertyValue(properties, "hasChildren", "");
        configurationModel.DisplayType
            = propertyService.getPropertyValue(properties, "displayType", "Node");

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {
        configurationService.saveProperties(properties, configurationModel);
        propertyService.setProperty(properties, "startNodeValue", configurationModel.StartNodeValue);
        propertyService.setProperty(properties, "parentNode", configurationModel.ParentNode);
        propertyService.setProperty(properties, "node", configurationModel.Node);
        propertyService.setProperty(properties, "title", configurationModel.Title);

        if (configurationModel.HasChildren) {
            propertyService.setProperty(properties, "hasChildren", configurationModel.HasChildren);
        }
        else {
            propertyService.removeProperty(properties, "hasChildren");
        }

        propertyService.setProperty(properties, "displayType", configurationModel.DisplayType);
    }

    return service;
}]);