app.service('ChartService', ['basePluginService', '$http', 'appConfig', '$q',
    function (basePluginService, $http, appConfig, $q) {

        angular.extend(this, basePluginService);

        var service = {};

        service.getData = function (model, portletId) {
            return basePluginService.backend.post('queryExecutionHeader', model, portletId);
        };  
        service.getChartData = function (paginationModel, portletId) {
            return basePluginService.backend.post('queryExecution', paginationModel, portletId);
        };


        return service;


    }]);