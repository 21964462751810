app.controller("ProcessOverviewController",[
    "$scope", "$rootScope", "ProcessOverviewService", "$uibModal", "propertyService", "receiverService", "flowService",
    "editorService", "portletErrorService", "variableService", "processOverviewConfigurationService",
    "translationsService", "dataEvalService", "appConfig", "imageService", "$controller", 
    function($scope,
        $rootScope,
        ProcessOverviewService,
        $uibModal,
        propertyService,  
        receiverService,
        flowService,
        editorService,
        portletErrorService,
        variableService,
        processOverviewConfigurationService,
        translationsService,
        dataEvalService, appConfig, imageService, $controller ) {

        $controller('BasePluginController', { $scope: $scope });

        $scope.configurationModel = processOverviewConfigurationService
            .getConfigurationModel($scope.portlet.Properties);
        $scope.controllingModel = processOverviewConfigurationService.getControllingModel();

        $scope.variableService = variableService;


        $scope.selectionModel = {};
        $scope.selectionModel.selectedRows = [];
            

        /* Workflows */
        $scope.flowService = flowService;

        $scope.$on("kendoWidgetCreated",
            function(event, widget) {
                if (widget.options && widget.options.bookmark) {

                    angular.forEach($scope.configurationModel.LaneModel.lanes,
                        function(lane) {
                            if (lane.gridOptions) {
                                if (widget.options.bookmark === lane.gridOptions.bookmark) {

                                    lane.widgetReference = widget;

                                    if ($scope.configurationModel.WorkFlowModel.workFlowDoubleClick) {
                                        jQuery(widget.element)
                                            .mousedown(function(e) {
                                                if (!(widget.element.find(".k-grid-edit-row").length)) {
                                                    e.preventDefault();
                                                }
                                            });

                                        widget.element.on("dblclick",
                                            function() {
                                                $scope.configurationModel.WorkFlowModel.rowDblClick(lane
                                                    .Id);
                                            });
                                    }
                                }
                            }
                        });

                }
            });

        $scope.tooltip = {
            filter: ".processOverviewCard",
            showAfter: 750,
            position: "center",
            show: function(e) {
                this.popup.element[0].classList.add("processOverviewTooltip");
            },
            content: function(e) {
                var grid = e.target.closest(".tableCardList").getKendoGrid();
                var dataItem = grid.dataItem(e.target.closest("tr"));

                var result = translationsService.get($scope.configurationModel.CardDefinition.Code + "-tooltip",
                    $scope.configurationModel.CardDefinition.tooltip);


                result = $scope.createDisplay(dataItem, result);

                result = result.replace(new RegExp("\n", "g"), "<br/>");

                return result;
            }
        };


        $scope.configurationModel.WorkFlowModel.rowDblClick = function(laneId) {
            $scope.configurationModel.WorkFlowModel.callDblClick($scope.selectionModel.selectedRows[0], laneId);
        };

        $scope.configurationModel.WorkFlowModel.callDblClick = function(data, laneId) {
                
            var lane = _.find($scope.configurationModel.LaneModel.lanes, function (x) { return x.Id === laneId; });

            if ($scope.hasWorkflow($scope.configurationModel.WorkFlowModel.workFlowDoubleClick)) {
                if ($scope.configurationModel.WorkFlowModel.dblClickUserActions) { //we need to put args into object as below
                    $scope.flowService.runFlowUserAction($scope,
                        $scope.configurationModel.WorkFlowModel.workFlowDoubleClick.Id, //change name
                        [data],
                        undefined,
                        appConfig.WorkflowOperationType.DBL_CLICK_WORKFLOW,
                        lane.PaginationModel.RowFilter,
                        $scope.refresh,
                        $scope.configurationModel.WorkFlowModel.workFlowDoubleClick.RootElementName,
                        $scope.portlet.InternalName);
                } else {
                    var config = {
                        workflowIdentifier: $scope.configurationModel.WorkFlowModel.workFlowDoubleClick.Id, //change name
                        dataObject: [data],
                        callType: appConfig.WorkflowOperationType.DBL_CLICK_WORKFLOW,
                        onSuccessAction: $scope.refresh,
                        onErrorAction: function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                        },
                        filterDataObject: lane.PaginationModel.RowFilter,
                        dataDefinitions: undefined,
                        portletInternalName: $scope.portlet.InternalName
                    };
                    $scope.flowService.callFlowWithTableData(
                       $scope.configurationModel.WorkFlowModel.workFlowDoubleClick.RootElementName,
                        config);
                }
            }
            $scope.controllingModel.DisplayLoadingBar = false;


        };

        $scope.hasWorkflow = function(workflowObj) {
            if (workflowObj) {
                if (workflowObj !== appConfig.TablePlugin.NO_WORKFLOW) {
                    return true;
                }
            }
            return false;
        };
        /* Workflows END*/

        $scope.createFields = function(columns) {
            var fields = {};

            angular.forEach(columns, function (x) {
                fields[x.ColumnCode] = {
                    type: "string"
                };
            });

            return fields;
        };

        $scope.createDisplay = function(dataItem, displayDefinition) {

            if (displayDefinition) {
                var parsedDisplay = displayDefinition;
                return $scope.variableService.variableReplacer(parsedDisplay, dataItem);
            }
            return "";
        };

        $scope.orderedColorRules = _.sortBy($scope.configurationModel.CardDefinition.colours, [function (rule) { return rule.Position; }]);

        $scope.ruleIsMatching = function(dataItem, rule) {
            return dataEvalService.evaluateExpression(
                dataEvalService.createNewDataSet(dataItem),
                rule.FirstValue,
                rule.Func,
                rule.SecondValue);
        };

        $scope.orderedIconRules = _.sortBy($scope.configurationModel.CardDefinition.icons, [function (rule) { return rule.Position; }]);

		$scope.calculateColor = function (dataItem) {
			for (var i = 0; i < $scope.orderedColorRules.length; i++) {
				var currentRule = $scope.orderedColorRules[i];
			    if (dataItem) {
				    if ($scope.ruleIsMatching(dataItem, currentRule)) {
					    return currentRule.Colour;
				    }
			    }
		    }
		    return $scope.configurationModel.CardDefinition.defaultColor;
	    };



        $scope.calculateIcon = function(dataItem) {

	        var icon = undefined;
            angular.forEach($scope.orderedIconRules, function (currentRule) {
                if ($scope.ruleIsMatching(dataItem, currentRule)) {
                    icon= currentRule.Icon;
                }
            });
	        if (icon)
		        return icon;
			else
				return $scope.configurationModel.CardDefinition.defaultIcon;
        };
        $scope.getTemplateRow = function(dataItem, displayName, data) {
            if (!$scope.cardRightColumnWidth) {
                $scope.cardRightColumnWidth = 100 - $scope.configurationModel.CardDefinition.leftColumnWidthPercent;
            }
            if (displayName || data) {
                return "<tr>" +
                    "<td class='displayNameCell' style='width: " +
                    $scope.configurationModel.CardDefinition.leftColumnWidthPercent +
                    "%;'>" +
                    "<span class='rowName'>" +
                    $scope.createDisplay(dataItem, displayName) +
                    "</span>" +
                    "</td>" +
                    "<td class='dataCell' style='width: " +
                    $scope.cardRightColumnWidth +
                    "%;'>" +
                    "<span class='rowData'>" +
                    $scope.createDisplay(dataItem, data) +
                    "</span>" +
                    "</td>" +
                    "</tr>";
            }
            return "";
        };
        $scope.getTemplate = function() {
            return function(dataItem) {

                var template = "<div class='processOverviewCard' style='height: " +
                    $scope.configurationModel.CardDefinition.cardHeightPixels +
                    "px'>";

                template += "<div class='leftSideColorDiv' style='background-color: " +
                    $scope.calculateColor(dataItem) +
                    " !important;'>&nbsp;</div>";

                template += "<div class='centerDataHolderDiv'>" +
                    "<table>";

                template += $scope.getTemplateRow(dataItem,
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowHeader-displayName",
                        $scope.configurationModel.CardDefinition.dataRowHeader.displayName),
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowHeader-data",
                        $scope.configurationModel.CardDefinition.dataRowHeader.data));

                template += $scope.getTemplateRow(dataItem,
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowOne-displayName",
                        $scope.configurationModel.CardDefinition.dataRowOne.displayName),
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowOne-data",
                        $scope.configurationModel.CardDefinition.dataRowOne.data));

                template += $scope.getTemplateRow(dataItem,
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowTwo-displayName",
                        $scope.configurationModel.CardDefinition.dataRowTwo.displayName),
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowTwo-data",
                        $scope.configurationModel.CardDefinition.dataRowTwo.data));

                template += $scope.getTemplateRow(dataItem,
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowThree-displayName",
                        $scope.configurationModel.CardDefinition.dataRowThree.displayName),
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowThree-data",
                        $scope.configurationModel.CardDefinition.dataRowThree.data));

                template += $scope.getTemplateRow(dataItem,
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowFour-displayName",
                        $scope.configurationModel.CardDefinition.dataRowFour.displayName),
                    translationsService.get($scope.configurationModel.CardDefinition.Code + "-dataRowFour-data",
                        $scope.configurationModel.CardDefinition.dataRowFour.data));

                //Close centercell and the table in it.
                template += "</table>" +
                    "</div>";

                template += "<div class='rightSideDiv'>";


                var iconToUse = $scope.calculateIcon(dataItem);
                var topMargin = "0";
                if (iconToUse) {
                    var src = imageService.getImageInSize(iconToUse, 48);
                    template += '<div><img class="processOverviewCardIconImg" ng-src="' + src + '"/></div>';
                    topMargin = "48px";
                }
                if ($scope.configurationModel.CardDefinition.bottomRightValueDef) {
                    template +=
                        "<div class='bottomRightValueDivWrapper'><div class='bottomRightValueDiv'  style='top:" +
                        topMargin +
                        ";'><span>" +
                        $scope.createDisplay(dataItem,
                            translationsService.get($scope.configurationModel.CardDefinition.Code +
                                "-bottomRightValueDef",
                                $scope.configurationModel.CardDefinition.bottomRightValueDef)) +
                        "</span></div></div>";
                }


                //Close rightmost image and number cell
                template += "</div>";

                //Close the outermost tablerow and table.
                template += "</div>";
                    
                return template;
            };
        };

        $scope.getLaneContainerClasses = function(first) {
            var classList = "laneContainerParent";
            var laneSize = 1;
            var laneOffset = undefined;
            var lanesCount = $scope.configurationModel.LaneModel.lanes.length;
            if (12 % lanesCount > 0) {
                var remainder = 12 % lanesCount;
                laneSize = (12 - remainder) / lanesCount;
                if (remainder & 2 > 0) {
                    laneOffset = (remainder - 1) / 2;
                } else {
                    laneOffset = remainder / 2;
                }
            } else {
                laneSize = 12 / lanesCount;
            }


            classList += " col-xs-" + laneSize;

            if (laneOffset && first) {
                classList += " col-xs-offset-" + laneOffset;
            }

            return classList;
        };
        $scope.gridHeightStyle = function() {
            var cards = $scope.configurationModel.cardsShown;

            return {
                height: cards * ($scope.configurationModel.CardDefinition.cardHeightPixels + 4),
                'min-width': (parseInt($scope.configurationModel.LaneWidth) + 18) + "px"
            };
        };


        $scope.initialize = function() {

            angular.forEach($scope.configurationModel.LaneModel.lanes,
                function(lane) {
                    lane.PaginationModel = {};
                    lane.PaginationModel.Search = [];
                    lane.PaginationModel.QueryId = lane.QueryId;
                    lane.PaginationModel.DataSourceType = $scope.portlet.DataSourceType;
                    lane.PaginationModel.RowFilter = {};
                    if (lane.SortColumn && lane.SortColumn.length > 0) {
                        lane.PaginationModel.Sort = {};
                        lane.PaginationModel.Sort.Code = lane.SortColumn;
                        lane.PaginationModel.Sort.Order = lane.SortDirection;
                    } else {
                        lane.PaginationModel.Sort = {};
                    }
                    lane.PaginationModel.Group = {};

             

                    lane.headerStyle = {
                        "background-color": lane.HeaderBgColour,
                        color: lane.HeaderColour
                    };
                    ProcessOverviewService.getQueryExecutionHeader(lane.PaginationModel, $scope.portlet.Id)
                    .then(function (result) {
                            $scope.displayLoadingBar = false;
                            if (result.data.Headers.length > 0) {
                                lane.gridOptions = {
                                    bookmark: lane.Position,                          
                                    scrollable: {
                                        virtual: true
                                    },
                                    change: $scope.onChange,
                                    selectable: true,
                                    dataSource: new kendo.data.DataSource({
                                        pageSize: $scope.configurationModel.pageSize,
                                        transport: {
                                            read: function(options) {

                                                lane.PaginationModel.StartIndex = options.data.skip;
                                                lane.PaginationModel.PageSize = options.data.take;
                                                lane.PaginationModel.PageNumber = options.data.page;

                                                ProcessOverviewService
                                                    .processOverviewQueryExecution(lane.PaginationModel,
                                                        $scope.portlet.Id).then(function(result) {
                                                        options.success(result.data);
                                                    }).catch(function(error) {
                                                        options.error(error);
                                                    }).finally(function() {
                                                        $scope.controllingModel.DisplayLoadingBar = false;
                                                    });
                                            }
                                        },
                                        schema: {
                                            data: "Rows",
                                            total: "Total",
                                            groups: "Groups",
                                            model: {
                                                id: "rnum",
                                                fields: $scope.createFields(result.data.Headers)
                                            }
                                        },
                                        serverPaging: true,
                                        serverFiltering: true,
                                        serverSorting: true,                               
                                    }),
                                    columns: [
                                        {
                                            title: "Card",
                                            width: $scope.configurationModel.LaneWidth + "px",
                                            template: $scope.getTemplate()
                                        }
                                    ]
                                };
                            }
                    }).catch(angular.noop);
                });

        };

        $scope.ignoreChangeEventOn = [];

        $scope.onChange = function(arg) {
            if (arg.sender.options.bookmark) {

                var ignoreIndex = $scope.ignoreChangeEventOn.indexOf(arg.sender.options.bookmark);
                if (ignoreIndex >= 0) {
                    $scope.ignoreChangeEventOn.splice(ignoreIndex, 1);
                    return;
                }

                /* Broadcast selected row, if not multiSelect is on - since the receivers dont support multiline selection*/
                //if (!$scope.selectionModel.multiSelect) {
                var currentDataItem = arg.sender.dataItem(this.select());
                $rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: currentDataItem });
                //}
                /* #### */

                /* Arrange a complete selection for the selection model - used by among other things custom flows*/


                angular.forEach($scope.configurationModel.LaneModel.lanes,
                    function(lane) {
                        if (arg.sender.options.bookmark !== lane.Position) {
                            $scope.ignoreChangeEventOn.push(lane.Position);
                            lane.widgetReference.clearSelection();
                        }
                    });

                var fullSelection = arg.sender.select();
                var currentDataItems = [];
                angular.forEach(fullSelection,
                    function(fullRowObject) {
                        currentDataItems.push(arg.sender.dataItem(fullRowObject));
                    });

                $scope.selectionModel.selectedRows = currentDataItems;
                $scope.$digest();
                /* #### */
            }


        };

        $scope.$on("onFilter",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
                    angular.forEach($scope.configurationModel.LaneModel.lanes,
                        function(lane) {
                            lane.PaginationModel.Search = [];

                            angular.forEach(data.Data, function (searchPredicate) {
                                lane.PaginationModel.Search.push(searchPredicate);
                            });
                        });
                    $scope.refresh();
                }
            });

        $scope.refresh = function() {
            angular.forEach($scope.configurationModel.LaneModel.lanes,
                function(lane) {
                    lane.gridOptions.dataSource.read();
                });
            $rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });

        };

        $scope.$on("onRefresh",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                    $scope.refresh();
                }
            });

        $scope.newRowFilter = function(rowFilter) {
            angular.forEach($scope.configurationModel.LaneModel.lanes,
                function(lane) {

                    lane.PaginationModel.RowFilter = rowFilter;
                });

            $scope.refresh();

        };

        $scope.$on("onRowSelected",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                    $scope.newRowFilter(data.Data);
                }
            });


        $scope.initialize();


    }
]);
