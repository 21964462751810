app.service('GenericDocumentViewerService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getDocument = function (DocumentViewerViewModel,portletId) {      
            return basePluginService.backend.postWithHeaders("genericDocumentViewer", DocumentViewerViewModel, {}, portletId, true);
        };

        return service;
    }]);