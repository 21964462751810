app.service('NewsViewerService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};
        
        service.getNewsViewer = function (postId, portletId) {
            return basePluginService.backend.get("getNewsViewer/"+postId, portletId);
        };

        service.readNews = function (paginationModel, portletId) {
            return basePluginService.backend.post("newsViewer/", paginationModel, portletId);
        };

        service.addComment = function (comment, portletId) {
            return basePluginService.backend.post("action/comment/", comment, portletId);
        };

        service.deleteComment = function (commentId, portletId) {
            return basePluginService.backend.delete("action/deleteComment/"+ commentId, portletId);            
        };

        return service;

    }]);