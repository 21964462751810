app.controller('Chart2_CreateOrEditPortletController',
[
    '$scope', '$uibModalInstance', '$rootScope', 'createOrEditModalService', 'chart2ConfigurationService', 'data',
    'suggestionService', 'editorService', 'connectorService', 'Guid', 'dataEvalService', 'kendoGridUtilService',
    function ($scope,
        $uibModalInstance,
        $rootScope,
        createOrEditModalService,
        chart2ConfigurationService,
        data,
        suggestionService,
        editorService,
        connectorService,
        Guid,
        dataEvalService,
        kendoGridUtilService) {

        $scope.connectorService = connectorService;
        $scope.editorService = editorService;

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.model = createOrEditModalService.getModel("Chart2", data);
        $scope.configurationModel = chart2ConfigurationService.getConfigurationModel($scope.model.Properties);
        $scope.querySuggestions = suggestionService.getSuggestions();

        

        $scope.seriesOptions = {
            editable: true,
            scrollable: false,
            toolbar: [{ name: "create", text: "Add" }],
            columns: [
                { field: "DisplayName", title: "Display Name", width: "200px" },
                { field: "Field", title: "Field", width: "210px" },
                { field: "Category", title: "Category", width: "210px" },
                { field: "Type", title: "Type", width: "100px", editor: kendoGridUtilService.makeKendoComboBoxFromList(chart2ConfigurationService.PluginsConstants.ChartTypes, 'Name', 'Name'), template: kendoGridUtilService.getListTemplate(chart2ConfigurationService.PluginsConstants.ChartTypes, 'Name', 'Name', 'Type') },
                {
                    field: "SerieColour",
                    title: "Serie colour",
                    width: "50px",
                    editor: kendoGridUtilService.getColorPicker(),
                    template: kendoGridUtilService.getColorTemplate("SerieColour")
                },
                {
                    command: [
                        {
                            name: "up",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='groupUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
                        },
                        {
                            name: "down",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='groupDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
                        },
                        {
                            name: "destroy",
                            template:
                                "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                        }
                    ],
                    width: 24
                }
            ],

            dataSource: new kendo.data.DataSource({
                data: $scope.configurationModel.ChartModel.SeriesDefinitions,
                autoSync: true,
                transport: {
                    read: function(o) {
                        o.success($scope.configurationModel.ChartModel.SeriesDefinitions);
                    },
                    create: function(o) {
                        var item = o.data;
                        item.Id = $scope.configurationModel.ChartModel.SeriesDefinitions.length + 1;
                        item.Code = Guid.newGuid();
                        item.Type = 'column';
                        $scope.configurationModel.ChartModel.SeriesDefinitions.unshift(item);
                        o.success(item);
                    },
                    update: function(o) {
                        o.success();
                    },
                    destroy: function(o) {
                        for (var index = 0;
                            index < $scope.configurationModel.ChartModel.SeriesDefinitions.length;
                            index++) {
                            if ($scope.configurationModel.ChartModel.SeriesDefinitions[index].Id == o.data.Id) {
                                $scope.configurationModel.ChartModel.SeriesDefinitions.splice(index, 1);
                                break;
                            }
                        }
                        o.success();
                    }
                },
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            DisplayName: {
                                type: "string",
                                validation: {
                                    required: { message: "Field is required"} }
                            },
                            Field: {
                                type: "string",
                                validation: {
                                    required: { message: "Field is required"} }
                            },
                            Category: {
                                type: "string",
                                validation: {
                                    required: { message: "Field is required"} }
                            },
                            Type: {
                                type: "string",
                                validation: {
                                    required: { message: "Field is required"} }
                            },
                            SerieColour: {
                                type: "string"
                            }
                        }
                    }
                }
            })
        };

        editorService.createColumnNameComboBox($scope.seriesOptions.columns[1], $scope.model);
        editorService.createColumnNameComboBox($scope.seriesOptions.columns[2], $scope.model);

        $scope.groupUp = function (e) {      
            for (var p = 0; p < $scope.seriesGrid.dataSource.data().length; p++) {
                $scope.seriesGrid.dataSource.data()[p].Position = p;
            }

            var dataItem = $scope.seriesGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.seriesGrid.dataSource.data();
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            if (index > 0) {
                $scope.seriesGrid.dataSource.remove(dataItem);
                $scope.seriesGrid.dataSource.insert(index - 1, dataItem);
            }
            $scope.seriesGrid.dataSource.sync();
        };

        $scope.groupDown = function (e) {
            for (var p = 0; p < $scope.seriesGrid.dataSource.data().length; p++) {
                $scope.seriesGrid.dataSource.data()[p].Position = p;
            }
            var dataItem = $scope.seriesGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.seriesGrid.dataSource.data();

            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            $scope.seriesGrid.dataSource.remove(dataItem);
            $scope.seriesGrid.dataSource.insert(index + 1, dataItem);
            $scope.seriesGrid.dataSource.sync();
        };


        $scope.validateDataSource = function () {

            if (!$scope.model.DataSourceType) {
                return false;
            }
            if ($scope.model.DataSourceType === 'Workflow') {
                if (!$scope.portletConfigurationForm.outputVariable) {
                    return false;
                }
                return $scope.model.Workflows.length > 0;
            }
            if ($scope.model.DataSourceType === 'Database') {
                if (!$scope.model.Queries[0].ConnectorName) {
                    return false;
                }
            }
            return true;
        };

        $scope.ok = function () {               

            $scope.configurationModel.ChartModel.SeriesDefinitions = [];

            var seriesConfigurations = $scope.seriesGrid.dataSource.data();
            for (var p = 0; p < seriesConfigurations.length; p++) {
                $scope.configurationModel.ChartModel.SeriesDefinitions.push(seriesConfigurations[p]);
            }

            chart2ConfigurationService.saveProperties($scope.model.Properties,$scope.configurationModel, data.operation);

            $uibModalInstance.close($scope.model);
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        //Chart 2.0
        $scope.initialize = function () {
            $scope.createWorkflowTypes = [{
                    "type": "dsWithPagingAndFiltering",
                    "displayName": "Data Source with paging and filtering",
                }
            ];
        }();

    }
]);
