app.factory('docManListConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        configurationModel.PageSize = parseInt(propertyService.getPropertyValue(properties, "pageSize", "10"));
        configurationModel.IncludeSubFolders = propertyService.getBoolPropertyValue(properties, "includeSubFolders", true);
        configurationModel.ViewDoc = propertyService.getBoolPropertyValue(properties, "viewDoc", false);

        var snv = propertyService.getPropertyValue(properties, "startNodeValue", "");
        if (snv) {
            configurationModel.StartNodeValue = parseInt(snv);
        }

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {

        configurationService.saveProperties(properties, configurationModel);

        propertyService.setProperty(properties, "pageSize", configurationModel.PageSize.toString());

        if (configurationModel.StartNodeValue) {
            propertyService.setProperty(properties, "startNodeValue", configurationModel.StartNodeValue.toString());
        }
        else
        {
            propertyService.removeProperty(properties, "startNodeValue");
        }

        propertyService.setProperty(properties, "includeSubFolders", configurationModel.IncludeSubFolders);
        propertyService.setProperty(properties, "viewDoc", configurationModel.ViewDoc);
    }

    return service;
}]);