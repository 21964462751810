app.controller('FlowApplicationTasksController', [
    '$scope', '$rootScope',
    'FlowApplicationTasksService','FlowApplicationTasksConfigurationService',
    'translationsService','sharedSessionService',
    'receiverService',
    '$controller',
    'appConfig',
    '$uibModal',
    function ($scope,$rootScope,
        FlowApplicationTasksService,FlowApplicationTasksConfigurationService,
        translationsService,sharedSessionService,
        receiverService,
        $controller,
        appConfig,
        $uibModal) {

        $controller('BaseTableController', { $scope: $scope });
        $controller('TableWorkflowExecutionController', { $scope: $scope });
        
        $scope.selectionModel = {};
        $scope.selectionModel.selectedRows = [];
        $scope.selectionModel.emptySelection = true;
        $scope.width = 0;
        $scope.shouldApplyWidth = false;
        
        $scope.$on("onRefresh",
            function (event, data) {
                if (receiverService.canReceiveAnyCommand($scope.portlet, data)) {
                    $scope.refresh();
                }
            });

        $scope.createToolbar = function () {
            var toolbar = [];
            angular.forEach($scope.configurationModel.WorkFlowModel.FlowConfigurations,
                function (flowConfig) {

                    if (flowConfig.CustomFlow) {

                        var datasetName = null;

                        if (flowConfig.DatasetName)
                            datasetName = flowConfig.DatasetName;

                        this.push({
                            Id: flowConfig.CustomFlow.Id,
                            text: flowConfig.DisplayName,
                            template: '<button ng-click="runCustomFlow(\'' +
                                flowConfig.CustomFlow.Id +
                                "', " +
                                flowConfig.UserActions +
                                ",'" +
                                datasetName +
                                "'" +
                                ')" class="k-button k-button-icontext k-grid-export" ng-disabled="customFlowDisabled(' +
                                flowConfig.Id +
                                ')">' +
                                translationsService.get(flowConfig.Code + "-DisplayName", flowConfig.DisplayName) +
                                "</button>"
                        });
                    }
                },
                toolbar);

            if ($scope.configurationModel.Exportable) {
                toolbar.push({
                    name: "export",
                    template: '<button ng-click="export()" class="k-button k-button-icontext k-grid-export">' +
                        translationsService.get("Export_Button") +
                        "</button>"
                });
            }

            toolbar.push({
                name: "filterCheckox",
                template: '<input id="{{archiveCheckboxKey}}" ng-change="filterCheckoxChecked()" style="margin-left:5px;margin-right:5px;" ng-model="PaginationModel.IncludeArchived" type="checkbox" class="k-checkbox">' +
                    '<label for="{{archiveCheckboxKey}}" class="k-checkbox-label">Include archive</label>'
            });


            toolbar.push({
                name: "filterCheckox",
                template: '<input id="{{finishedCheckboxKey}}" ng-change="filterCheckoxChecked()" style="margin-left:5px;margin-right:5px;"  ng-model="PaginationModel.IncludeFinished" type="checkbox" class="k-checkbox">' +
                    '<label for="{{finishedCheckboxKey}}" class="k-checkbox-label">Include finished</label>'
            });


            toolbar.push({
                name: "Kill",
                template:
                    '<button ng-click="killProcess()"  ng-if="configurationModel.EnableOperations" style="margin-left:5px;margin-right:5px;" ng-disabled="killButtonDisabled()" class="k-button killTaskButton">' +
                        "Kill process" +
                        "</button>"
            });
            toolbar.push({
                name: "Reassign",
                template:
                    '<button ng-click="reassignProcess()" ng-if="configurationModel.EnableOperations"  ng-disabled="reassingButtonDisabled()"  class="k-button reassignTaskButton">' +
                        "Reassign" +
                        "</button>"
            });


            return toolbar;
        };
        
        $scope.search = function () {
            $scope.gridOptions.dataSource.read();
        };

        $scope.newRowFilter = function (rowFilter) {
            if ($scope.gridOptions) {
                $scope.PaginationModel.RowFilter = rowFilter;
                $scope.refreshSilently();
            } else {
                $scope.initialize(rowFilter);
            }
        };

        $scope.refreshSilently = function () {
            $scope.gridOptions.dataSource.read();
        };

        $scope.newRowFilter = function (rowFilter, calculatedProcessIdentityFilter) {

            $scope.selectionModel.selectedRows = [];
            $scope.selectionModel.emptySelection = true;

            if ($scope.gridOptions) {

                if (calculatedProcessIdentityFilter) {
                    $scope.PaginationModel.CalculatedProcessIdentityFilter = calculatedProcessIdentityFilter;
                }

                $scope.PaginationModel.RowFilter = rowFilter;
                $scope.refreshSilently();
            } else {
                $scope.initialize(rowFilter);
            }
        };

        $scope.$on("onRowSelected",
            function (event, data) {
                if (receiverService.canReceiveAnyCommand($scope.portlet, data)) {
                    var processId = data.Data.__id;
                    $scope.newRowFilter(data.Data, processId);


                }
            });
        
        $scope.killButtonDisabled = function () {
            if ($scope.selectionModel.emptySelection) {
                return true;
            }
            return false;
        };

        $scope.reassingButtonDisabled = function() {

            if ($scope.selectionModel.emptySelection)
                return true;

            var selectedRow = $scope.selectionModel.selectedRows[0];

            if (selectedRow) {

                if (selectedRow.Status === "Done" || selectedRow.Status === "Completed")
                    return true;
                else
                    return false;
            }
        };


        $scope.killProcess = function () {

            if ($scope.selectionModel.selectedRows.length > 0) {

                var selectedRow = $scope.selectionModel.selectedRows[0];

                var taskModel = {
                    HandoverIdentity: selectedRow.HandoverIdentity,
                    ProcessIdentity: selectedRow.ProcessIdentity
                };

                $scope.controllingModel.DisplayLoadingBar = true;

                FlowApplicationTasksService.removeTask(taskModel, $scope.portlet.Id).then(function () {
                }).finally(function () {
                    $scope.controllingModel.DisplayLoadingBar = false;
                    $scope.refreshSilently();
                });

            }
        };
        $scope.reassignProcess = function () {

            if ($scope.selectionModel.selectedRows.length > 0) {

                var selectedRow = $scope.selectionModel.selectedRows[0];

                var modalInstance = $uibModal.open({
                    templateUrl: $.pluginsDir + "/FlowApplicationTasks/ReassignTask.html",
                    controller: "ReassignTaskController",
                    backdrop: "static",
                    keyboard: false,
                    resolve: {
                        params: function () {
                            return {
                                PortletId: $scope.portlet.Id,
                                HandoverIdentity: selectedRow.HandoverIdentity
                            };
                        }
                    }
                });
                modalInstance.result.then(function () {
                    $scope.refreshSilently();
                }, function () {
                    //handle popup dismiss on cancel
                }).catch(angular.noop);
            }
        };


        $scope.getTableOptions = function (result) {

            var toolBarContent = $scope.createToolbar();
            
            return {
                toolbar: toolBarContent.length ? toolBarContent : undefined,
                editable: false,
                groupable: $scope.configurationModel.Groupable,
                navigatable: true,
                pageable: {
                    refresh: true
                },
                columnMenu: true,
                dataBinding: $scope.onDataBinding,
                dataBound: $scope.onDataBound,
                columnResize: $scope.onColumnResize,
                columnReorder: $scope.onColumnReorder,
                change: $scope.onChange,
                selectable: $scope.configurationModel.MultiSelect ? "multiple" : true,
                resizable: $scope.configurationModel.Resizable,
                reorderable: $scope.configurationModel.Reorderable,
                columnShow: $scope.onColumnShow,
                columnHide: $scope.onColumnHide,
                sortable: true,
                filterable:false,
                dataSource: new kendo.data.DataSource({
                    pageSize: $scope.PaginationModel.PageSize,
                    batch: true,

                    transport: {
                        read: function (transportOptions) {

                            if ($scope.searchText)
                                $scope.PaginationModel.StringFieldsFilter = $scope.searchText;
                            else
                                $scope.PaginationModel.StringFieldsFilter = undefined;

                            $scope.PaginationModel.PageNumber = transportOptions.data.page;
                            $scope.setPaginationSort($scope.PaginationModel,
                                transportOptions,
                                $scope.configurationModel);

                            if (transportOptions.data.group && transportOptions.data.group.length === 2) {
                                transportOptions.success([]);
                            } else {
                                $scope.setPaginationGroup($scope.PaginationModel, transportOptions);

                                //check conditions before loading data     
                                if ($scope.portlet.Senders === null || $scope.portlet.Senders.length === 0 || $scope.PaginationModel.CalculatedProcessIdentityFilter) {

                                    if ($scope.portlet.Senders === null || $scope.portlet.Senders.length === 0)
                                        $scope.PaginationModel.AssigneeIdentityFilter = sharedSessionService.getUser();

                                    FlowApplicationTasksService.getApplicationTasks($scope.PaginationModel, $scope.portlet.Id)
                                       .then(function (result) {
                                            transportOptions.success(result.data);
                                       }).catch(function (error) {
                                            transportOptions.error(error);
                                       })
                                       .finally(function () {
                                           $scope.controllingModel.DisplayLoadingBar = false;
                                       }
                                       );

                                } else {
                                    transportOptions.success([]);
                                }
                            }
                        }
                    },
                    schema: {
                        data: "Rows",
                        total: "Total",
                        groups: "Groups",
                        model: {
                            id: "id",
                            fields: $scope.createFields(result.data.Headers)
                        }
                    },
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    serverGrouping: true
                }),
                columns: $scope.createColumns(result.data.Headers)
            };
        };
        
        $scope.filterCheckoxChecked = function() {         
            $scope.gridOptions.dataSource.read();
        };
        
        $scope.getData = function () {
            
            FlowApplicationTasksService.getApplicationTasksHeader($scope.PaginationModel, $scope.portlet.Id)
                    .then(function (result) {
                        $scope.controllingModel.DisplayLoadingBar = false;

                        $scope.headerResult = result;

                        if (result.data.Headers.length > 0) {
                            $scope.gridOptions = $scope.getTableOptions($scope.headerResult);
                        }

                        if ($scope.configurationModel.Groupable && $scope.PaginationModel.GroupBy) {
                            $scope.gridOptions.dataSource.group([
                                {
                                    aggregates: [],
                                    dir: "asc",
                                    field: $scope.PaginationModel.GroupBy
                                }
                            ]);
                        }
                    }).catch(angular.noop).finally(
                        function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                        }
                    );
        }

        $scope.generateControlRandomKey = function () {

            var result = Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);
            return result;
        };

        $scope.initialize = function (rowFilter) {

            $scope.archiveCheckboxKey = $scope.generateControlRandomKey();
            $scope.finishedCheckboxKey = $scope.generateControlRandomKey();
            
            $scope.FlowApplicationTasksService = FlowApplicationTasksService;
            $scope.configurationModel =
                FlowApplicationTasksConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = FlowApplicationTasksConfigurationService.getControllingModel();

            $scope.enumerableFlowConfigs = $scope.configurationModel.WorkFlowModel.FlowConfigurations;
            $scope.orderedColorRulesBackground = _.sortBy($scope.configurationModel.colorRulesBackground, [function (rule) { return rule.Position; }]);

            $scope.PaginationModel = {};

            $scope.PaginationModel.IncludeFinished = false;
            $scope.PaginationModel.IncludeArchived = false;

            $scope.PaginationModel.CurrentUICulture = kendo.culture().name;
            $scope.PaginationModel.PortletId = $scope.portlet.Id;
            $scope.PaginationModel.GroupBy = $scope.configurationModel.GroupingModel.GroupBy;
            $scope.PaginationModel.Search = [];
            $scope.PaginationModel.PageSize = $scope.configurationModel.PageSize;

            if (rowFilter) {
                $scope.PaginationModel.RowFilter = rowFilter;
            }

            $scope.getData();

        }
        $scope.initialize();
    }
])