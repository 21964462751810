app.factory('newsViewerConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {

        var configurationModel = configurationService.getConfigurationModel(properties);

        configurationModel.PageSize = parseInt(propertyService.getPropertyValue(properties, "pageSize", "3"));
        configurationModel.DisplayComments = propertyService.getBoolPropertyValue(properties, "displayComments", true); 

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {
        configurationService.saveProperties(properties, configurationModel);
        propertyService.setProperty(properties, "pageSize", configurationModel.PageSize.toString());
        propertyService.setProperty(properties, "displayComments", configurationModel.DisplayComments.toString());
    }

    return service;
}]);