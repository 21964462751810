app.controller('Text_CreateOrEditPortletController', ['$scope', '$uibModalInstance', 'createOrEditModalService', 'configurationService', 'data', '$location', function ($scope, $uibModalInstance, createOrEditModalService, configurationService, data, $location) {

    $scope.panelBarOptions = {
        expandMode: "single",
        select: $scope.expandCollapse
    };

    $scope.model = createOrEditModalService.getModel("Text", data);
    $scope.configurationModel = configurationService.getConfigurationModel($scope.model.Properties);

    var fontsInConfig = $.customEditorFonts.split(",").filter(function (v) { return v !== ''; });

    var fonts = [];

    angular.forEach(fontsInConfig, function (fontName) {
        fonts.push({ text: fontName, value: fontName });
    });

    var fontsWithSansSerif = [];

    angular.forEach(fontsInConfig, function (fontName) {
        fontsWithSansSerif.push({ text: fontName + ', sans-serif', value: fontName + ', sans-serif' });
    });

    fonts = fonts.concat(fontsWithSansSerif);

    $scope.options = {
        imageBrowser: {
            transport: {
                read: $.path + "/ImageBrowser/Read",
                destroy: {
                    url: $.path + "/ImageBrowser/Destroy",
                    type: "POST"
                },
                create: {
                    url: $.path + "/ImageBrowser/Create",
                    type: "POST"
                },
                thumbnailUrl: $.path + "/ImageBrowser/Thumbnail",
                uploadUrl: $.path + "/ImageBrowser/Upload",
                imageUrl: $.path + "/ImageBrowser/Image?path={0}"
            }
        },
        resizable: {
            content: true,
            toolbar: false
        },
        tools: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "createLink",
            "unlink",
            "insertImage",
            "subscript",
            "superscript",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn",
            "viewHtml",
            "formatting",
            "cleanFormatting",
            {
                name: "fontName",
                items: fonts
            }, 
            "fontSize",
            "foreColor",
            "backColor"
        ]
    };

    $scope.ok = function () {
        configurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}])
