app.service('DocManListService', ['basePluginService', '$q',
    function (basePluginService,$q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getDocumentManagementList = function (paginationModel,portletId) {
            return basePluginService.backend.post('documentManagementList',paginationModel, portletId);
        };

        service.getDocument = function (documentModel, headers, portletId) {     
            return basePluginService.backend.postWithHeaders("documentManagement", documentModel, headers, portletId, true);
        };


        return service;

    }]);