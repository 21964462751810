app.factory('genericDocumentViewerUrlConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        configurationModel.MimeType = propertyService.getPropertyValue(properties, "mimeType", "");

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {
        configurationService.saveProperties(properties, configurationModel);
        propertyService.setProperty(properties, "mimeType", configurationModel.MimeType);
    }

    return service;
}]);