app.factory('dataVisualizerCardNavigatorConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {

        var configurationModel = configurationService.getConfigurationModel(properties);
        configurationModel.CardList = $.parseJSON(propertyService.getPropertyValue(properties, "CardList", JSON.stringify([])));


        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {
        configurationService.saveProperties(properties, configurationModel);

        propertyService.setProperty(properties, "CardList", JSON.stringify(configurationModel.CardList));
    }

    return service;
}]);