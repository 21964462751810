app.controller("KanbanController",
	["$scope", "$controller", "kanbanService", "$timeout", "pluginConfigurationService", "dragulaService", "receiverService", "$rootScope", 'propertyService',
		function ($scope, $controller, kanbanService, $timeout, pluginConfigurationService, dragulaService, receiverService, $rootScope, propertyService) {

			$controller('BasePluginController', { $scope: $scope });
			$controller('OperationAndInputExecutionController', { $scope: $scope });

			/** 
			 * PROPERTIES & SETTINGS
			 */
			dragulaService.options($scope, 'third-bag', {
				revertOnSpill: true,
				accepts: function (el, target, source, sibling) {
					var dropTaskWorkflow = $scope.configurationModel.FlowConfiguration.DropTask;
					return (dropTaskWorkflow != null && dropTaskWorkflow != -1) ? true : false;
				}
			});


		
			$scope.kanbanViewConfig = {
				columnNumber: null,
				columnWidth: null,
				scrollBarWidth: 0,
				columnMinWidth: 10, //px,
				height: 500
			};
			

			$scope.kanbanDragDropConfig = {

				//for AngularJs
				sourceColumnModel: null,
				targetColumnModel: null,
				movedCellModel: null,

				//For JQuery
				targetColumnDOM: null,
				sourceColumnDOM: null,

				//methods
				getKanbanColumnId: function (el) {
					return jQuery(el).closest('.kanban-column').attr('data-dragula-column');
				},
				getKanbanCellId: function (el) {
					return jQuery(el).attr('data-dragula-cell');
				}
			};


			/**
			 * EVENTS
			 */
			$scope.$on('third-bag.drag', function (e, el, container) {

				var dragulaColumnId = $scope.kanbanDragDropConfig.getKanbanColumnId(el);
				var dragulaCellId = $scope.kanbanDragDropConfig.getKanbanCellId(el);

				$scope.kanbanDragDropConfig.sourceColumnModel = $scope.columns.filter(function (col) {
					return col.status == dragulaColumnId;
				})[0];

				$scope.kanbanDragDropConfig.movedCellModel = $scope.kanbanDragDropConfig.sourceColumnModel.items.filter(function (item) {
					return item.id == dragulaCellId;
                })[0];

                $scope.selectedTaskModel = $scope.kanbanDragDropConfig.movedCellModel.data;
			});

			$scope.$on('third-bag.over', function (e, el, container, siblings) {
				var overColumnId = $scope.kanbanDragDropConfig.getKanbanColumnId(container);
				var overColumnModel = $scope.columns.filter(function (col) {
					return col.status == overColumnId;
				})[0];

				if (overColumnModel.items.length >= overColumnModel.maxCount) {
					if (!overColumnModel.noMaxCountLimit) {
						jQuery(container).css({ 'background-color': '#E56B54' });
					} else {
						jQuery(container).css({ 'background-color': '#D0E0EE' });
					}
				} else {
					jQuery(container).css({ 'background-color': '#D0E0EE' });
				}
			});

			$scope.$on('third-bag.out', function (e, el, container, siblings) {
				jQuery(container).css({ 'background-color': '' }); //end over
			});

			$scope.$on('third-bag.drop', function (e, el, target, source) {
				$scope.kanbanDragDropConfig.sourceColumnDOM = source[0];
				$scope.kanbanDragDropConfig.targetColumnDOM = target[0];
				var dragulaColumnId = $scope.kanbanDragDropConfig.getKanbanColumnId(el);
				$scope.kanbanDragDropConfig.targetColumnModel = $scope.columns.filter(function (m) {
					return m.status == dragulaColumnId;
				})[0];
			});

			$scope.$on('third-bag.drop-model', function (e, target, source) {


				$timeout(function () {
					var columns = [];
					jQuery("#kanban" + $scope.portlet.Id + " .panel.kanban-column").each(function () {
						var columnId = jQuery(this).attr("id");
						var colHeight = 0;
						var cells = jQuery("#" + columnId + " div.kanban-cell");
						var cellsCount = cells.length;

						cells.each(function (idx) {
							colHeight = colHeight + jQuery(this).outerHeight(true);
							if (idx === cellsCount - 1) {
								columns.push({ id: columnId, height: colHeight });
							}
						});

					});

					$scope.setColumnsHeight(columns);


                    var currentOrderId = $scope.kanbanDragDropConfig.movedCellModel.data.OrderId;
					var currentOrderIdIndex = _.findIndex($scope.kanbanDragDropConfig.targetColumnModel.items,
						function (o) {
							return o.data.OrderId === currentOrderId;
						});
					var prevModel = $scope.kanbanDragDropConfig.targetColumnModel.items[currentOrderIdIndex - 1];
					var nextModel = $scope.kanbanDragDropConfig.targetColumnModel.items[currentOrderIdIndex + 1];
					var prevOrderId = (prevModel && prevModel.OrderId) ? prevModel.OrderId : '';
					var nextOrderId = (nextModel && nextModel.OrderId) ? nextModel.OrderId : '';


					var operationInputData = {
						OperationType: 'DragAndDrop',
						TaskId: $scope.kanbanDragDropConfig.movedCellModel.id,
						TargetStatus: $scope.kanbanDragDropConfig.targetColumnModel.status,
						SourceStatus: $scope.kanbanDragDropConfig.sourceColumnModel.status,
						CurrentItemOrderId: $scope.kanbanDragDropConfig.movedCellModel.OrderId ? $scope.kanbanDragDropConfig.movedCellModel.OrderId : '',
						PrevItemOrderId: prevOrderId,
						NextItemOrderId: nextOrderId
					};

                    var data = [];
                    if ($scope.selectedTaskModel)
                        data.push($scope.selectedTaskModel);

					var dropTaskWorkflow = $scope.configurationModel.FlowConfiguration.DropTask;
					if (dropTaskWorkflow != null && dropTaskWorkflow != -1) {
						$scope.runFlow(dropTaskWorkflow.Id,
							!dropTaskWorkflow.IsMachineWorkflow,
							data,
							operationInputData,
							$scope.portlet.Id);
					}
				});
			});

			$scope.$on("portletTabChanged",
				function (event, data) {
					if (data && data.portlet && $scope.portlet.Id === data.portlet) {
						$scope.redraw();
					}
				});

			$scope.$on("onMaximized",
				function (event, data) {
					for (var i = 0; i < data.length; i++) {
						if (data[i].Id === $scope.portlet.Id) {
							$scope.redraw();
							break;
						}
					}

				});

			$scope.$on("onMinimized",
				function (event, data) {
					for (var i = 0; i < data.length; i++) {
						if (data[i].Id === $scope.portlet.Id) {
							$scope.redraw();
							break;
						}
					}
				});


			$scope.$on("resize",
				function (event, data) {
					if (data) {
						for (var i = 0; i < data.PortletIds.length; i++) {
							if (data.PortletIds[i] === $scope.portlet.Id) {
								$scope.redraw();
								break;
							}
						}
					} else {
						$scope.redraw();
					}
				});

			/**
			 *  LISTENING FOR EVENTS FROM OTHER PORTLETS
			 */

			$scope.$on("onRowSelected",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
						$scope.updateRequestsWithRowFilter(data);
					    $scope.refresh();
					}
				});

			$scope.$on("onRefresh",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
						$scope.refresh();
					}
				});

			$scope.$on("onFilter",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
						$scope.updateRequestsWithFilters(data);
						$scope.refresh();
					}
				});

			$scope.$on("onGenericTreeNodeSelected",
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
						$scope.updateRequestsWithRowFilter(data);
						$scope.refresh();
					}
				});


			/**
			 * METHODS
			 */
			$scope.updateRequestsWithRowFilter = function (data) {
				$scope.DataModel.RefreshWorkflowCache = true;
				$scope.DataModel.RowFilter = data.Data;
			}

			$scope.updateRequestsWithFilters = function (data) {
				$scope.DataModel.Search = data.Data;
			}

			$scope.onCellClick = function (event, task) {
				if ($scope.clicked) {
					$scope.cancelClick = true;
					return;
				}

				$scope.clicked = true;

				$timeout(function () {
					if ($scope.cancelClick) {
						$scope.cancelClick = false;
						$scope.clicked = false;
						return;
					}

					$scope.selectedTaskModel = task.data;

					$rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: task.data });


					setCellBorder(event.target, 'gray');
					//clean up
					$scope.cancelClick = false;
					$scope.clicked = false;

				}, 300);
			};

			$scope.onCellDblclick = function (event, task) {
				$timeout(function () {

					setCellBorder(event.target, 'coral');

					var operationInputData = {
						OperationType: 'DoubleClick',
						TaskId: task.id
					};

					var data = [];
					data.push(task.data);

					var dblClickWorkflow = $scope.configurationModel.FlowConfiguration.DoubleClickTask;

					if (dblClickWorkflow && dblClickWorkflow != -1) {
						$scope.runFlow(dblClickWorkflow.Id,
							!dblClickWorkflow.IsMachineWorkflow,
							data,
							operationInputData,
							$scope.portlet.Id);
					}
				});
			};


			function setCellBorder(target, color) {
				jQuery(".kanban-cell").css({ 'border': '' });
				var clickedCell = jQuery(target).closest(".kanban-cell");
				jQuery(clickedCell).css({ 'border': '3px solid ' + color });
			};

			/**
			 * Per Stackoverflow.com
			 * @returns {} 
			 */
			function getScrollBarWidth() {
				var inner = document.createElement('p');
				inner.style.width = "100%";
				inner.style.height = "200px";

				var outer = document.createElement('div');
				outer.style.position = "absolute";
				outer.style.top = "0px";
				outer.style.left = "0px";
				outer.style.visibility = "hidden";
				outer.style.width = "200px";
				outer.style.height = "150px";
				outer.style.overflow = "hidden";
				outer.appendChild(inner);

				document.body.appendChild(outer);
				var w1 = inner.offsetWidth;
				outer.style.overflow = 'scroll';
				var w2 = inner.offsetWidth;
				if (w1 == w2) w2 = outer.clientWidth;

				document.body.removeChild(outer);

				return (w1 - w2);
			};

			$scope.runCustomFlow = function (workflowIdentifier, isUserInteractionWorkflow) {
				var data = [];
				if ($scope.selectedTaskModel)
					data.push($scope.selectedTaskModel);

				$scope.runFlow(workflowIdentifier, isUserInteractionWorkflow, data);
			};

			$scope.customFlowDisabled = function (flowConfigId) {
				if (flowConfigId) {
					return kanbanService.isCustomFlowDisabled($scope.configurationModel,
						flowConfigId,
						$scope.selectedTaskModel);
				}
			};

			$scope.refresh = function () {
				$scope.getData();
			};

			$scope.getData = function () {

				$scope.controllingModel.DisplayLoadingBar = true;

				kanbanService.getTasks($scope.DataModel, $scope.portlet.Id).then(
					function (response) {
						$scope.response = response;
						$scope.redraw();
					}, function (err) {
						console.log(err);
					}).finally(function () {
						$scope.controllingModel.DisplayLoadingBar = false;
					});
			};

			/**
			 * Fired when drawing of the kanban (by ng-repeat in the html view) ended
			 * @returns {} 
			 */
			$scope.repeatEnded = function () {

				var columns = [];
				$timeout(function () {

					jQuery("#kanban" + $scope.portlet.Id + " .panel.kanban-column").each(function () {
						columns.push({ id: jQuery(this).attr("id"), height: jQuery(this).height() });
					});

					$scope.setColumnsHeight(columns);

				});

			};

			$scope.setColumnsHeight = function (columns) {

				var columnWithMaxHeight = _.maxBy(columns,
					function (o) {
						return o.height;
					});

				jQuery("#kanban" + $scope.portlet.Id + " .panel-body.kanban-column-bag").height(columnWithMaxHeight.height);
			};

			/**
			 * Fired after events portletTabChanged, onMaximized, onMinimized, resize, and getData
			 * Should not get data from backend, but redraw kanban only, in order to fit to the screen, scrollbars and so on.
			 * @returns {} 
			 */
			$scope.redraw = function () {

				$scope.columns = kanbanService.getKanbanData($scope.response.data, $scope.configurationModel);

				$scope.kanbanViewConfig.columnNumber = Math.floor(12 / $scope.columns.length);
				$scope.kanbanViewConfig.columnWidth = 100 / $scope.columns.length; /* % */



				//wait for end of the digest cycle
				$timeout(function () {
					var kanbanHandler = jQuery("#kanban" + $scope.portlet.Id);
					var pcHandler = kanbanHandler.closest('.portletContainerContent');

					var pcContentHeight = pcHandler.closest('.portletContainerContent').outerHeight();
					var pcTabsHeight = pcHandler.find('.nav.nav-tabs').height();
					var kanbanToolbarHeight = kanbanHandler.find('.row.kanban-toolbar').height();
					var kanbanHeaderHeight = kanbanHandler.find('.row.kanban-board.kanban-header').height();

				
						var kanbanContent = kanbanHandler.find(".kanban-content");
						var hasVerticalScrollbar = kanbanContent[0].scrollHeight > kanbanContent[0].clientHeight;

						if (hasVerticalScrollbar)
							$scope.kanbanViewConfig.scrollBarWidth= getScrollBarWidth();


					$scope.kanbanViewConfig.height = Math.floor(pcContentHeight - pcTabsHeight - kanbanToolbarHeight - kanbanHeaderHeight + 2);

					if (propertyService.getBoolPropertyValue($scope.portlet.Properties, 'isTitleEnabled')) {
						$scope.kanbanViewConfig.height -= 74;
					}

				});
			}

			$scope.initialize = function () {

				//$timeout(function() {
				//	var kanbanHandler = jQuery("#kanban" + $scope.portlet.Id);
				//	var kanbanContent = kanbanHandler.find(".kanban-content");

				//	var hasVerticalScrollbar = kanbanContent[0].scrollHeight > kanbanContent[0].clientHeight;

				//	if (hasVerticalScrollbar)
				//		$scope.kanbanViewConfig.scrollWidth= getScrollBarWidth();
				
				//});		

				$scope.configurationModel = pluginConfigurationService.getConfigurationModel($scope.portlet.Configuration, "Kanban");
				$scope.controllingModel = pluginConfigurationService.getControllingModel();
				$scope.DataModel = {};
				$scope.DataModel.CurrentUICulture = kendo.culture().name;
				$scope.DataModel.QueryId = $scope.portlet.Queries.length > 0 ? $scope.portlet.Queries[0].Id : null;

				$scope.DataModel.WorkflowId = $scope.portlet.Workflows.length > 0 ? $scope.portlet.Workflows[0].WorkflowId : null;
				$scope.DataModel.OutputVariable = $scope.portlet.Workflows.length > 0 ? $scope.portlet.Workflows[0].OutputVariable : null;

				$scope.DataModel.RefreshWorkflowCache = true;
				$scope.DataModel.DataSourceType = $scope.portlet.DataSourceType;
				$scope.DataModel.PortletId = $scope.portlet.Id;
				$scope.DataModel.Group = {
					Code: $scope.configurationModel.TasksDatasource.TaskStatusColumnName,
					Order: "asc" //hardcoded
				};
				$scope.DataModel.Search = [];
				$scope.DataModel.PageSize = 100000; //hardcoded
				$scope.getData();
			}();
		}
	]);