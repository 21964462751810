angular.module("portal").controller("HtmlController", [
    "$scope", "HtmlService", "$sce", "portletErrorService", "receiverService", "htmlConfigurationService",
    "globalVariableService", "translationsService", "translationFlowClientService", "appConfig", "$controller", "$compile", "stringParserService",
    function ($scope, HtmlService, $sce, portletErrorService, receiverService, htmlConfigurationService,
        globalVariableService, translationsService, translationFlowClientService, appConfig, $controller, $compile, stringParserService) {

        $controller('BasePluginController', { $scope: $scope });


        $scope.$on("kendoWidgetCreated", function (event, widget) {
          
            if (widget) {
                var originalRefresh = widget.element.find("a.k-pager-refresh.k-link");
                originalRefresh.after(
                        "<a class='k-pager-refresh k-link' href='#' ng-click='refresh();$event.preventDefault();' style='float:right' id='new-refresh-button" +
                        $scope.portlet.Id + "'><span class='k-icon k-i-refresh'></span></a>");

                originalRefresh.remove();

                $compile($("#new-refresh-button" + $scope.portlet.Id))($scope);
            }
        });
        
        $scope.$on("onRowSelected", function(event, data) {
            if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                if (!$.isEmptyObject(data.Data) && $scope.PaginationModel.QueryId) {
                    $scope.newRowFilter(data.Data);
                    $scope.refresh();
                }
            }
        });

        $scope.$on("onRefresh", function(event, data) {
            if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                $scope.refresh();
            }
        });

        $scope.newRowFilter = function(rowFilter) {
            if ($scope.PaginationModel) {
                $scope.PaginationModel.RowFilter = rowFilter;
            }
        };

        $scope.refresh = function() {
            $scope.PaginationModel.RefreshWorkflowCache = true;
            $scope.getAndDisplayData();
        };

        $scope.getPagerOptions = function () {
            $scope.controllingModel.DisplayLoadingBar = true;
            return {
                buttonCount: 3,
                refresh: true,
                dataBinding: $scope.onDataBinding,
                dataSource: new kendo.data.DataSource({
                    pageSize: $scope.PaginationModel.PageSize,
                    batch: true,
                    serverPaging: true,
                    transport: {
                        read: function (options) {
                            $scope.PaginationModel.StartIndex = options.data.skip;
                            $scope.PaginationModel.PageNumber = options.data.page;

                            var pagerDataSource = {};
                            var data = [
                            ];
                            pagerDataSource.data = data;

                            HtmlService.getHtmlData($scope.PaginationModel, $scope.portlet.Id)
                                .then(function (result) {
                                    $scope.hasRows = result.data.Rows.length > 0;
                                    if ($scope.hasRows) {
                                        $scope.Total = result.data.Total;

                                        pagerDataSource.Total = $scope.Total;
                                        options.success(pagerDataSource);

                                        var htmlBody = "";
                                        angular.forEach(result.data.Rows,
                                            function (row) {

                                                htmlBody = htmlBody +
                                                    $scope.replaceAll(row.Details,
                                                        translationsService.get($scope.portlet.Code + '-HtmlBody',
                                                            $scope.portlet.Content));
                                            });
                                        $scope.htmlBody = $sce.trustAsHtml(htmlBody);
                                        $scope.displayHeader();
                                    }
                                }).catch(angular.noop).finally(function () {
                                        $scope.controllingModel.DisplayLoadingBar = false;
                                        $scope.PaginationModel.RefreshWorkflowCache = false;
                                    }
                                );;
                        }
                    }
                    ,
                    schema: {
                        total: "Total",
                        data: "data"
                    }
                })
            };
        };

		$scope.getAndDisplayData = function () {
			if (($scope.PaginationModel.QueryId || $scope.PaginationModel.WorkflowId) && $scope.PaginationModel.DataSourceType !== 'None')
			{
				$scope.pagerOptions = $scope.getPagerOptions();
				$scope.pagerOptions.dataSource.read();
			}
		};

        $scope.displayData = function () {
            $scope.htmlBody = $sce.trustAsHtml(translationsService.get($scope.portlet.Code + '-HtmlBody', $scope.portlet.Content));
            $scope.controllingModel.DisplayLoadingBar = false;

        };

        $scope.displayHeader = function() {
            $scope.htmlHeader = $sce.trustAsHtml(translationsService.get($scope.portlet.Code + '-HtmlHeader', $scope.configurationModel.HtmlModel.Header));
        };

        $scope.replaceAll = function (collection, str) {

            var fieldsSetting = $scope.configurationModel.HtmlModel.Fields || []; 

            var toReturn = str;
            angular.forEach(collection, function (row) {
                var setting = fieldsSetting.filter(function(r) { return r.databaseColumn === row.Name })[0] || {};
                var value = stringParserService.parse(row.Value, setting.type, setting.length) || row.Value;
                toReturn = toReturn.replace(new RegExp("{" + row.Name + "}", "g"), value);
            });

            return toReturn;
        };
        $scope.initialize = function() {

            $scope.configurationModel = htmlConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = htmlConfigurationService.getControllingModel();
            $scope.hasRows = true;
            $scope.PaginationModel = {};
            $scope.PaginationModel.GlobalVariables = globalVariableService.getAvailableGlobalVariables();
            $scope.PaginationModel.PageSize = $scope.configurationModel.PageSize;
            $scope.PaginationModel.PageNumber = 1;
            $scope.PaginationModel.RefreshWorkflowCache = false;
            $scope.Total = 0;
            $scope.PaginationModel.QueryId = (_.first($scope.portlet.Queries) || { Id: null }).Id;
            $scope.PaginationModel.WorkflowId = (_.first($scope.portlet.Workflows) || { WorkflowId: null }).WorkflowId;
            $scope.PaginationModel.OutputVariable = (_.first($scope.portlet.Workflows) || { OutputVariable: null }).OutputVariable;
            $scope.PaginationModel.DataSourceType = $scope.portlet.DataSourceType;

            if (($scope.PaginationModel.QueryId || $scope.PaginationModel.WorkflowId) && $scope.PaginationModel.DataSourceType !== 'None') {
                $scope.getAndDisplayData();
            } else {
                $scope.displayHeader();
                $scope.displayData();
            }
        };

        $scope.initialize();
    }
]);
