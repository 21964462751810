app.factory('processOverviewConfigurationService', ['propertyService', 'configurationService', 'Guid', 'dataEvalService', function (propertyService, configurationService, Guid, dataEvalService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        

        configurationModel.SortOrders = [{ Display: 'Ascending', Data: 'asc' }, { Display: 'Descending', Data: 'desc' }];

        /* Flows START */

        configurationModel.WorkFlowModel = {};
        configurationModel.WorkFlowModel.workFlowDoubleClick = $.parseJSON(propertyService.getPropertyValue(properties, "workFlowDoubleClick", "{}"));
        configurationModel.WorkFlowModel.dblClickUserActions = propertyService.getBoolPropertyValue(properties, "dblClickUserActions");

        /* Flows END */



        configurationModel.LaneWidth = propertyService.getPropertyValue(properties, "laneWidth", undefined);

        configurationModel.CardDefinition = $.parseJSON(propertyService.getPropertyValue(properties, "cardDefinition", JSON.stringify(
        {
            defaultColor: '#000000',
            colours: [],
            icons: [],
            dataRowHeader: { displayName: undefined, data: undefined },
            dataRowOne: { displayName: undefined, data: undefined },
            dataRowTwo: { displayName: undefined, data: undefined },
            dataRowThree: { displayName: undefined, data: undefined },
            dataRowFour: { displayName: undefined, data: undefined },
            tooltip: undefined,
            user: undefined,
            bottomRightValueDef: undefined,
            cardHeightPixels: 109,
            leftColumnWidthPercent: 40,
            Code: Guid.newGuid()
        })));

        configurationModel.LaneModel = {};
        configurationModel.LaneModel.CommonConnectorName = propertyService.getPropertyValue(properties, "CommonConnectorName", undefined);
        configurationModel.LaneModel.lanes = _.sortBy($.parseJSON(propertyService.getPropertyValue(properties, "lanes", "[]")),
            function (x) { return x.Position; });

        configurationModel.pageSize = parseInt(propertyService.getPropertyValue(properties, "pageSize", "10"));
        configurationModel.cardsShown = parseInt(propertyService.getPropertyValue(properties, "cardsShown", "10"));
        configurationModel.cardsShownFullscreen = parseInt(propertyService.getPropertyValue(properties, "cardsShownFullscreen", "14"));

        configurationService.assignIds(configurationModel.LaneModel.lanes);

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {

        configurationService.saveProperties(properties, configurationModel);

        propertyService.setProperty(properties, "CommonConnectorName", configurationModel.LaneModel.CommonConnectorName);

        propertyService.setPropertyWithRemoveOnEmpty(properties, "laneWidth", configurationModel.LaneWidth);

        if (configurationModel.LaneModel.lanes) {
            propertyService.setProperty(properties, "lanes", JSON.stringify(configurationModel.LaneModel.lanes));
        }


        /* Flows START */
        propertyService.setPropertyWithRemoveOnEmpty(properties, "workFlowDoubleClick", JSON.stringify(configurationModel.WorkFlowModel.workFlowDoubleClick));
        propertyService.setBoolProperty(properties, "dblClickUserActions", configurationModel.WorkFlowModel.dblClickUserActions);
        /* Flows END*/


        if (configurationModel.CardDefinition) {
            propertyService.setProperty(properties, "cardDefinition", JSON.stringify(configurationModel.CardDefinition));
        }

        propertyService.setProperty(properties, "pageSize", configurationModel.pageSize.toString());
        propertyService.setProperty(properties, "cardsShown", configurationModel.cardsShown.toString());
        propertyService.setProperty(properties, "cardsShownFullscreen", configurationModel.cardsShownFullscreen.toString());

    }

    return service;
}]);