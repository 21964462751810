app.controller("inboxViewController", [
    "$scope", "inboxService", "receiverService", "workflowService", "executionService", 'stateService', 'workflowThemeService', 'imageService', 'homeStateService', '$controller', 'dateHelperService', 
    function ($scope, inboxService, receiverService, workflowService, executionService, stateService, workflowThemeService, imageService, homeStateService, $controller, dateHelperService) {

        $controller('BasePluginController', { $scope: $scope });

        $scope.filterModel = {}
        $scope.done = false;

        $scope.clear = function (orderBy, calendar, favs, prio) {
            if (orderBy) {
                $scope.filterModel.OrderBy = false;
                $scope.filterModel.OrderByCssClass = "inboxInActiveFilter";
            }
            if (calendar) {
                var today = new Date();
                today.setHours(0, 0, 0, 0);
                $scope.filterModel.CalendarVisible = false;
                $scope.filterModel.SelectedDate = today;
                $scope.filterModel.SelectedDateString = kendo.toString($scope.filterModel.SelectedDate, 'd');;
                $scope.filterModel.DateCssClass = "inboxInActiveFilter";
                $scope.filterModel.DateIcon = $.sharedImagesDir + "/icon-date-inactive.svg";
            }
            if (favs) {
                $scope.filterModel.IsStarred = false;
                $scope.filterModel.IsStarredCssClass = "inboxInActiveFilter";
                $scope.filterModel.IsStarredIcon = $.sharedImagesDir + "/icon-star-inactive.svg";
            }
            if (prio) {
                $scope.filterModel.IsImportant = false;
                $scope.filterModel.IsImportantCssClass = "inboxInActiveFilter";
                $scope.filterModel.IsImportantIcon = $.sharedImagesDir + "/icon-prio-inactive.svg";
            }


        }

        $scope.searchByStarred = function () {
            if (!$scope.filterModel.IsStarred) {
                $scope.clear(true, true, false, true);
                $scope.filterModel.IsStarred = true;
                $scope.filterModel.IsStarredCssClass = "inboxActiveFilter";
                $scope.filterModel.IsStarredIcon = $.sharedImagesDir + "/icon-star-active.svg";
            }
        }

        $scope.searchByImportant = function () {
            if (!$scope.filterModel.IsImportant) {
                $scope.clear(true, true, true, false);
                $scope.filterModel.IsImportant = true;
                $scope.filterModel.IsImportantCssClass = "inboxActiveFilter";
                $scope.filterModel.IsImportantIcon = $.sharedImagesDir + "/icon-prio-active.svg";
            }

        }

        $scope.openDatePicker = function () {
            $scope.datePicker.open();
        }

        $scope.openCalendar = function () {

            if (!$scope.filterModel.CalendarVisible) {
                $scope.clear(true, false, true, true);
                $scope.filterModel.CalendarVisible = true;
                $scope.filterModel.DateCssClass = "inboxActiveFilter";
                $scope.filterModel.DateIcon = $.sharedImagesDir + "/icon-date-active.svg";
            }
        }

        $scope.order = function (item) {
            if ($scope.filterModel.OrderBy) {
                var date = new Date(item.LastAccessed).setHours(0, 0, 0, 0, 0);
                return date;
            }
            return new Date(0);
        }

        $scope.orderBy = function () {
            if (!$scope.filterModel.OrderBy) {
                $scope.clear(false, true, true, true);
                $scope.filterModel.OrderBy = true;
                $scope.filterModel.OrderByCssClass = "inboxActiveFilter";
            }
        }

        $scope.addDay = function () {
            $scope.changeDay(1);
        }

        $scope.minusDay = function () {
            $scope.changeDay(-1);
        }

        $scope.changeDay = function (value) {
            if ($scope.filterModel.SelectedDate) {
                $scope.filterModel.SelectedDate.setDate($scope.filterModel.SelectedDate.getDate() + value);
                $scope.filterModel.SelectedDateString = kendo.toString($scope.filterModel.SelectedDate, 'd');
            }
        }

        $scope.search = function (item) {

            if ($scope.filterModel.IsStarred && $scope.filterModel.IsStarred === item.WorkflowHeader.IsStarred) {
                return true;
            }
            if ($scope.filterModel.IsImportant && $scope.filterModel.IsImportant === item.WorkflowHeader.IsImportant) {
                return true;
            }

            return false;
        }

        $scope.filterOut = function (item) {
            if ($scope.filterModel.CalendarVisible && $scope.filterModel.SelectedDate != null) {
                var date = dateHelperService.parseCSharpDate(item.LastAccessed);
                var selDate = $scope.filterModel.SelectedDate.setHours(0, 0, 0, 0, 0);
                if (date !== selDate) {
                    return false;
                }
            }

            if ($scope.filterModel.IsStarred && $scope.filterModel.IsStarred === item.WorkflowHeader.IsStarred) {
                return false;
            }
            if ($scope.filterModel.IsImportant && $scope.filterModel.IsImportant === item.WorkflowHeader.IsImportant) {
                return false;
            }

            return true;
        }

        $scope.redirectToExecutionView = function(executionIdentity, handoverIdentity, themeColor, text, icon) {

            executionService.setCurrentInboxWorkflowState(executionIdentity, handoverIdentity, themeColor, text, icon, $scope.portlet.Id);

            var inboxData = {
                executionIdentity: executionIdentity,
                handoverIdentity: handoverIdentity,
                name: text,
                icon: icon
            };

            if (inboxData.handoverIdentity) {
                executionService.setCurrentWorkflowState(handoverIdentity, null, undefined, $scope.portlet.Id);
                executionService.takeJob(inboxData.handoverIdentity).then(function(result) {
                    var webStart = angular.fromJson(result.data);

                    workflowThemeService.setColor(themeColor);

                    stateService.go($scope,
                        "execution",
                        {
                            executionIdentity: webStart.JobIdentity,
                            isHandover: true,
                            name: inboxData.name,
                            workflowTitle: inboxData.name,
                            workflowIcon: inboxData.icon,
                            portletId: $scope.portlet.Id 
                        });
                }).catch(angular.noop);

            }
        };

        $scope.$on("onInboxRefreshed",
            function (event, data) {
                if (receiverService.canReceive($scope.portlet, "onInboxRefreshed", data)) {
                    $scope.refresh();
                }
            });

        $scope.refresh = function () {
            $scope.done = false;
            $scope.initialize();
        }

        $scope.restoreLastInbox = function () {
            var lastInboxId = homeStateService.getLastInboxId();
            if (lastInboxId) {
                angular.forEach($scope.model, function (menu) {
                    angular.forEach(menu.RootItems, function (item) {
                        if (item.Type == 'inbox' && item.Inbox.Identifier == lastInboxId)
                            $scope.showInbox(item.Inbox.WebExecutionInfos, item.Inbox.IconString, item.Inbox.Title, lastInboxId);
                    });
                });
            }
        };     
        
        $scope.initialize = function () {

            var currentInboxWorkflowState = executionService.getCurrentInboxWorkflowState($scope.portlet.Id);
            if (currentInboxWorkflowState) {
                $scope.redirectToExecutionView(
                    currentInboxWorkflowState.executionIdentity,
                    currentInboxWorkflowState.handoverIdentity,
                    currentInboxWorkflowState.themeColor,
                    currentInboxWorkflowState.text,
                    currentInboxWorkflowState.icon);
            }
            
            $scope.filterModel = {}
            $scope.filterValue = '';
            $scope.inboxCount = 0;
            $scope.iconString = '';

            $scope.workflowService = workflowService;
            $scope.workflowThemeService = workflowThemeService;
            $scope.executionService = executionService;
            $scope.imageService = imageService;
            $scope.homeStateService = homeStateService;


            inboxService.getInbox($scope.$parent.configurationModel.inboxId)
                .then(function(result) {
                   
                    $scope.model = result.data.WebExecutionInfos;
                    $scope.inboxTitle = result.data.Title;
                    $scope.iconString = result.data.IconString;

                    $scope.dateBarOptions = {
                        dates: $scope.model,
                        month: {
                            content:
                                "#if(hasValues(data.date, data.dates)) {##:data.value#<span class='glyphicon glyphicon-exclamation-sign' aria-hidden='true'></span>#} else{##:data.value##}#"
                        }
                    };
                    $scope.clear(true, true, true, true);
                    $scope.orderBy();
                    $scope.done = true;
                }).catch(angular.noop);
        };

        $scope.initialize();

    }
]);

function hasValues(date, dates) {
    for (var idx = 0, length = dates.length; idx < length; idx++) {
        var toCheck = new Date(dates[idx].LastAccessed).setHours(0, 0, 0, 0, 0);
        if (date.setHours(0, 0, 0, 0, 0) === toCheck) {
            return true;
        }
    }

    return false;
} 