app.factory('filterConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);

        configurationModel.NumberOfColumns = parseInt(propertyService.getPropertyValue(properties, "numberOfColumns", "2"));

        configurationModel.Mappers = $.parseJSON(propertyService.getPropertyValue(properties, "mappers", "[]"));

        for (var i = 0; i < configurationModel.Mappers.length; i++) {
            if (!configurationModel.Mappers[i].Operator) {
                configurationModel.Mappers[i].Operator = { Text: "Equals", Value: "eq" };
            }
        }

        configurationService.assignIds(configurationModel.Mappers);

        configurationModel.GroupHeaders = $.parseJSON(propertyService.getPropertyValue(properties, "groupHeaders", "[]"));

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel, operation) {
        configurationService.saveProperties(properties, configurationModel);

        propertyService.setProperty(properties, "numberOfColumns", configurationModel.NumberOfColumns.toString());

        if (configurationModel.Mappers && operation == "copy") {
            configurationService.assignCodes(configurationModel.Mappers);
        }

        if (configurationModel.Mappers) {
            propertyService.setProperty(properties, "mappers", JSON.stringify(configurationModel.Mappers));
        }

        if (configurationModel.GroupHeaders && operation == "copy") {
            configurationService.assignCodes(configurationModel.GroupHeaders);
        }

        if (configurationModel.GroupHeaders) {
            propertyService.setProperty(properties, "groupHeaders", JSON.stringify(configurationModel.GroupHeaders));
        }
    }

    return service;
}]);