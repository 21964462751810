app.controller('GenericDocumentViewerUrl_CreateOrEditPortletController', [
    '$scope', '$rootScope', '$uibModalInstance', 'createOrEditModalService', 'genericDocumentViewerUrlConfigurationService', 'data', function ($scope, $rootScope, $uibModalInstance, createOrEditModalService, genericDocumentViewerUrlConfigurationService, data) {

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.model = createOrEditModalService.getModel("GenericDocumentViewerUrl", data);
        $scope.configurationModel = genericDocumentViewerUrlConfigurationService.getConfigurationModel($scope.model.Properties);

        $scope.ok = function () {
            genericDocumentViewerUrlConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
            $uibModalInstance.close($scope.model);
            //kendo.destroy($('body > div:gt(0)'));
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
            //kendo.destroy($('body > div:gt(0)'));
        };
    }
])