app.controller('NewsViewer_CreateOrEditPortletController', [
    '$scope', '$uibModalInstance', 'createOrEditModalService', 'newsViewerConfigurationService', 'propertyService', 'data', function ($scope, $uibModalInstance, createOrEditModalService, newsViewerConfigurationService, propertyService, data) {

        $scope.model = createOrEditModalService.getModel("NewsViewer", data);
        $scope.configurationModel = newsViewerConfigurationService.getConfigurationModel($scope.model.Properties);

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.ok = function () {
            newsViewerConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
            $uibModalInstance.close($scope.model);
            //kendo.destroy($('body > div:gt(0)'));
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
            //kendo.destroy($('body > div:gt(0)'));
        };
    }])
