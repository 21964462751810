app.controller('DataVisualizerCardController',
[
    '$scope', '$rootScope', 'dataVisualizerCardService', 'dataVisualizerCardConfigurationService', 'receiverService',
    'editorService', 'portletErrorService', 'bootstrapColumnClassService', 'translationsService',
    'globalVariableService', 'appConfig', 'dataEvalService', '$controller', '$timeout', 

    function($scope,
        $rootScope,
        dataVisualizerCardService,
        dataVisualizerCardConfigurationService,
        receiverService,
        editorService,
        portletErrorService,
        bootstrapColumnClassService,
        translationsService,
        globalVariableService,
        appConfig,
        dataEvalService,
        $controller,
        $timeout
        )
    {
        $controller('BasePluginController', { $scope: $scope });

        $scope.editorService = editorService;   
        $scope.configurationService = dataVisualizerCardConfigurationService;

       

        $scope.initPaginationModel = function() {

            $scope.PaginationModel = {};
            $scope.PaginationModel.PageSize = 1;
            $scope.PaginationModel.StartIndex = 0;
            $scope.PaginationModel.PageNumber = 1;

            $scope.PaginationModel.GlobalVariables = globalVariableService.getAvailableGlobalVariables();
            $scope.PaginationModel.QueryId = (_.first($scope.portlet.Queries) || { Id: null }).Id;            
            $scope.PaginationModel.WorkflowId = (_.first($scope.portlet.Workflows) || { WorkflowId: null }).WorkflowId;
            $scope.PaginationModel.OutputVariable = (_.first($scope.portlet.Workflows) || { OutputVariable: null }).OutputVariable;

            $scope.PaginationModel.DataSourceType = $scope.portlet.DataSourceType;
            $scope.PaginationModel.Search = [];
            $scope.PaginationModel.Groups = [];

            $scope.PaginationModel.PortletId = $scope.portlet.Id;

        }

        $scope.DisplayedData = {};
        $scope.DisplayedData.Description = undefined;
        $scope.DisplayedData.MainData = undefined;
        $scope.DisplayedData.Unit = undefined;
        $scope.DisplayedData.Priority = undefined;
        $scope.DisplayedData.Trend = undefined;
        $scope.additionalStyles = "";
        $scope.selectedCardCss = "navigator-horizontal-list-item-content-selected";
        $scope.maximized = false; 

        $scope.click = function () {
            $rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, SenderType: $scope.portlet.PortletType, Data: $scope.completeDataRow });

            if ($scope.additionalStyles != undefined && $scope.additionalStyles.includes($scope.selectedCardCss)) {
                $scope.additionalStyles = $scope.additionalStyles.replace($scope.selectedCardCss, "");
            }
            else if ($scope.additionalStyles != undefined && !$scope.additionalStyles.includes($scope.selectedCardCss)) {
                var currentStyle = $scope.additionalStyles;
                $scope.additionalStyles = currentStyle + " " + $scope.selectedCardCss;
            }
        }

        $scope.$on('onFilter',
            function(event, data) {

                $scope.PaginationModel.Search = [];
                if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
                    
                    angular.forEach(data.Data, function (searchPredicate) {
                        $scope.PaginationModel.Search.push(searchPredicate);
                    });

                    $scope.getData();
                }
            });

        $scope.$on('onRefresh',
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                    $scope.refresh();
                }
            });

        $scope.$on('onRowSelected',
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                    $scope.PaginationModel.RowFilter = data.Data;
                    $scope.getData();
                }

                if (data.SenderId != $scope.portlet.Id
                    && data.SenderType === $scope.portlet.PortletType
                    && $scope.additionalStyles != undefined && $scope.additionalStyles.includes($scope.selectedCardCss)) {
                    $scope.additionalStyles = $scope.additionalStyles.replace($scope.selectedCardCss, "");
                }
            });

        $scope.$on("portletTabChanged",
            function (event, data) {
                if (data && data.portlet && $scope.portlet.Id === data.portlet) {
                    $scope.resizeCard();
                }
            });

        $scope.$on('resize', function (event, data) {
                    $scope.resizeCard();
        });

        $scope.$on('onMinimized', function(event, data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].Id === $scope.portlet.Id) {
                        $scope.maximized = false;
                        $scope.resizeCard();
                        break;
                    }
                }
            }
        );

        $scope.$on('onMaximized', function(event, data) {
                for (var i = 0; i < data.length; i++) {
                    if (data[i].Id === $scope.portlet.Id) {
                        $scope.maximized = true;
                        $scope.resizeCard();
                        break;
                    }
                }
            }
        );

        $scope.resizeCard = function () {

           $scope.timer = $timeout(function () {
                var id = $scope.portlet.Id;
                if (id == undefined) return;

               $("#dvc" + id).closest(".portletContainerContent").css("overflow-y", "hidden");

               if ($scope.portletContainer.Layout == 'Grid') {
                   size = 110;
               }
               else if (!$scope.maximized) {
                   var container = $("#dvc" + id).closest(".kpi-data-card-speedometer");
                   var width = container.width() || 117;
                   size = width - (width * 0.1) || 117
               }
               else if ($scope.maximized){
                   var container = $("#dvc" + id).closest(".portletContainerContent");
                   var height = container.height() || 117;
                   size = height || 117
               }

               $scope.DisplayedData.Size = Math.max(size, 70);  
            }, 250)
        }

        $scope.$on(
            "$destroy",
            function () {
                $timeout.cancel($scope.timer);
            }
        );

        $scope.refresh = function() {
            $scope.getData();
            $rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });
        };


        $scope.colorStyles = {};

        $scope.ruleIsMatching = function (dataItem, rule) {
            return dataEvalService.evaluateExpression(
                dataEvalService.createNewDataSet(dataItem),
                rule.FirstValue,
                rule.Func,
                rule.SecondValue);
        };

        $scope.calculateColor = function (dataItem, ruleDefinition) {
            var orderedColorRules = _.sortBy(ruleDefinition.colours, [function (rule) { return rule.Position; }]);

            var result = undefined; 

            if (orderedColorRules && orderedColorRules.length > 0) {
                orderedColorRules.forEach(function (currentRule) {
                    if ($scope.ruleIsMatching(dataItem, currentRule)) {
                        result = currentRule.Colour;
                    }
                });
            }
            
            return result || ruleDefinition.defaultColor;
        };

        $scope.CalculateLineBackgroundColors = function(dataItem, configuration) {       
            configuration.forEach(function (currentRule) {

                var isParameter = function (value) {
                    if (typeof value === 'number') return false; 

                    var pattern = /{\w*}/g;
                    var matches = value.match(pattern);
                    return matches !== null && matches !== undefined && matches.length > 0 ? true : false; 
                } 

                var prepareValue = function (value) {
                    return isParameter(value) ? dataItem[value.replace(/{|}/g, '')] : value;
                }

                currentRule.FirstValue = prepareValue(currentRule.FirstValue);
                currentRule.SecondValue = prepareValue(currentRule.SecondValue);            
            });

            return configuration;
        }

        $scope.getData = function() {

            $scope.controllingModel.DisplayLoadingBar = true;

            dataVisualizerCardService.getData( $scope.PaginationModel,$scope.portlet.Id)
                .then(function(result) {                    
                    if (result.data.Rows.length > 0) {
                        $scope.completeDataRow = result.data.Rows[0];

                        //This is for the benefit of the navigator
                        $scope.portlet.DCV_DataRow = $scope.completeDataRow;

                        $scope.DisplayedData.Description = $scope.getDataOrUndefined($scope.configurationModel.CardDataMapping.Description, $scope.completeDataRow);
                        $scope.DisplayedData.MainData = $scope.getDataOrUndefined($scope.configurationModel.CardDataMapping.MainData, $scope.completeDataRow);
                        $scope.DisplayedData.Unit = $scope.getDataOrUndefined($scope.configurationModel.CardDataMapping.Unit, $scope.completeDataRow);
                        $scope.DisplayedData.Priority = $scope.getDataOrUndefined($scope.configurationModel.CardDataMapping.Priority, $scope.completeDataRow);
                        $scope.DisplayedData.Trend = $scope.getDataOrUndefined($scope.configurationModel.CardDataMapping.Trend, $scope.completeDataRow);
                        $scope.DisplayedData.SpeedometerThickness = $scope.configurationModel.CardData.SpeedometerThickness;
                        $scope.DisplayedData.SpeedometerBackgroundThickness = $scope.configurationModel.CardData.SpeedometerBackgroundThickness;
                        $scope.DisplayedData.SpeedometerMin = $scope.getDataOrUndefined($scope.configurationModel.CardDataMapping.SpeedometerMin, $scope.completeDataRow);
                        $scope.DisplayedData.SpeedometerMax = $scope.getDataOrUndefined($scope.configurationModel.CardDataMapping.SpeedometerMax, $scope.completeDataRow);
                        $scope.DisplayedData.SpeedometerLineBackgroundColor = $scope.configurationModel.CardData.SpeedometerLineBackgroundColor;
                        $scope.DisplayedData.SpeedometerLineForegroundColor = $scope.configurationModel.CardData.SpeedometerLineForegroundColor;
                        $scope.DisplayedData.SpeedometerBackgroundColor = $scope.configurationModel.CardData.SpeedometerBackgroundColor;
                        $scope.DisplayedData.SpeedometerTextColor = $scope.configurationModel.CardData.SpeedometerTextColor;
                        $scope.DisplayedData.SpeedometerLineBackgroundScale = $scope.configurationModel.SpeedometerLineBackgroundScale.colours;
                        $scope.DisplayedData.SpeedometerShowScale = $scope.configurationModel.CardData.SpeedometerShowScale;


                    } else {
                        $scope.completeDataRow = {};
                        $scope.DisplayedData.Description = $scope.configurationModel.CardData.DescriptionDefault;
                        $scope.DisplayedData.MainData = undefined;
                        $scope.DisplayedData.Unit = undefined;
                        $scope.DisplayedData.Priority = undefined;
                        $scope.DisplayedData.Trend = undefined;
                        $scope.DisplayedData.SpeedometerThickness = undefined;
                        $scope.DisplayedData.SpeedometerBackgroundThickness = undefined;
                        $scope.DisplayedData.SpeedometerMin = undefined;
                        $scope.DisplayedData.SpeedometerMax = undefined;
                        $scope.DisplayedData.SpeedometerShowScale = undefined;
                    }

                    $scope.colorStyles.SpeedometerBackground = {
                        "background-color": $scope.calculateColor(
                            $scope.completeDataRow,
                            $scope.configurationModel.SpeedometerBackground)
                    };

                    $scope.colorStyles.SpeedometerLineBackground =
                        $scope.calculateColor(
                            $scope.completeDataRow,
                            $scope.configurationModel.SpeedometerLineBackground);

                    $scope.colorStyles.SpeedometerLineForeground =
                        $scope.calculateColor(
                            $scope.completeDataRow,
                            $scope.configurationModel.SpeedometerLineForeground);
                    
                    $scope.colorStyles.Background = {
                        "background-color": $scope.calculateColor(
                            $scope.completeDataRow,
                            $scope.configurationModel.ColorBackground)
                    };
                    $scope.colorStyles.ProcessArrow = {
                        "border-left-color": $scope.calculateColor(
                            $scope.completeDataRow,
                            $scope.configurationModel.ColorBackground)
                    };
                    $scope.colorStyles.Text = {
                        color: $scope.calculateColor(
                            $scope.completeDataRow,
                            $scope.configurationModel.ColorText)
                    };   

                    $scope.colorStyles.SpeedometerText = {
                        color: $scope.calculateColor(
                            $scope.completeDataRow,
                            $scope.configurationModel.SpeedometerTextColor)
                    };

                    $scope.colorStyles.SpeedometerLineBackgroundScale =
                        $scope.CalculateLineBackgroundColors($scope.completeDataRow,
                            $scope.DisplayedData.SpeedometerLineBackgroundScale);
                    
                }).catch(angular.noop).finally(
                        function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                            $scope.show();
                            $scope.resizeCard();
                        }
                    );
        };

        $scope.getPriority = function () {
            return $scope.DisplayedData.Priority ? 'Priority: ' + $scope.DisplayedData.Priority : '';
        }

        $scope.getDataOrUndefined = function(columnName, dataRow) {
            if (columnName && dataRow) {
               return dataRow[columnName];
            }
            return undefined;
        }

        $scope.initParameters = function ()
        {
            $scope.initParameters = JSON.parse(parametersJson);
        }

        $scope.initialize = function() {

            $scope.configurationModel = $scope.configurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = $scope.configurationService.getControllingModel();                       

            $scope.initPaginationModel();
            $scope.getData();
            
            
        };

        $scope.show = function(){
            var outputMode = $scope.configurationModel.CardData.OutputMode;

            if (outputMode === 'default') {
                $scope.templateUrl = $scope.pluginsDir + '/DataVisualizerCard/Templates/default.html';
            }
            else if (outputMode === 'process') {
                $scope.templateUrl = $scope.pluginsDir + '/DataVisualizerCard/Templates/processCard.html';
            }
            else if (outputMode === 'speedometer') {
                $scope.templateUrl = $scope.pluginsDir + '/DataVisualizerCard/Templates/speedometer.html';
            }
        }

        $scope.initialize();
    }
]);
