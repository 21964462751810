app.controller('GenericDocumentViewer_CreateOrEditPortletController', [
    '$scope', '$rootScope', '$uibModalInstance', 'createOrEditModalService', 'genericDocumentViewerConfigurationService', 'data', 'propertyService', 'connectorService', 'editorService', function ($scope, $rootScope, $uibModalInstance, createOrEditModalService, genericDocumentViewerConfigurationService, data, propertyService, connectorService, editorService) {

        $scope.connectorService = connectorService;
        $scope.editorService = editorService;

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.model = createOrEditModalService.getModel("GenericDocumentViewer", data);
        $scope.configurationModel = genericDocumentViewerConfigurationService.getConfigurationModel($scope.model.Properties);

        $scope.$watch('configurationModel.FileNameColumn', function () {
            if ($scope.configurationModel.FileNameColumn) {
                $scope.configurationModel.MimeTypeColumnName = null;
                $scope.configurationModel.DefaultMimeType = null;

            }
        }, true);

        $scope.$watch('configurationModel.DefaultMimeType', function () {
            if ($scope.configurationModel.DefaultMimeType) {
                $scope.configurationModel.MimeTypeColumnName = null;
                $scope.configurationModel.FileNameColumn = null;

            }
        }, true);

        $scope.$watch('configurationModel.MimeTypeColumnName', function () {
            if ($scope.configurationModel.MimeTypeColumnName) {
                $scope.configurationModel.FileNameColumn = null;
                $scope.configurationModel.DefaultMimeType = null;

            }
        }, true);

        $scope.validateDataSource = function () {

            if (!$scope.model.DataSourceType) {
                return false;
            }
            if ($scope.model.DataSourceType === 'Workflow') {
                if (!$scope.portletConfigurationForm.outputVariable) {
                    return false;
                }
                return $scope.model.Workflows.length > 0;
            }
            if ($scope.model.DataSourceType === 'Database') {
                if (!$scope.model.Queries[0].ConnectorName) {
                    return false;
                }
            }
            return true;
        };

        $scope.ok = function () {
            genericDocumentViewerConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
            $uibModalInstance.close($scope.model);
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        //Generic Document Viewer
        $scope.initialize = function () {
            $scope.createWorkflowTypes = [{
                    "type": "dsWithPagingAndFiltering",
                    "displayName": "Data Source with paging and filtering",
                }
            ];
        }();
    }
])