app.factory('iframeConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);
        
        var height = propertyService.getPropertyValue(properties, "height", "");
        if (height) {
            configurationModel.Height = parseInt(height);
        }
        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    }


    service.saveProperties = function (properties, configurationModel) {
        var height = "";
        if (configurationModel.Height) {
            height = configurationModel.Height.toString();
        }
        propertyService.setProperty(properties, "height", height);
        configurationService.saveProperties(properties, configurationModel);
    }

    return service;
}]);