app.controller('FlowMenuViewController',
[
    '$scope', '$rootScope', '$uibModal', 'flowMenuConfigurationService', 'flowService', 'translationsService',
    'flowMenuService', '$controller', 'stateService', 'workflowService', 'workflowThemeService', 'executionService', 'homeStateService', 'imageService',
    function ($scope,
        $rootScope,
        $uibModal,
        flowMenuConfigurationService,
        flowService,
        translationsService,
        flowMenuService,
        $controller, stateService, workflowService, workflowThemeService, executionService, homeStateService, imageService) {

        $scope.routing.home = $.pluginsDir + "/FlowMenu/FlowMenuView.html";

        $scope.redirectToExecutionView = function (identifier) {

            if (identifier) {
                executionService.start(identifier).then(function (result) {

                    var workflowHeader = result.data.WorkflowHeader;
                    executionService.setCurrentWorkflowState(identifier, result.data.WorkflowHeader, undefined, $scope.portlet.Id);
                    workflowThemeService.setColor(workflowHeader.ThemeColor);

                    stateService.go($scope, 'execution', { executionIdentity: result.data.JobIdentity, isHandover: false, name: workflowHeader.Title, workflowTitle: workflowHeader.Title, workflowIcon: workflowHeader.IconIdentifier, portletId: $scope.portlet.Id });
                }).catch(angular.noop);
            }
        };

        $scope.getMenuConfiguration = function () {

            $scope.activeLoader = true;
            $scope.workflowService.getMenuConfiguration().then(function (result) {

                $scope.model = angular.fromJson(result.data);
                $scope.buildTabs();
                $scope.restoreLastInbox();
                $scope.activeLoader = false;

                $scope.setActiveTab(homeStateService.getActiveTab());
            }).catch(angular.noop);
        };
        //angular and uib-tabset requeires 'active' as value, not a function
        $scope.buildTabs = function () {
            angular.forEach($scope.model, function (menu) {

                var newTab = {
                    Title: menu.Title,
                    Categories: menu.Categories,
                    RootItems: menu.RootItems,
                    Size: menu.Size,
                    Id: menu.Id,
                    IsActive: $scope.isTabActive(menu.Id)
                };
                $scope.tabs.push(newTab);
            });
        };

        $scope.restoreLastInbox = function () {
            var lastInboxId = homeStateService.getLastInboxId();
            if (lastInboxId) {
                angular.forEach($scope.model, function (menu) {
                    angular.forEach(menu.RootItems, function (item) {
                        if (item.Type === 'inbox' && item.Inbox.Identifier == lastInboxId)
                            $scope.showInbox(item.Inbox.WebExecutionInfos, item.Inbox.IconString, item.Inbox.Title, lastInboxId);
                    });
                });
            }
        };

        $scope.showInbox = function (myData, iconIdentifier, inboxTitle, inboxIdentifier) {

            homeStateService.saveLastInboxId(inboxIdentifier);

            var modalInstance = $uibModal.open({
                templateUrl: $.sharedAppDir + "/views/inbox.html",
                controller: 'inboxController',
                backdrop: 'static',
                keyboard: true,
                resolve: {
                    data: function () {
                        return myData;
                    },
                    iconIdentifier: function () {
                        return iconIdentifier;
                    },
                    inboxTitle: function () {
                        return inboxTitle;
                    }
                }
            });
            modalInstance.result.then(function (inboxData) {

                if (inboxData) {
                    if (inboxData.handoverIdentity) {
                        executionService.takeJob(inboxData.handoverIdentity).then(function (result) {
                            var webStart = angular.fromJson(result.data);
                            executionService.setCurrentWorkflowState(inboxData.handoverIdentity, {
                                executionIdentity: result.data.JobIdentity,
                                isHandover: true,
                                name: inboxData.name,
                                workflowTitle: inboxData.name,
                                workflowIcon: inboxData.icon
                            }, true, $scope.portlet.Id);
                            stateService.go($scope, 'execution', { executionIdentity: webStart.JobIdentity, isHandover: true, name: inboxData.name, workflowTitle: inboxData.name, workflowIcon: inboxData.icon, portletId: $scope.portlet.Id });
                        }).catch(angular.noop);

                    }
                }
            }, function () {
                //handle popup dismiss on cancel
            }).catch(angular.noop);
        };

        $scope.initialize = function () {


            $scope.model = [];
            $scope.workflowService = workflowService;
            $scope.executionService = executionService;
            $scope.workflowThemeService = workflowThemeService;
            $scope.homeStateService = homeStateService;
            $scope.imageService = imageService;

            $scope.DisplayLoadingBar = true;

            flowMenuService.getMenus($scope.configurationModel.flowMenuId)
               .then(
                   function (result) {

                       $scope.model = angular.fromJson(result.data);
                       $scope.tab = result.data[0];

                       var currentWorkflow = executionService.getCurrentWorkflowState($scope.portlet.Id);
                       var currentWorkflowHeader = currentWorkflow ? currentWorkflow.workflowHeader : null;
                       if (currentWorkflowHeader) {
                           var executionId = currentWorkflow.inbox
                               ? currentWorkflowHeader.executionIdentity
                               : currentWorkflow.jobIdentity;

                           var executionPromise = null;
                           if (currentWorkflow.inbox) {
                               executionPromise = executionService.handover(executionId);
                           } else {
                               executionPromise = executionService.start(executionId);
                           }

                           executionPromise.then(function(result) {
                               stateService.go($scope,
                                   'execution',
                                   {
                                       executionIdentity: result.data.JobIdentity,
                                       isHandover: currentWorkflow.inbox ? true : false,
                                       name: currentWorkflowHeader.Title,
                                       workflowTitle: currentWorkflowHeader.Title,
                                       workflowIcon: currentWorkflowHeader.IconIdentifier,
                                       portletId: $scope.portlet.Id
                                   });
                           }).catch(angular.noop);
                       } else
                           $scope.restoreLastInbox();
                   }
               ).finally(function () {
                   $scope.DisplayLoadingBar = false;
                });

      

         
        };

        $scope.initialize();

    }
]);