app.service('inboxService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getInbox = function (inboxId) {
            return basePluginService.backend.get("inboxes/" + inboxId);
        };
        service.getInboxes = function () {
            return basePluginService.backend.get("inboxes/");
        };       

        return service;

    }]);