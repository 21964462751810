app.service('FlowClientService', ['basePluginService','$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getMenu = function (flowMenuId) {
            return basePluginService.backend.get("menus/" + flowMenuId);
        };
        
        return service;

    }]);