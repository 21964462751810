app.controller('DocManList_CreateOrEditPortletController', ['$scope', '$uibModalInstance', 'propertyService', 'createOrEditModalService', 'docManListConfigurationService', 'data', function ($scope, $uibModalInstance, propertyService, createOrEditModalService, docManListConfigurationService, data) {

    $scope.model = createOrEditModalService.getModel("DocManList", data);
    $scope.configurationModel = docManListConfigurationService.getConfigurationModel($scope.model.Properties);
    
    $scope.panelBarOptions = {
        expandMode: "single",
        select: $scope.expandCollapse
    };

    $scope.ok = function () {
        docManListConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel);
        $uibModalInstance.close($scope.model);
    };

   $scope.cancel = function () {
       $uibModalInstance.dismiss('cancel');
    };
}])