app.controller("Filter_CreateOrEditPortletController",
    [
        "$scope", "$uibModalInstance", "$rootScope", "createOrEditModalService", "filterConfigurationService", "data",
        "suggestionService", "editorService", "Guid", "kendoGridUtilService", function ($scope,
            $uibModalInstance,
            $rootScope,
            createOrEditModalService,
            filterConfigurationService,
            data,
            suggestionService,
            editorService,
            Guid,
            kendoGridUtilService) {

            $scope.nextEditIndex = {};

            $scope.panelBarOptions = {
                expandMode: "single",
                select: $scope.expandCollapse
            };

            $scope.model = createOrEditModalService.getModel("Filter", data);

            $scope.configurationModel = filterConfigurationService.getConfigurationModel($scope.model.Properties);

            $scope.numberOfColumnsDataSource = new kendo.data.DataSource({
                data: [1, 2, 3, 4]
            });

            $scope.onclickMultline = function(dataItem) {
                if (dataItem.IsMultiline) {
                    dataItem.IsMultiline = false;
                } else {
                    dataItem.IsMultiline = true;
                }
            };

            $scope.onclickVisible = function(dataItem) {
                if (dataItem.IsVisible) {
                    dataItem.IsVisible = false;
                } else {
                    dataItem.IsVisible = true;
                }
            };

            $scope.groupHeaderOptions = {
                editable: {
                    createAt: "bottom"
                },
                scrollable: false,
                navigatable: true,
                save: function(e) {
                    for (var p = 0; p < $scope.mapperGrid.dataSource.data().length; p++) {
                        if ($scope.mapperGrid.dataSource.data()[p].Group == e.model.GroupName) {
                            $scope.mapperGrid.dataSource.data()[p].Group = e.values.GroupName;
                        }
                    };
                },
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    { field: "GroupName", title: "Group name" },
                    {
                        field: "IsVisible",
                        title: "Header visible",
                        width: "70px",
                        template:
                            "<input ng-click=onclickVisible(dataItem) type='checkbox' #= IsVisible ? checked='checked' : '' # ></input>"
                    },
                    {
                        command: [
                            {
                                name: "up",
                                template: "<a class='k-button k-button-icontext gridButton' ng-click='groupUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
                            },
                        {
                            name: "down",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='groupDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
                        },
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 100
                    }
                ],
                dataSource: new kendo.data.DataSource({
                    batch: true,
                    data: $scope.configurationModel.GroupHeaders,
                    schema: {
                        model: {
                            fields: {
                                Code: { type: "string" },
                                IsVisible: { type: "boolean", defaultValue: true },
                                GroupName: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"}
                                    }
                                }
                            }
                        }
                    }
                })
            };

            $scope.groupUp = function(e) {
                for (var p = 0; p < $scope.groupGrid.dataSource.data().length; p++) {
                    $scope.groupGrid.dataSource.data()[p].Position = p;
                }

                var dataItem = $scope.groupGrid.dataItem($(e.currentTarget).closest("tr"));

                var index = null;
                var elements = $scope.groupGrid.dataSource.data();
                for (var i = 0; i < elements.length; i++) {
                    if (elements[i].Position == dataItem.Position) {
                        index = i;
                        break;
                    }
                }
                if (index > 0) {
                    $scope.groupGrid.dataSource.remove(dataItem);
                    $scope.groupGrid.dataSource.insert(index - 1, dataItem);
                }
            };

            $scope.groupDown = function(e) {
                for (var p = 0; p < $scope.groupGrid.dataSource.data().length; p++) {
                    $scope.groupGrid.dataSource.data()[p].Position = p;
                }

                var dataItem = $scope.groupGrid.dataItem($(e.currentTarget).closest("tr"));

                var index = null;
                var elements = $scope.groupGrid.dataSource.data();

                for (var i = 0; i < elements.length; i++) {
                    if (elements[i].Position == dataItem.Position) {
                        index = i;
                        break;
                    }
                }
                $scope.groupGrid.dataSource.remove(dataItem);
                $scope.groupGrid.dataSource.insert(index + 1, dataItem);

            };

            $scope.mapperOptions = {
                editable: {
                    createAt: "bottom"
                },
                scrollable: false,
                navigatable: true,
                autoSync: true,
                toolbar: [{ name: "create", text: "Add" }],
                columns: [
                    { field: "DisplayName", title: "Display Name", width: "170px" },
                    { field: "DatabaseColumn", title: "Filter parameter", width: "170px" },
                    { field: "ColumnSize", title: "Column size", width: "50px", editor: columnSizeDropDownEditor },
                    { field: "Group", title: "Group", width: "100px", editor: groupDropDownEditor },
                    {
                        field: "Lov",
                        title: "Lov",
                        width: "180px",
                        editor: editorService.createLovComboBox
                    },
                    {
                        field: "Editor",
                        title: "Editor",
                        width: "130px",
                        editor: editorService.createEditorDropDownListWithoutCheckbox
                    },
                    {
                        field: "Operator",
                        title: "Operator",
                        width: "220px",
                        editor: editorService.createSearchOperatorDropDownList,
                        template: "#=Operator.Text#"
                    },
                    
                    { field: "Length", title: "Length", width: "50px" },
                    {
                        field: "IsMultiline",
                        title: "Multiline",
                        width: "50px",
                        template:
                            "<input ng-click=onclickMultline(dataItem) type='checkbox' #= IsMultiline ? checked='checked' : '' # ></input>"
                    },
                    {
                        command: [
                            {
                                name: "up",
                                template: "<a class='k-button k-button-icontext gridButton' ng-click='mapperUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
                            },
                            {
                                name: "down",
                                template: "<a class='k-button k-button-icontext gridButton' ng-click='mapperDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
                            },
                            {
                                name: "destroy",
                                template:
                                    "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                            }
                        ],
                        width: 100
                    }
                ],

                dataSource: new kendo.data.DataSource({
                    data: $scope.configurationModel.Mappers,

                    schema: {
                        model: {
                            fields: {
                                DisplayName: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"} }
                                },
                                DatabaseColumn: {
                                    type: "string",
                                    validation: {
                                        required: { message: "Field is required"} }
                                },
                                Code: { type: "string" },
                                ColumnSize: { type: "string" },
                                Group: { type: "string" },
                                Editor: {
                                    defaultValue: "Text"
                                },
                                Operator: {
                                    defaultValue: { Text: "Equals", Value: "eq" }
                                },
                                Length: { type: "number" },
                                IsEditable: { type: "boolean", defaultValue: true },
                                Lov: { type: "string" },
                                IsMultiline: { type: "boolean", defaultValue: false }

                            }
                        }
                    }
                })
            };

            function groupDropDownEditor(container, options) {
                $('<input data-bind="value:' + options.field + '"/>')
                    .appendTo(container)
                    .kendoComboBox({
                        autoBind: true,
                        dataSource: new kendo.data.DataSource({
                            transport: {
                                read: function(opts) {
                                    var toShow = [];
                                    for (var p = 0; p < $scope.groupGrid.dataSource.data().length; p++) {
                                        toShow.push($scope.groupGrid.dataSource.data()[p].GroupName);
                                    }
                                    opts.success(toShow);
                                }
                            }
                        })
                    });
            }

            function columnSizeDropDownEditor(container, options) {
                $('<input data-bind="value:' + options.field + '"/>')
                    .appendTo(container)
                    .kendoComboBox({
                        autoBind: true,
                        dataSource: new kendo.data.DataSource({
                            transport: {
                                read: function(opts) {
                                    opts.success(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]);
                                }
                            }
                        })
                    });
            }

            $scope.mapperUp = function(e) {
                for (var p = 0; p < $scope.mapperGrid.dataSource.data().length; p++) {
                    $scope.mapperGrid.dataSource.data()[p].Position = p;
                }

                var dataItem = $scope.mapperGrid.dataItem($(e.currentTarget).closest("tr"));

                var index = null;
                var elements = $scope.mapperGrid.dataSource.data();
                for (var i = 0; i < elements.length; i++) {
                    if (elements[i].Position == dataItem.Position) {
                        index = i;
                        break;
                    }
                }
                if (index > 0) {
                    $scope.mapperGrid.dataSource.remove(dataItem);
                    $scope.mapperGrid.dataSource.insert(index - 1, dataItem);
                }
            };

            $scope.mapperDown = function(e) {
                for (var p = 0; p < $scope.mapperGrid.dataSource.data().length; p++) {
                    $scope.mapperGrid.dataSource.data()[p].Position = p;
                }

                var dataItem = $scope.mapperGrid.dataItem($(e.currentTarget).closest("tr"));

                var index = null;
                var elements = $scope.mapperGrid.dataSource.data();

                for (var i = 0; i < elements.length; i++) {
                    if (elements[i].Position == dataItem.Position) {
                        index = i;
                        break;
                    }
                }
                $scope.mapperGrid.dataSource.remove(dataItem);
                $scope.mapperGrid.dataSource.insert(index + 1, dataItem);

            };

            $scope.ok = function() {

                $scope.configurationModel.GroupHeaders = [];
                var headers = $scope.groupGrid.dataSource.data();
                for (var p = 0; p < headers.length; p++) {
                    delete headers[p].Position;
                    $scope.configurationModel.GroupHeaders.push(headers[p]);
                    if (!headers[p].Code) {
                        headers[p].Code = Guid.newGuid();
                    }
                }

                $scope.configurationModel.Mappers = [];
                var mappers = $scope.mapperGrid.dataSource.data();
                for (var p = 0; p < mappers.length; p++) {
                    delete mappers[p].Position;
                    $scope.configurationModel.Mappers.push(mappers[p]);
                    if (!mappers[p].Code) {
                        mappers[p].Code = Guid.newGuid();
                    }
                }

                filterConfigurationService.saveProperties($scope.model.Properties,
                    $scope.configurationModel,
                    data.operation);
                $uibModalInstance.close($scope.model);
            };

            $scope.cancel = function() {
                $uibModalInstance.dismiss("cancel");
            };

        }
    ])
