app.service('DocumentViewerService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getDocument = function (DocumentId, headers, portletId) {
            return basePluginService.backend.postWithHeaders("documentManagement", DocumentId, headers, portletId, true);
        };

        return service;

    }]);