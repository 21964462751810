app.service('FlowApplicationTasksService', ['basePluginService','basePortalService', '$q',
    function (basePluginService,basePortalService, $q) {
        angular.extend(this, basePluginService);
        angular.extend(this, basePortalService);

        var service = {};
        service.getApplicationTasksHeader = function (paginationModel,portletId) {
            return basePluginService.backend.post("applicationTasksHeader", paginationModel,portletId);
        };
        service.getApplicationTasks = function (paginationModel,portletId) {
            return basePluginService.backend.post("applicationTasks", paginationModel,portletId);
       };

        service.removeTask = function (model,portletId) {
            return basePluginService.backend.post("removeTask", model,portletId);
        };

        service.reassignTask = function (model,portletId) {
            return basePluginService.backend.post("reassignTask", model,portletId);
        };


        service.getSenderType = function (data) {
            return basePortalService.backend.post("senderType", data);
        };

        service.getLogins=function(portletId){
            return basePluginService.backend.get("reassignTaskLogins",portletId);
        };


        service.getApplicationColumns= function(config) {
            var paginationModel = {
                PageNumber:0,     
                PageSize:100
            };

            var columnsDataSource = {
                transport: {
                    read: function (opts) {
                            service.getApplicationTasksHeader(paginationModel)
                                .then(function (result) {
                                    var toSet = [];
                                    angular.forEach(result.data.Headers,
                                        function(item) {
                                            if(!item.Hidden)    
                                               toSet.push(item);
                                        });

                                    opts.success(toSet);
                                }).catch(angular.noop);
                    }
                }
            };

            return {
                filter: "contains",
                dataSource: columnsDataSource,
                dataTextField: "ColumnDescription",
                dataValueField: "ColumnCode"
            };      


        }
        service.senderTypeOptions = function(portletType) {

            var sendersDataSource = {
                transport: {
                    read: function(options) {
                        service.getSenderType({ PortletType: portletType })
                            .then(function(result) {
                                var toSet = [];
                                angular.forEach(result.data,
                                    function(item) {
                                        toSet.push({
                                            SenderId: item.Id,
                                            PortletId: 0,
                                            InternalName: item.InternalName
                                        });
                                    });

                                options.success(toSet);
                            }).catch(angular.noop);
                    }
                }
            };

            return {
                dataSource: sendersDataSource,
                dataTextField: "InternalName",
                dataValueField: "SenderId"
            };
        };

      
   

        return service;

    }]);